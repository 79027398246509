import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import authGuard from '@/core/guards/auth';
import guestGuard from '@/core/guards/guest';
import AppLostPwd from '../modules/auth/views/AppLostPwd.vue';
import AppLogin from '../modules/auth/views/AppLogin.vue';
import AppCheckEmail from '../modules/auth/views/AppCheckEmail.vue';
import AppPasswordResetted from '../modules/auth/views/AppPasswordResetted.vue';
import AppResetPwd from '../modules/auth/views/AppResetPwd.vue';
import DashBoardIndex from '../modules/dashboard/views/DashboardIndex.vue';
import PrivacyIndex from '../modules/privacy/views/PrivacyIndex.vue';
import DriversIndex from '../modules/drivers/views/DriversIndex.vue';
import CompaniesIndex from '../modules/companies/views/CompaniesIndex.vue';
import CompaniesNccIndex from '../modules/companies-ncc/views/CompaniesIndex.vue';
import CustomersIndex from '../modules/customers/views/CustomersIndex.vue';
import SingleCompanies from '../modules/companies/views/SingleCompanies.vue';
import SingleCompaniesNcc from '../modules/companies-ncc/views/SingleCompanies.vue';
import SingleCustomers from '../modules/customers/views/SingleCustomers.vue';
import VehiclesIndex from '../modules/vehicles/views/VehiclesIndex.vue';
import SingleDrivers from '../modules/drivers/views/SingleDrivers.vue';
import ReferentsIndex from '../modules/referents/ReferentsIndex.vue';
import RidesIndex from '../modules/rides/RidesIndex.vue';
import MembersIndex from '../modules/members/MembersIndex.vue';
import BillingsIndex from '../modules/billings/views/BillingsIndex.vue';
import SettingsIndex from '../modules/settings/views/SettingsIndex.vue';
import SingleRides from '@/modules/rides/SingleRides.vue';
import ToursIndex from '@/modules/tours/ToursIndex.vue';
import PayoutsIndex from '@/modules/payout/PayoutsIndex.vue';
import SinglePayout from '@/modules/payout/SinglePayout.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'DashBoard',
    component: DashBoardIndex,
    beforeEnter: authGuard,
  },
  {
    path: '/login',
    name: 'AppLogin',
    component: AppLogin,
  },
  {
    path: '/lostpassword',
    name: 'AppLostPwd',
    component: AppLostPwd,
  },
  {
    path: '/resetpassword',
    name: 'AppResetPwd',
    component: AppResetPwd,
  },
  {
    path: '/email-send',
    name: 'AppCheckEmail',
    component: AppCheckEmail,
  },
  {
    path: '/password-resetted',
    name: 'AppPasswordResetted',
    component: AppPasswordResetted,
    beforeEnter: guestGuard,
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/customers',
    name: 'Customers',
    component: CustomersIndex,
    beforeEnter: authGuard,
  },
  {
    path: '/customers/:id',
    name: 'SingleCustomers',
    component: SingleCustomers,
    beforeEnter: authGuard,
  },
  {
    path: '/drivers',
    name: 'Drivers',
    component: DriversIndex,
    beforeEnter: authGuard,
  },
  {
    path: '/drivers/:id',
    name: 'SingleDrivers',
    component: SingleDrivers,
    beforeEnter: authGuard,
  },
  {
    path: '/companies',
    name: 'Companies',
    component: CompaniesIndex,
    beforeEnter: authGuard,
  },
  {
    path: '/companies/:id',
    name: 'SingleCompanies',
    component: SingleCompanies,
    beforeEnter: authGuard,
  },
  {
    path: '/companies-ncc',
    name: 'CompaniesNcc',
    component: CompaniesNccIndex,
    beforeEnter: authGuard,
  },
  {
    path: '/companies-ncc/:id',
    name: 'SingleCompanies',
    component: SingleCompaniesNcc,
    beforeEnter: authGuard,
  },
  {
    path: '/vehicles',
    name: 'Vehicles',
    component: VehiclesIndex,
    beforeEnter: authGuard,
  },
  {
    path: '/referents',
    name: 'Referents',
    component: ReferentsIndex,
    beforeEnter: authGuard,
  },
  {
    path: '/rides',
    name: 'Rides',
    component: RidesIndex,
    beforeEnter: authGuard,
  },
  {
    path: '/tours',
    name: 'Tours',
    component: ToursIndex,
    beforeEnter: authGuard,
  },
  {
    path: '/payout',
    name: 'Payout',
    component: PayoutsIndex,
    beforeEnter: authGuard,
  },
  {
    path: '/payout/:id',
    name: 'SinglePayout',
    component: SinglePayout,
    beforeEnter: authGuard,
  },
  {
    path: '/rides/:id',
    name: 'SingleRide',
    component: SingleRides,
    beforeEnter: authGuard,
  },
  {
    path: '/members',
    name: 'Members',
    component: MembersIndex,
    beforeEnter: authGuard,
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: PrivacyIndex,
    beforeEnter: authGuard,
  },
  {
    path: '/billings',
    name: 'Billings',
    component: BillingsIndex,
    beforeEnter: authGuard,
  },
  {
    path: '/settings',
    name: 'Settings',
    component: SettingsIndex,
    beforeEnter: authGuard,
  },
  {
    path: '/referents',
    name: 'Referents',
    component: ReferentsIndex,
    beforeEnter: authGuard,
  },


];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
