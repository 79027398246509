import axios from 'axios';

const createCompany = async (data: any) => {
  return axios({
    url: `${process.env.VUE_APP_API}/users/companies`,
    method: 'post',
    headers:  {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
    data
  });
};

const CompanyClient = {
  createCompany,
};
  // Disallow new properties on our object
Object.freeze(CompanyClient);
export default CompanyClient;
