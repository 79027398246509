import vuexDefinitions, { CustomerState, Filters } from '@/modules/customers/store/enums';
import { ActionContext } from 'vuex';
import store, { RootState } from '@/store';
import UserClient from '@/core/services/user-client';
import RidesClient from '../../rides/rides-services/ride-client';
import { AuthRole } from '@/modules/auth/store/enums';

type VehicleActionContext = ActionContext<CustomerState, RootState>

export default {
    [vuexDefinitions.enums.ACTIONS.FetchCustomerList]: 
        async (context: VehicleActionContext,  payload: Filters, page: number): Promise<void> => {
        context.commit(vuexDefinitions.enums.MUTATIONS.UpdateFilters, payload);
        const customerList = await UserClient.getAllUsers(
          AuthRole.CUSTOMER,
          context.state.filters?.search,
          context.state.filters?.orderBy,
          page
        );

        context.commit(vuexDefinitions.enums.MUTATIONS.UpdateCustomerList, customerList.data.data);
        context.commit(vuexDefinitions.enums.MUTATIONS.UpdatePage, customerList.data.paginate);
        context.commit(vuexDefinitions.enums.MUTATIONS.UpdateSelectedCustomer, customerList.data.data);
    },  

    [vuexDefinitions.enums.ACTIONS.SetSelectedCustomer]: 
    async (context: VehicleActionContext, id: string): Promise<void> => {
        const singleCustomer = await UserClient.getSingleUser(id);
        context.commit(vuexDefinitions.enums.MUTATIONS.UpdateSelectedCustomer, singleCustomer.data.data);
    },  
};
