<script lang="ts" setup>
import { reactive, ref, defineEmits, watch, computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, requiredIf, maxValue } from '@vuelidate/validators';
import AppAutoComplete from '@/components/AppAutoComplete.vue';
import authEnums, { AuthUser } from '@/modules/auth/store/enums';
import MemberClient from '@/modules/members/services/member-client';
import { OrderBy } from '@/core/interfaces';
import alertVuexDefinitions, { Alert, AlertType } from "@/core/store/alert/enums";
import { Address, PayloadCreateTour } from '../store/enums';
import configEnums from "@/core/store/config/enums";
import { useStore } from "vuex";
import moment from 'moment';
import RidesClient from '@/modules/rides/rides-services/ride-client';
const emits = defineEmits(["update-form-value", "update-form-valid"]);

const store = useStore();
const formData = reactive({
  intermediate_stops: [],
  start_date: '',
  end_date: '',
  start_point: '',
  end_point: '',
  description: '',
  guide: '',
  vehicle: '',
  duration: '',
  region: '',
  image: '',
  video: '',
  additional_info: '',
  total_price: '',
});

const end_point = ref<Address[]>();
const start_point = ref<Address[]>();
const dataPostTour = ref<PayloadCreateTour>({
  intermediate_stops: [],
  start_date: '',
  end_date: '',
  start_point: '',
  end_point: '',
  description: '',
  guide: '',
  vehicle: '',
  duration: '',
  region: '',
  image: '',
  video: '',
  additional_info: '',
  total_price: ''
});
const authUser = computed(() => store.getters[authEnums.enums.GETTERS.GetAuthUser]);
const config = computed(() => store.getters[configEnums.enums.GETTERS.GetConfig]);

const rules = {
  intermediate_stops: { required },
  start_date: { required },
  end_date: { required },
  start_point: { required },
  end_point: { required },
  description: { required },
  guide: { required },
  vehicle: { required },
  duration: { required },
  region: { required },
  image: { required },
  video: { required },
  additional_info: { required },
  total_price: { required },
};

const v$ = useVuelidate(rules, formData);

const updateFormValue = () => {
  console.log('formData', formData);
  console.log('v$', v$.value.$invalid);

  emits("update-form-value", {
    ...formData,
  });
  emits("update-form-valid", v$.value.$invalid);
};


const updatePickUpAddress = async (search: string) => {
  try {
    console.log(search)
    formData.start_point = search;
    if (search.length < 3) return;
    const response = await RidesClient.findAddress(search);
    start_point.value = response.data.data;
    v$.value.start_point.$touch();
  } catch (error: any) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: "Errore",
      description: error?.data?.message,
      type: AlertType.Error,
    } as Alert);
  }
};

const updateDropOffAddress = async (search: string) => {
  try {
    console.log(search)
    formData.end_point = search;
    if (search.length < 3) return;
    const response = await RidesClient.findAddress(search);
    end_point.value = response.data.data;
    v$.value.end_point.$touch();
  } catch (error: any) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: "Errore",
      description: error.data.message,
      type: AlertType.Error,
    } as Alert);
  }
};

const selectPickUpAddress = (newValue: Address) => {
  formData.start_point = newValue.formatted_address;
  dataPostTour.value.start_point = {
    address: newValue.formatted_address,
    position: {
      lat: newValue.geometry.location?.lat,
      lng: newValue.geometry.location?.lng
    }
  };
  start_point.value = [];
  v$.value.start_point.$touch();
  updateFormValue();
};

const selectDropOffAddress = (newValue: Address) => {
  formData.end_point = newValue.formatted_address;
  dataPostTour.value.end_point = {
    address: newValue.formatted_address,
    position: {
      lat: newValue.geometry.location?.lat,
      lng: newValue.geometry.location?.lng
    }
  };
  end_point.value = [];
  console.log(dataPostTour.value.end_point);
  v$.value.end_point.$touch();
  updateFormValue();
};

watch(formData, () => {
  updateFormValue();
});

</script>
<template>
  <div class="wi-ride-form">
    <div class="wi-flex wi-flex-row wi-justify-between wi-m-t-2">
      <label for="start_point" class="wi-text-left wi-text-xs wi-text-bold wi-w-44 mc-w-l-100">
        <div class="wi-flex wi-m-b-1">
          <p>Data di ritiro:</p>
        </div>
        <input class="wi-input wi-w-100" :min="moment().format('')" type="date"
          v-model="v$.start_date.$model" name="" id="">
        <div class="input-errors wi-m-y-1" v-for="error of v$.start_date.$errors" :key="error.$uid">
          <div class="wi-text-xxs">{{ error.$message }}</div>
        </div>
      </label>
      <label for="end_point" class="wi-text-left wi-text-xs wi-text-bold wi-w-44 mc-w-l-100">
        <div class="wi-flex wi-m-b-1">
          <p>Data fine tour:</p>
        </div>
        <input class="wi-input wi-w-100" :min="moment().format('')" type="date"
          v-model="v$.end_date.$model" name="" id="">
        <div class="input-errors wi-m-y-1" v-for="error of v$.end_date.$errors" :key="error.$uid">
          <div class="wi-text-xxs">{{ error.$message }}</div>
        </div>
      </label>
    </div>
    <div class="wi-flex wi-flex-row wi-justify-between wi-m-t-2">
      <label for="start_point" class="wi-text-left wi-text-xs wi-text-bold wi-w-44 mc-w-l-100">
        <div class="wi-flex">
          <wi-icon-svg icon="pickup" class="wi-icon wi-m-e-1" width="16" height="16"></wi-icon-svg>
          <p>Da:</p>
        </div>
        <app-auto-complete :search="v$.start_point.$model" :has-open="!!start_point"
          @update:search="updatePickUpAddress($event)" @reset:items="start_point = undefined"
          placeholder="Luogo di partenza" class="wi-m-t-1">
          <div class="wi-flex wi-flex-column" v-if="start_point && start_point.length > 0">
            <div class="items" v-for="(item, index) in start_point" :key="index">
              <div class="wi-ride-form-guest" @click="selectPickUpAddress(item as Address)">
                <wi-icon-svg icon="pickup" class="wi-icon wi-m-e-1" width="16" height="16"></wi-icon-svg>
                <div class="wi-flex wi-flex-column">
                  <a class="wi-text-left wi-color-lc">{{ item.formatted_address }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="items" v-else>
            <div class="wi-ride-form-guest">
              <div class="wi-flex wi-flex-column">
                <p>Nessun indirizzo trovato</p>
              </div>
            </div>
          </div>
        </app-auto-complete>
        <div class="input-errors wi-m-y-1" v-for="error of v$.start_point.$errors" :key="error.$uid">
          <div class="wi-text-xxs">{{ error.$message }}</div>
        </div>
      </label>
      <label for="end_point" class="wi-text-left wi-text-xs wi-text-bold wi-w-44 mc-w-l-100">
        <div class="wi-flex">
          <wi-icon-svg icon="droppoff" class="wi-icon wi-m-e-1" width="16" height="16"></wi-icon-svg>
          <p>A:</p>
        </div>
        <app-auto-complete :search="v$.end_point.$model" :has-open="!!end_point"
          @update:search="updateDropOffAddress($event)" @reset:items="end_point = undefined"
          placeholder="Luogo di arrivo" class="wi-m-t-1">
          <div class="wi-flex wi-flex-column" v-if="end_point && end_point.length > 0">
            <div class="items" v-for="(item, index) in end_point" :key="index">
              <div class="wi-ride-form-guest" @click="selectDropOffAddress(item as Address)">
                <wi-icon-svg icon="droppoff" class="wi-icon wi-m-e-1" width="16" height="16"></wi-icon-svg>
                <div class="wi-flex wi-flex-column">
                  <a class="wi-text-left wi-color-lc">{{ item.formatted_address }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="items" v-else>
            <div class="wi-ride-form-guest">
              <div class="wi-flex wi-flex-column">
                <p>Nessun indirizzo trovato</p>
              </div>
            </div>
          </div>
        </app-auto-complete>
        <div class="input-errors wi-m-y-1" v-for="error of v$.end_point.$errors" :key="error.$uid">
          <div class="wi-text-xxs">{{ error.$message }}</div>
        </div>
      </label>
    </div>
    <div class="wi-flex wi-flex-row wi-justify-between wi-m-t-2">
      <label for="start_point" class="wi-text-left wi-text-xs wi-text-bold wi-w-100 mc-w-l-100">
        <div class="wi-flex wi-m-b-1">
          <p>Descrizione Tour:</p>
        </div>
        <textarea class="wi-input wi-w-100"
          v-model="v$.description.$model" name="" id="">
        </textarea>
        <div class="input-errors wi-m-y-1" v-for="error of v$.description.$errors" :key="error.$uid">
          <div class="wi-text-xxs">{{ error.$message }}</div>
        </div>
      </label>
    </div>
    <div class="wi-flex wi-flex-row wi-justify-between wi-m-t-2">
      <label for="start_point" class="wi-text-left wi-text-xs wi-text-bold wi-w-100 mc-w-l-100">
        <div class="wi-flex wi-m-b-1">
          <p>Informazioni aggiuntive:</p>
        </div>
        <textarea class="wi-input wi-w-100"
          v-model="v$.additional_info.$model" name="" id="">
        </textarea>
        <div class="input-errors wi-m-y-1" v-for="error of v$.additional_info.$errors" :key="error.$uid">
          <div class="wi-text-xxs">{{ error.$message }}</div>
        </div>
      </label>
    </div>
    <div class="wi-flex wi-flex-row wi-justify-between wi-m-t-2">
      <label for="start_point" class="wi-text-left wi-text-xs wi-text-bold wi-w-44 mc-w-l-100">
        <div class="wi-flex wi-m-b-1">
          <p>Nome guida:</p>
        </div>
        <input class="wi-input wi-w-100"
          placeholder="Nome guida"
          v-model="v$.guide.$model" name="" id="">
        <div class="input-errors wi-m-y-1" v-for="error of v$.guide.$errors" :key="error.$uid">
          <div class="wi-text-xxs">{{ error.$message }}</div>
        </div>
      </label>
      <label for="start_point" class="wi-text-left wi-text-xs wi-text-bold wi-w-44 mc-w-l-100">
        <div class="wi-flex wi-m-b-1">
          <p>Descrizione Veicolo:</p>
        </div>
        <input class="wi-input wi-w-100"
          placeholder="descrizione veicoli"
          v-model="v$.vehicle.$model" name="" id="">
        <div class="input-errors wi-m-y-1" v-for="error of v$.vehicle.$errors" :key="error.$uid">
          <div class="wi-text-xxs">{{ error.$message }}</div>
        </div>
      </label>
    </div>
    <div class="wi-flex wi-flex-row wi-justify-between wi-m-t-2">
      <label for="start_point" class="wi-text-left wi-text-xs wi-text-bold wi-w-44 mc-w-l-100">
        <div class="wi-flex wi-m-b-1">
          <p>Regione:</p>
        </div>
        <select
          id="name"
          placeholder="Inserisci la tua regione"
          class="wi-input wi-modal-input"
          :class="{ 'error-border': v$.region.$error }" 
          v-mode="v$.region.$model"
        >
          <option value="" :selected="v$.region.$model === ''">Inserisci la regione desiderata</option>
          <option
            v-for="region in config.modules.region"
            :key="region"
            :value="region"
            :selected="v$.region.$model === region"
          >
            {{region}}
          </option>
        </select>
      <div class="input-errors wi-m-b-1" v-for="error of v$.region.$errors" :key="error.$uid">
        <div class="wi-text-xs wi-font-lato">{{ error.$message }}</div>
      </div>
        <div class="input-errors wi-m-y-1" v-for="error of v$.region.$errors" :key="error.$uid">
          <div class="wi-text-xxs">{{ error.$message }}</div>
        </div>
      </label>
      <label for="start_point" class="wi-text-left wi-text-xs wi-text-bold wi-w-30 mc-w-l-100">
        <div class="wi-flex wi-m-b-1">
          <p>Durata (min):</p>
        </div>
        <input class="wi-input wi-w-100"
          placeholder="Durata"
          v-model="v$.additional_info.$model" name="" type="number" id="">
        <div class="input-errors wi-m-y-1" v-for="error of v$.additional_info.$errors" :key="error.$uid">
          <div class="wi-text-xxs">{{ error.$message }}</div>
        </div>
      </label>
      <label for="start_point" class="wi-text-left wi-text-xs wi-text-bold wi-w-30 mc-w-l-100">
        <div class="wi-flex wi-m-b-1">
          <p>Prezzo:</p>
        </div>
        <input class="wi-input wi-w-100"
          placeholder="Prezzo"
          v-model="v$.total_price.$model" name="" type="number" id="">
        <div class="input-errors wi-m-y-1" v-for="error of v$.total_price.$errors" :key="error.$uid">
          <div class="wi-text-xxs">{{ error.$message }}</div>
        </div>
      </label>
    </div>
    <div class="wi-flex wi-flex-row wi-justify-between wi-m-t-2">

    </div>
  </div>
</template>

<style lang="scss">
.wi-ride {
  &-form {
    display: flex;
    flex-direction: column;

    fieldset {
      width: max-content;
    }

    &-guest {
      display: flex;
      flex-direction: row;
      box-shadow: inset 3px 0 0 #acafb2;
      cursor: pointer;
      padding: 1rem;
      font-size: 13px;

      &:hover {
        background-color: #efefef;
      }
    }
  }

  &-type {
    &-item {
      background-color: #ffffff;
      cursor: pointer;
      padding: 1rem;

      &-price {
        font-weight: bold;
        font-size: 20px;
      }

      &:hover {
        background-color: #f7f7f7;
        box-shadow: 0 0 5px #00000029;
        transition: all 0.3s ease-in-out;
      }

      &.selected {
        background-color: #f7f7f7;
        box-shadow: 0 0 5px #00000029;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

button:active {
  background-color: F7E462;
  color: black;
  outline: 1px solid F7E462;
}

.option-radio {
  height: 15px;
  width: 15px;
  border-radius: 100%;
  margin-right: 10px;
  border: 1px solid #E9E9E9;
  background-color: #f2f2f2;
  box-shadow: inset 0 0 0 2px white;
  transition: background-color 0.3s;

  &.selected {
    background-color: yellow;
  }
}</style>