<script lang="ts">
import { reactive } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { mapActions } from 'vuex';
import AppAuthLayout from '../components/AppAuthLayout.vue';
import enums from '../store/enums';

export default {
  components: {
    AppAuthLayout,
  },
  setup() {
    const state = reactive({
      email: '',
    });

    const rules = {
      email: { required, email },
    };

    const v$ = useVuelidate(rules, state);
    return { state, v$ };
  },
  methods: {
    ...mapActions({
      resetPassword: enums.enums.ACTIONS.ResetPassword,
    }),
  },
};
</script>

<template>
  <AppAuthLayout>
    <h1 class="wi-text-l wi-text-bold wi-text-left wi-p-b-3">Password dimenticata?</h1>
    <p class="wi-text-xs wi-text-left wi-p-b-1 wi-w-75 wi-color-dark-grey">
    Inserisci l’e-mail che hai utilizzato per creare il tuo account in modo
    che possiamo inviarti le istruzioni
    su come reimpostare la tua password.
    </p>
    <h3 class="wi-text-s wi-text-bold wi-text-left wi-p-t-2 wi-p-b-1 wi-w-75">Email</h3>
    <label for="email" class="wi-text-left wi-w-75">
      <input
        v-model="v$.email.$model"
        type="text"
        placeholder="Inserisci la tua email"
        id="email"
        :class="{ 'wi-input-error wi-m-b-1': v$.email.$error, 'wi-m-b-2': !v$.email.$error}"
        class="wi-input wi-w-100">
        <div class="input-errors wi-m-b-1" v-for="error of v$.email.$errors" :key="error.$uid">
          <div class="wi-text-xs">{{ error.$message }}</div>
        </div>
    </label>
    <h1
      class="wi-p-t-1 wi-p-b-2 wi-color-grey wi-text-right wi-w-75 cursor-pointer"
      @click="$router.push('/login')"
      @keypress="$router.push('/login')"
    >
      Torna alla home
    </h1>
    <button
      :disabled="v$.email.$invalid"
      @click="resetPassword(state.email)"
      class="wi-button wi-w-75 wi-text-bold"
    >
      Richiedi una nuova Password
    </button>
  </AppAuthLayout>
</template>

<style lang="scss" scoped>
</style>
