import { ConfigState } from '@/core/store/config/enums';
import { InjectionKey } from 'vue';
import { Store } from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const configState = {
  modules: {
    referent: {
      columns: [],
      orderBy: [],
      label: ''
    },
    member: {
      columns: [],
      orderBy: [],
      label: ''
    },
    ride: {
      columns: [],
      filter: [],
      orderBy: [],
      label: ''
    },
    billing: {
      columns: [],
      filter: [],
      orderBy: [],
      label: ''
    }
  },
  region: []
};

export const key: InjectionKey<Store<ConfigState>> = Symbol('Config Store');

export default ({
  state: (): ConfigState => (configState),
  getters: {
    ...getters,
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
});
