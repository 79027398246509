import actions from '@/modules/companies/store/actions';
import getters from '@/modules/companies/store/getters';
import mutations from '@/modules/companies/store/mutations';
import { InjectionKey } from 'vue';
import { Store } from 'vuex';
import { Company, CompanyState } from '@/modules/companies/store/enums';
import { OrderBy } from '@/core/interfaces';

export const companiesState: CompanyState = {
  companiesForm: {} as unknown as Company,
  selectedCompany: null,
  errorCompanies: null,
  companies: [],
  filters:{
    search: '',
    orderBy: OrderBy.ASC,
    page: 1,
  },
  paginate: null,
  ridesList: [],
};

export const key: InjectionKey<Store<CompanyState>> = Symbol('Company Store');

export default ({
  state: (): CompanyState => (companiesState),
  getters: {
    ...getters,
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
});
