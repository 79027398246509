<script lang="ts" setup>
import { onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import SettingsPrice, { PostPrice } from '../services/settings-client';
import alertVuexDefinitions, { Alert, AlertType } from '@/core/store/alert/enums';
const store = useStore();

const prices = ref<PostPrice[]>();
const loading = ref(false);
const successIds = ref<string[]>([]);

onMounted(async () => {
  try {
    loading.value = true;
    const response = await SettingsPrice.getAllPrice();
    // TODO: Set price and thene set data in input v-model attributes
    prices.value = response.data.data;
    prices.value?.forEach(el => {
      el.price = el.price.toString();
    });
  } catch(error: any) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: 'Errore',
      description: error.data.message,
      type: AlertType.Success,
    } as Alert)
  } finally {
    loading.value = false;
  }
});

watch(successIds.value, (newValue, oldValue) => {
  console.log(newValue.length, prices.value?.length)
  if(newValue.length === prices.value?.length) {
    successIds.value = []
  }
})
const save = async (id: string, item: PostPrice) => {
  try {
    loading.value = true;
    const patchResponse = await SettingsPrice.updatePrice(id, {
      has_vip_price: (item.has_vip_price === 1),
      vehicle_type: item.vehicle_type,
      label: item.label,
      price: Math.round(parseFloat(item.price.toString()) * 100) / 100
    });
    const response = await SettingsPrice.getAllPrice();
    // TODO: Set price and thene set data in input v-model attributes
    prices.value = response.data.data;
    prices.value?.map(el => {
      el.price = el.price.toString();
    });
    if(!successIds.value.includes(id)) {
      successIds.value?.push(id);
    }
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: 'Cambio prezzo',
      description: 'Prezzo modificato con successo',
      type: AlertType.Success,
    } as Alert);
    // TODO: Set price and thene set data in input v-model attributes
  } catch(error: any) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: 'Errore Aggiornamento prezzi',
      description: error.data.message,
      type: AlertType.Success,
    } as Alert);
  } finally {
    loading.value = false;
  }
}
</script>

<template>
  <app-layout>
    <div class="wi-card wi-w-100">
      <div class="wi-flex wi-flex-column align-items-start wi-p-x-3 wi-p-t-3">
        <h1 class="wi-text-l wi-text-bold wi-m-b-3 wi-text-left">Calcola prezzo corse</h1>
        <div class="wi-settings" v-for="(price, key) in prices" :key="key">
          <h1 class="wi-text-m wi-m-b-3 wi-text-left">Tipo:
            <strong class="wi-text-bold">{{ price.label }}</strong>
          </h1>
          <div class="wi-flex wi-flex-row w-100 wi-m-b-3 wi-align-e">
            <label for="economy" class="wi-text-left wi-flex wi-w-100 wi-flex-column">
              <strong class="wi-text-bold wi-m-b-1">Prezzo per KM</strong>
              <input
                type="number"
                :disabled="loading"
                v-model.trim="price.price"
                placeholder="Inserisci la tariffa"
                class="wi-input"
              >
            </label>
            <wi-icon icon="check" v-if="successIds.includes(price._id)"></wi-icon>
            <button
              class="wi-button wi-text-bold wi-m-x-3 wi-button-add wi-p-x-2"
              :class="{ loading: 'loading'}"
              @click="save(price._id, price)"
              :disabled="loading"
            >
              SALVA
              <span v-if="loading" class="wi-m-x-1 loader"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<style lang="scss" scoped>
input {
  max-width: 700px;
}

button {
  height: 44px;
}

.wi-loader-button {
  height: 20px;
  width: 0px;
}
</style>
