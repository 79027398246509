import { InjectionKey } from 'vue';
import { Store } from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { CustomerState } from './enums';
import { OrderBy } from '@/core/interfaces';

export const customerState : CustomerState = {
  customerList: [],
  ridesList: [],
  selectedCustomer: null,

  filters:{
    search: '',
    orderBy: OrderBy.ASC, 
  },

  paginate: null,


};

export const key: InjectionKey<Store<CustomerState>> = Symbol('Customer Store');

export default ({
  state: (): CustomerState => (customerState),
  getters: {
    ...getters,
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
});
