import { AuthState } from '@/modules/auth/store/enums';
import { VehicleState } from '@/modules/vehicles/store/enums';
import { AlertState } from '@/core/store/alert/enums';
import { createStore, Module } from 'vuex';
import { CompanyState } from '@/modules/companies/store/enums';
import { CompanyNccState } from '@/modules/companies-ncc/store/enums';
import authStore from '@/modules/auth/store/store';
import companiesStore from '@/modules/companies/store/store';
import companiesNccStore from '@/modules/companies-ncc/store/store';
import customersStore from '@/modules/customers/store/store';
import alertStore from '@/core/store/alert/store';
import configStore from '@/core/store/config/store';
import { DriverState } from '@/modules/drivers/store/enums';
import { CustomerState } from '@/modules/customers/store/enums';
import  referentStore  from '@/modules/referents/store/store';
import {ReferentState} from '@/modules/referents/store/enums';
import { ConfigState } from '@/core/store/config/enums';

export interface RootState {
  auth: AuthState,
  vehicle: VehicleState,
  alert: AlertState,
  // TODO: aggiungere state company
  customer: CustomerState,
  referent: ReferentState,
  configStore: ConfigState,
  companies: CompanyState
  companiesNcc: CompanyNccState
}

const store = createStore({
  modules: {
    auth: authStore as unknown as Module<AuthState, RootState>,
    alert: alertStore as unknown as Module<AlertState, RootState>,
    companies: companiesStore as unknown as Module<CompanyState, RootState>,
    companiesNcc: companiesNccStore as unknown as Module<CompanyNccState, RootState>,
    customers: customersStore as unknown as Module<CustomerState, RootState>,
    referents: referentStore as unknown as Module<ReferentState, RootState>,
    configs: configStore as unknown as Module<ConfigState, RootState>,
  },
});

export default store;
