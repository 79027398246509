<script lang="ts" setup>
import { onMounted, ref, computed } from 'vue';
import PayoutClient from './payout-services/payout-client';
import moment from 'moment';
import alertVuexDefinitions, { Alert, AlertType } from '@/core/store/alert/enums';
import { useStore } from 'vuex';
import enums from "@/core/store/config/enums";
import { useRouter } from 'vue-router';
const router = useRouter();
const store = useStore();
const config = computed(() => store.getters[enums.enums.GETTERS.GetConfig]);
const items = ref();
const driver = ref();
const page = ref(1);
const order = ref('asc');
const status = ref();
const loading = ref(false);
const paginate = ref();
onMounted(async () => {
  getAllPayouts(order.value, page.value);
});

const getAllPayouts = async (order: string, page: number, status?: string, search?: string, payoutTo?: string, payoutFrom?: string) => {
  try {
    loading.value = true;
    const body = {status, payoutTo, payoutFrom, driver: search, order, page}
    if(!status) {
      delete body.status
    }
    if (!search) {
      delete body.driver
    }
    const ridesList = await PayoutClient.getAllPayouts(body);
    if(ridesList.data.success) {
      items.value = ridesList.data.data;
      paginate.value = ridesList.data.paginate;
    } else {
      items.value = [];
    }
  } catch(error: any) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: 'Errore',
      description: error,
      type: AlertType.Error,
    } as Alert);
  } finally {
    loading.value = false;
  }
}
const updateOrderValue = async (value: any) => {
  order.value = value?.target?.value ?? '';
  getAllPayouts(order.value, page.value, status.value, driver.value);
}

const updateSearchValue = async (value: any) => {
  driver.value = value?.target?.value ?? '';
  getAllPayouts(order.value, page.value, status.value, driver.value);
}
const updateFilterValue = async (value: any) => {
  status.value = value?.target?.value ?? '';
  getAllPayouts(order.value, page.value, status.value, driver.value);
}
const updatePage = async (value: any) => {
  page.value = page.value + value;
  getAllPayouts(order.value, page.value, status.value, driver.value);
}

const setPostTour = () => {
  console.log('set post tour');
}
</script>

<template>
  <app-layout>
    <div class="wi-card wi-w-100">
      <table-page
        :has-add="false"
        :has-search="false"
        :filter-value="status"
        :order="order"
        :order-value="order"
        :label="config?.modules?.payouts?.label"
        :filter-by="config?.modules?.payouts?.filter"
        :order-by="config?.modules?.payouts?.order"
        @update:orderValue="updateOrderValue($event)"
        @update:filterValue="updateFilterValue($event)"
        @update:search="updateSearchValue"
      />
      <table-content :loading="loading" :columns="config.modules.payouts.columns" :paginate="paginate" :rows-count="items?.length" @next-page="updatePage(1)" @prev-page="updatePage(-1)">
        <tr
          class="mc-cursor-pointer"
          v-for="item in items"
          :key="item._id"
        >
          <td class="content">{{ moment(item.payoutTo).format('DD MMMM YYYY HH:mm') }}</td>
          <td class="content">{{moment(item.payoutFrom).format('DD MMMM YYYY HH:mm')}}</td>
          <td class="content">€{{ item.amount }}</td>
          <td class="content">{{ item.status }}</td>
          <td class="content">{{ item.driver?.name }}{{ item.driver?.surname }}</td>
          <td class="content wi-flex wi-flex-row wi-justify-center wi-m-w-none wi-w-auto">
            <wi-icon-svg icon="view" width="24" height="24" class="wi-icon wi-icon-pointer wi-m-e-1" @click="router.push('/payout/' + item._id)"></wi-icon-svg>
          </td>
        </tr>
      </table-content>
    </div>
  </app-layout>
</template>

<style lang="scss" scoped>
</style>
