import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-bd5b758e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wi-card wi-w-100" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "content" }
const _hoisted_4 = { class: "content" }
const _hoisted_5 = { class: "content" }
const _hoisted_6 = { class: "content" }
const _hoisted_7 = { class: "content" }
const _hoisted_8 = { class: "content" }
const _hoisted_9 = { class: "content" }
const _hoisted_10 = { class: "content" }
const _hoisted_11 = { class: "content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_table_page = _resolveComponent("table-page")!
  const _component_companies_form = _resolveComponent("companies-form")!
  const _component_wi_modal = _resolveComponent("wi-modal")!
  const _component_table_content = _resolveComponent("table-content")!
  const _component_app_layout = _resolveComponent("app-layout")!

  return (_openBlock(), _createBlock(_component_app_layout, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_table_page, {
          label: "Companies",
          orderBy: _ctx.orderBy,
          "onUpdate:search": _cache[0] || (_cache[0] = ($event: any) => (_ctx.setCompaniesList(
          {
            ..._ctx.companyFilter,
            search: $event.target.value,
          },
          _ctx.paginate.page,
        ))),
          "onUpdate:orderValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.setCompaniesList(
          {
            ..._ctx.companyFilter,
            orderBy: $event.target.value,
          },
          _ctx.paginate.page,
        ))),
          onAddEntities: _cache[2] || (_cache[2] = ($event: any) => (_ctx.showModal = !_ctx.showModal)),
          "onUpdate:reset": _cache[3] || (_cache[3] = ($event: any) => (_ctx.resetValue()))
        }, null, 8, ["orderBy"]),
        (_ctx.showModal)
          ? (_openBlock(), _createBlock(_component_wi_modal, {
              key: 0,
              onClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.showModal=false)),
              textButton: "Aggiungi",
              textClose: "Annulla",
              title: "Aggiungi una company",
              onConfirmEntities: _ctx.setPostCompany
            }, {
              default: _withCtx(() => [
                _createVNode(_component_companies_form, { ref: "submitForm" }, null, 512)
              ]),
              _: 1
            }, 8, ["onConfirmEntities"]))
          : _createCommentVNode("", true),
        _createVNode(_component_table_content, {
          columns: _ctx.columns,
          paginate: _ctx.paginate,
          "rows-count": _ctx.getAllCompanies.length,
          onPrevPage: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setCompaniesList(
          {
            ..._ctx.companyFilter,
            page: parseInt(_ctx.paginate.page ?? '1') - 1
          }
        ))),
          onNextPage: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setCompaniesList(
          {
            ..._ctx.companyFilter,
            page: parseInt(_ctx.paginate.page ?? '1') + 1
          }
        )))
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAllCompanies, (item) => {
              return (_openBlock(), _createElementBlock("tr", {
                class: "mc-cursor-pointer",
                key: item.id,
                onClick: ($event: any) => (_ctx.$router.push(`companies/${item._id}`))
              }, [
                _createElementVNode("td", _hoisted_3, _toDisplayString(_ctx.moment(item.createdAt).format("MMM Do YY")), 1),
                _createElementVNode("td", _hoisted_4, _toDisplayString(item.name), 1),
                _createElementVNode("td", _hoisted_5, _toDisplayString(item.surname), 1),
                _createElementVNode("td", _hoisted_6, _toDisplayString(item.email), 1),
                _createElementVNode("td", _hoisted_7, _toDisplayString(item.company?.bName), 1),
                _createElementVNode("td", _hoisted_8, _toDisplayString(item.company?.sdi), 1),
                _createElementVNode("td", _hoisted_9, _toDisplayString(item.company?.pec), 1),
                _createElementVNode("td", _hoisted_10, _toDisplayString(item.company?.country), 1),
                _createElementVNode("td", _hoisted_11, _toDisplayString(item.company?.pIva), 1)
              ], 8, _hoisted_2))
            }), 128))
          ]),
          _: 1
        }, 8, ["columns", "paginate", "rows-count"])
      ])
    ]),
    _: 1
  }))
}