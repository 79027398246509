import vuexDefinitions, { AuthState, AuthUser } from '@/modules/auth/store/enums';

export default {
  [vuexDefinitions.enums.MUTATIONS.UpdateEmail](
    state: AuthState,
    email: string,
  ): void {
    state.email = email;
  },
  [vuexDefinitions.enums.MUTATIONS.UpdateProfile](
    state: AuthState,
    authUser: AuthUser,
  ): void {
    state.authUser = authUser;
  },
  [vuexDefinitions.enums.MUTATIONS.UpdateToken](
    token: string
  ): void {
    sessionStorage.setItem('token', token)
  },
  [vuexDefinitions.enums.MUTATIONS.UpdateProfile](
    state: AuthState,
    authState: AuthUser,
  ): void {
    state.authUser = authState;
  },
};
