<template>
  <span class="fe-icon">
    <img :src="iconPath" :width="width" :height="height" :alt="alt">
  </span>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import addons from '@/core/addons/functions';
import { defineProps } from 'vue';

const props = defineProps({
  icon: {
    type: String,
    default: null,
  },
  height: {
    type: [String, Number],
    default: null,
  },
  width: {
    type: [String, Number],
    default: null,
  },
  alt: {
    type: String,
    default: '',
  },
});

const iconPath = computed(() => `${addons.getBaseURL()}/svg/${props.icon}.svg`);
</script>

<style lang="scss" scoped>
.fe-icon {
  min-width: 1rem;
  min-height: 1rem;
}
</style>