import vuexDefinitions, { CompanyState, Company, CompanyFilter } from '@/modules/companies/store/enums';
import { ActionContext } from 'vuex';
import store, { RootState } from '@/store';
import UserClient from '@/core/services/user-client';
import { AuthRole } from '@/modules/auth/store/enums';
import alertVuexDefinitions, { Alert, AlertType } from '@/core/store/alert/enums';
import { IResponse, OrderBy } from '@/core/interfaces';
import router from '@/router';
import CompanyClient from '../services/company-client';
import RidesClient from '../../rides/rides-services/ride-client';
import { companiesState } from './store';
import { formatErrorMessages } from '@/core/addons/functions';

type CompanyActionContext = ActionContext<CompanyState, RootState>

export default {
  [vuexDefinitions.enums.ACTIONS.SetAllCompanies]: // per avere lo stesso nome del metodo in qualsiasi punto
    async (context: CompanyActionContext, payload: CompanyFilter): Promise<void> => {
        // il context in questo caso rappresenta il nostro modulo vuex
      context.commit(vuexDefinitions.enums.MUTATIONS.UpdateCompanyFilter, payload);
      const companyList = await UserClient.getAllUsers(
        AuthRole.COMPANY,
        context.state.filters?.search,
        context.state.filters?.orderBy,
        context.state.filters?.page
      );
      context.commit(vuexDefinitions.enums.MUTATIONS.UpdateAllCompanies, companyList.data.data);
      context.commit(vuexDefinitions.enums.MUTATIONS.UpdatePage, companyList.data.paginate);
    },


  [vuexDefinitions.enums.ACTIONS.SetPostCompany]:
    async (context: CompanyActionContext): Promise<void> => {
      try {
        const response = await CompanyClient
          .createCompany({
            ...companiesState.companiesForm,
            role: AuthRole.COMPANY,
            terms: true,
          });
        const company = response?.data?.data as Company;
        if (response.data?.success) {
          context.commit(vuexDefinitions.enums.MUTATIONS.UpdateSelectedCompany, company);
          store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
            title: 'Ok !',
            description: response.data.message,
            type: AlertType.Success,
          } as Alert);
          router.push(`companies/${company._id}`);
        } else {
          store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
            title: 'Errore Generico',
            description: response.data?.errorMessage,
            type: AlertType.Error,
          } as Alert);
        }
      } catch (error: any) {
        const errorCode = error.code;
        let errorMessage;
        if(errorCode === "ERR_BAD_REQUEST") {
          errorMessage = formatErrorMessages(error.response.data.message);
        }
        store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
          title: errorCode,
          description: errorMessage ?? '',
          type: AlertType.Error,
        } as Alert);
      }
    },

  [vuexDefinitions.enums.ACTIONS.SetCompanyForm]:
    async (context: CompanyActionContext, payload: any): Promise<void> => {
      context.commit(
        vuexDefinitions.enums.MUTATIONS.UpdateCompanyForm,
        {
          ...context.state.companiesForm,
          ...payload,
        },
      );
    },

  [vuexDefinitions.enums.ACTIONS.SetSelectedCompany]:
    async (context: CompanyActionContext, id: string): Promise<void> => {
        try{
            const singleCompany = await UserClient.getSingleUser(id);
            context.commit(vuexDefinitions.enums.MUTATIONS.UpdateSelectedCompany, singleCompany.data.data);
        }catch {
            store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
              title: 'Errore Generico',
              description: 'Descrizione Errore Generico',
              type: AlertType.Error,
            } as Alert);
        }
    },
};
