import constants from './constants';

export const AlertType = {
  Success: 'success',
  Error: 'error',
  Warning: 'warning',
};

export interface Alert {
  id: number
  title: string
  description: string
  type?: string
}

export interface AlertState {
  alerts: Alert[],
}

/**
 * PUBLIC ACTIONS, MUTATIONS AND GETTERS EXPOSED TO THE COMPONENTS
 */
const enums = {
  ACTIONS: {
    SetAlert: 'set-alert',
    RemoveAlert: 'remove-alert',
    AutoHideAlert: 'auto-hide-alert',
  },
  GETTERS: {
    GetAlerts: 'get-alerts',
  },
  MUTATIONS: {
    AddAlert: 'add-Alert',
    RemoveAlert: 'remove-Alert',
    PopAlert: 'pop-alert',
  },
};
export default {
  namespaced: {
    ACTIONS: {
      SetAlert: `${constants.MODULE_NAME}/${enums.ACTIONS.SetAlert}`,
      RemoveAlert: `${constants.MODULE_NAME}/${enums.ACTIONS.RemoveAlert}`,
      AutoHideAlert: `${constants.MODULE_NAME}/${enums.ACTIONS.AutoHideAlert}`,
    },
    GETTERS: {
      GetAlerts: `${constants.MODULE_NAME}/${enums.GETTERS.GetAlerts}`,
    },
    MUTATIONS: {
      AddAlert: `${constants.MODULE_NAME}/${enums.MUTATIONS.AddAlert}`,
      RemoveAlert: `${constants.MODULE_NAME}/${enums.MUTATIONS.RemoveAlert}`,
      PopAlert: `${constants.MODULE_NAME}/${enums.MUTATIONS.PopAlert}`,
    },
  },
  enums,
};
