<script lang="ts" setup>
import { onMounted, ref, computed, defineProps } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import configEnums from "@/core/store/config/enums";
import { useStore } from 'vuex';
import alertVuexDefinitions, { Alert, AlertType } from '@/core/store/alert/enums';
import { Ride } from '@/modules/rides/store/enums';
import RidesClient from '@/modules/rides/rides-services/ride-client';
import moment from 'moment';

const store = useStore();
const props = defineProps<{
  driver: any;
}>();
const route = useRoute();
const search = ref('');
const page = ref(1);
const order = ref('asc');
const status = ref();
const config = computed(() => store.getters[configEnums.enums.GETTERS.GetConfig]);
const paginate = ref();
const loading = ref(false);
const router = useRouter();

// Load drive on mounted
onMounted(async () => {
  getAllRide(status.value, search.value, order.value, page.value);
});

const getRidesList = ref<Ride[]>();
const getAllRide = async (status: string, search: string, order: string, page: number) => {
    try {
        loading.value = true;
        let ridesList;
        if(!status) {
            console.log('status', props.driver?._id)
            ridesList = await RidesClient.getAllRides({driver: route.params.id, search, order, page});
        } else {
            ridesList = await RidesClient.getAllRides({driver: route.params.id, status, search, order, page});
        }
        if(ridesList.data.success) {
            getRidesList.value = ridesList.data.data as Ride[];
            paginate.value = ridesList.data.paginate;
        } else {
            getRidesList.value = [];
        }
    } catch(error: any) {
        store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
        title: 'Errore',
        description: error,
        type: AlertType.Error,
        } as Alert);
    } finally {
        loading.value = false;
    }
}

const updateOrderValueRide = async (value: any) => {
  order.value = value?.target?.value ?? '';
  getAllRide(status.value, search.value, order.value, page.value);
}

const updateSearchValueRide = async (value: any) => {
  search.value = value?.target?.value ?? '';
  getAllRide(status.value, search.value, order.value, page.value);
}
const updatePageRide = async (value: any) => {
  page.value = page.value + value;
  getAllRide(status.value, search.value, order.value, page.value);
}
</script>

<template>
    <div class="wi-card wi-w-100">
        <table-page
        :has-add="false"
        :filter-value="status"
        :order="order"
        :order-value="order"
        :label="config.modules.ride.label"
        :orderBy="config.modules.ride.order"
        :filter-by="config.modules.ride.filter"
        @update:orderValue="updateOrderValueRide($event)"
        @update:filterValue="getAllRide($event.target?.value, search, order, page)"
        @update:search="updateSearchValueRide"
        />
        <table-content :columns="config.modules.ride.columns" :paginate="paginate" :rows-count="getRidesList?.length" :loading="loading" @next-page="updatePageRide(1)" @prev-page="updatePageRide(-1)">
        <tr class="mc-cursor-pointer" v-for="item in getRidesList" :key="item?._id">
            <td class="content">{{ moment(item.start_time).format('DD-MM-YYYY HH:mm') }}</td>
            <td class="content">{{item.pickup_address?.address}}</td>
            <td class="content">{{item.dropoff_address?.address}}</td>
            <td class="content">€{{item.amount}}</td>
            <td class="content">{{ item.customer?.name }} {{item.customer?.surname }}</td>
            <td class="content">{{ item.driver?.name }} {{item.driver?.surname }}</td>
            <td class="content wi-flex wi-flex-row wi-justify-center wi-m-w-none wi-w-auto">
            <wi-icon-svg icon="view" width="24" height="24" class="wi-icon wi-icon-pointer wi-m-e-1" @click="router.push('/rides/' + item._id)"></wi-icon-svg>
            <wi-icon-svg v-if="item.status !== 'COMPLETED' && item.status !== 'CANCELLED'" icon="delete" width="24" height="24"  class="wi-icon wi-icon-pointer wi-m-e-1" @click="openDeleteRide(item)"></wi-icon-svg>
            </td>
        </tr>
        </table-content>
    </div>
</template>