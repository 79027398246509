import { IResponse } from '@/core/interfaces';
import axios from 'axios';
import { PayloadCreateRide } from '../store/enums';

const getAllRides = async (params: any) : Promise<IResponse> => axios({
  url: `${process.env.VUE_APP_API}/ride`,
  params,
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}` ?? '',
  },
});

const allRide = async (params: any) => {
  return axios.request({
    url: `${process.env.VUE_APP_API}/ride/auth-rides`,
    params,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
  });
};

const findAddress = async (place?: string, lat?: string, lng?: string) => {
  console.log(place)
  return axios.request({
    url: `${process.env.VUE_APP_API}/ride/find-address`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
    data: {
      place,
      lat,
      lng
    }
  });
};

const create = async (data: PayloadCreateRide) => {
  console.log(data);
  return axios.request({
    url: `${process.env.VUE_APP_API}/ride`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
    data,
  });
};

const cancelRide = async (id: string) => {
  return axios.request({
    url: `${process.env.VUE_APP_API}/ride/cancel-ride/${id}`,
    method: 'patch',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
  }).catch((e) => {
    return e.toJSON();
  });
};


const getSingleRide = async (id: string) => {
  return axios.request({
    url: `${process.env.VUE_APP_API}/ride/${id}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
  }).catch((e) => {
    return e.toJSON();
  });
};

const RidesClient = {
  getAllRides,
  allRide,
  findAddress,
  create,
  getSingleRide,
  cancelRide,
};
Object.freeze(RidesClient);
export default RidesClient;