import vuexDefinitions, { ConfigState } from '@/core/store/config/enums';
import { ActionContext } from 'vuex';
import store, { RootState } from '@/store';
import ConfigClient from '@/core/services/config-client';

type VehicleActionContext = ActionContext<ConfigState, RootState>

export default {
  [vuexDefinitions.enums.ACTIONS.SetConfig]:
    async (context: VehicleActionContext): Promise<void> => {
      try {
        const respose = await ConfigClient.getAllConfig();
        if(respose.data.success) {
          console.log(respose.data.data)
          context.commit(vuexDefinitions.enums.MUTATIONS.UpdateConfig, respose.data.data);
        }
      } catch(error) {
        console.log(error);
      }
    },
};
