<script lang="ts">
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  name: 'CardTable',
  props: {
    columns: {
      type: Array as PropType<Array<string>>,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    hasShowMore: {
      type: Boolean,
      default: true,
    },
    rowsCount: {
      type: Number,
      default: 0,
    },
    hasPaginate: {
      type: Boolean,
      default: true,
    },
    routeExtra : {
      type: String,
      default: ''
    }
  },
});
</script>

<template>
  <div class="mc-min-w-l-50 mc-w-xxl-100 wi-p-y-2">
    <div class="wi-card">
      <div class="wi-p-t-4 wi-p-x-2 wi-flex wi-flex-row wi-justify-between">
        <h1 class="wi-color-primary wi-text-left wi-text-l wi-text-bold">{{ title }}</h1>
        <router-link v-show="hasShowMore"
        :to="routeExtra"
        class="wi-text-right wi-text-xs wi-text-bold">vedi di più</router-link>
      </div>
      <table-content :columns="columns" :rows-count="rowsCount" :has-paginate="hasPaginate">
        <slot />
      </table-content>
    </div>
  </div>
</template>

<style scoped>
a {
  text-decoration: none;
}

</style>
