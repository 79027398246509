import axios from 'axios';

const getReportData = async () => {
  return axios({
    url: `${process.env.VUE_APP_API}/dashboard`,
    method: 'get',
    headers:  {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
  });
};

const getDriverLocations = async () => {
  return axios({
    url: `${process.env.VUE_APP_API}/dashboard/drivers`,
    method: 'get',
    headers:  {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
  });
};
const DashboardClient = {
    getReportData,
    getDriverLocations
};
  // Disallow new properties on our object
Object.freeze(DashboardClient);
export default DashboardClient;
