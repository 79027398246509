<script lang="ts" setup>
import { reactive } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { mapActions, useStore } from 'vuex';
import AppAuthLayout from '../components/AppAuthLayout.vue';
import enums from '../store/enums';
import AuthClient from '../services/auth-client';
import { useRouter } from 'vue-router';
import alertVuexDefinitions, { Alert, AlertType } from '@/core/store/alert/enums';

const store = useStore();
const router = useRouter();
const state = reactive({
  email: '',
  password: '',
  typeInput: 'password',
  terms: false,
});

const rules = {
  email: { required, email },
  password: { required },
  terms: { required },
};

const getChangeType = (type: string): void => {
  state.typeInput = type
}

const v$ = useVuelidate(rules, state);


const login = async () => {
  try {
    const response = await AuthClient.loginWithPassword(state.email, state.password);
    if(response.data.success) {
      store.dispatch(enums.enums.ACTIONS.SetEmail, state.email);
      if (response.data.data.user) {
        sessionStorage.setItem('token', response.data.data.jwt)
        store.dispatch(enums.enums.ACTIONS.SetProfile, response.data.data.user);
        if(response.data.data.user.role === 'SUPERADMIN') {
          router.push('/');
        } else {
          router.push('/members');
        }
      } else {
        router.push('/register-profile');
      }
    } else {
      throw new Error(response.message);
    }
  } catch (e) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: 'Errore Generico',
      description: 'Descrizione Errore Generico',
      type: AlertType.Error,
    } as Alert);
  }
};
</script>

<template>
  <AppAuthLayout>
    <h1 class="wi-text-l wi-text-bold wi-text-left wi-p-b-3 wi-w-75">Ciao, Benvenuto!</h1>
    <h3 class="wi-text-s wi-text-bold wi-text-left wi-p-b-1 wi-w-75">Inserisci la tua email</h3>
    <label data-test="login-email" for="email" class="wi-text-left wi-w-75">
      <input
        v-model="v$.email.$model"
        type="text"
        placeholder="Inserisci la tua email"
        id="email"
        :class="{ 'wi-input-error wi-m-b-1': v$.email.$error, 'wi-m-b-2': !v$.email.$error}"
        class="wi-input wi-w-100">
        <div class="input-errors wi-m-b-1" v-for="error of v$.email.$errors" :key="error.$uid">
          <div class="wi-text-xs">{{ error.$message }}</div>
        </div>
    </label>
    <h3 class="wi-text-s wi-text-bold wi-text-left wi-p-b-1 wi-w-75">Password</h3>

    <label data-test="login-password" for="password" class="wi-text-left wi-w-75">
      <div class="pass-container">
        <input
          v-model="v$.password.$model"
          :type="state.typeInput"
          placeholder="Inserisci la tua password"
          id="password"
          :class="{ 'wi-input-error wi-m-b-1': v$.password.$error, 'wi-m-b-2': !v$.password.$error}"
          class="wi-input wi-w-100 pass-input"
        >
          <button class="eye" v-if="state.typeInput === 'password'" @click="getChangeType('text')">
            <i class="fa-solid fa-eye "></i> 
          </button>
          <button class="eye" v-else @click="getChangeType('password')">
            <i class="fa-solid fa-eye-slash"></i> 
          </button>     
      </div>
      
        <div class="input-errors wi-m-b-1" v-for="error of v$.password.$errors" :key="error.$uid">
          <div class="wi-text-xs">{{ error.$message }}</div>
        </div>
    </label>
    <div class="wi-flex wi-flex-column wi-justify-start wi-w-75">
      <div class="wi-flex wi-flex-row wi-justify-right wi-align-center">
        <input :v-model="v$.terms.$model" type="checkbox" class="wi-checkbox" id="cb" />
        <h1 class="wi-color-grey wi-m-s-1">Ricordami!</h1>
      </div>
      <p
        class="wi-p-t-1 wi-p-b-2 wi-color-grey cursor-pointer wi-text-left"
        @click="$router.push('/lostpassword')"
        @keypress="$router.push('/lostpassword')"
      >
        Hai dimenticato la password?
      </p>
    </div>
    <button
      data-test="login-button"
      :disabled="v$.email.$invalid || v$.password.$invalid || v$.terms.$invalid"
      @click="login"
      class="wi-button wi-w-75 wi-text-bold"
    >Accedi</button>
  </AppAuthLayout>
</template>

<style lang="scss" scoped>
.pass-input{
  background-color: transparent;
  height: 37px;
  border: none;
}
.pass-input:focus{
  outline: 0 !important;
  //outline: -webkit-focus-ring-color auto 1px;
}

.pass-container{
  background-color: white;
  display: flex;
  height: 40px;
  border: solid 1px #E9E9E9;
  margin-bottom: 1rem;
}


.pass-container:focus{
  outline: 1px dashed black;
}

.eye{
  border: none;
  background-color: transparent;
}




</style>
