import { Paginate } from '@/core/interfaces';
import vuexDefinitions, { CustomerState, Customer, Filters } from '@/modules/customers/store/enums';
import { Ride } from '@/modules/rides/store/enums';
  
export default {
    [vuexDefinitions.enums.MUTATIONS.UpdateCustomerList](
        state: CustomerState,
        customers: Customer[],
    ): void {
        state.customerList = customers;
    },

    [vuexDefinitions.enums.MUTATIONS.UpdateFilters](
        state: CustomerState,
        filterCustomer: Filters,
    ): void {
        state.filters = filterCustomer;
    },

    [vuexDefinitions.enums.MUTATIONS.UpdatePage](
        state: CustomerState,
        paginate: Paginate,
    ): void {
        state.paginate = paginate;
    },
    [vuexDefinitions.enums.MUTATIONS.UpdateSelectedCustomer](
        state: CustomerState,
        selectedCustomer: Customer,
    ): void {
        state.selectedCustomer = selectedCustomer;
    },
    [vuexDefinitions.enums.MUTATIONS.UpdateRidesList](
        state: CustomerState,
        ridesList: Ride[],
    ): void {
        state.ridesList = ridesList;
    },


};
  