<script lang="ts" setup>
import { onMounted, ref, computed, defineProps } from 'vue';
import { useRouter } from 'vue-router';
import configEnums from "@/core/store/config/enums";
import { useStore } from 'vuex';
import alertVuexDefinitions, { Alert, AlertType } from '@/core/store/alert/enums';
import { Vehicle } from '@/modules/vehicles/store/enums';
import VehicleClient from '@/modules/vehicles/services/vehicle-client';
import moment from 'moment';

const store = useStore();
const props = defineProps<{
  driver: any;
}>();
const search = ref('');
const page = ref(1);
const order = ref('asc');
const status = ref();
const config = computed(() => store.getters[configEnums.enums.GETTERS.GetConfig]);
const paginate = ref();
const loading = ref(false);
const deleteVehicleId = ref('');
const showModalDelete = ref(false);

const getVehiclelist = ref<Vehicle[]>();
const baseIMGPath = process.env.VUE_APP_API_PHOTO;


const getAllVehicle = async (search: string, order: string, page: number) => {
    try {
        console.log('search', props.driver);
        loading.value = true;
        const vehicle = await VehicleClient.getAllVehicles({ search, driver: props.driver?._id, order, page });
        if (vehicle.data.success) {
            getVehiclelist.value = vehicle.data.data as Vehicle[];
            paginate.value = vehicle.data.paginate;
        } else {
            getVehiclelist.value = [];
        }
    } catch (error: any) {
        store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
            title: 'Errore',
            description: error,
            type: AlertType.Error,
        } as Alert);
    } finally {
        loading.value = false;
    }
}
// Load drive on mounted
onMounted(async () => {
    await getAllVehicle(status.value, order.value, page.value);
    book_document.value = baseIMGPath + '/' + (getVehiclelist.value?.book_document ?? getVehiclelist.value?._id)
});

const updateOrderValueVehicle = async (value: any) => {
    order.value = value?.target?.value ?? '';
    getAllVehicle(search.value, order.value, page.value);
}

const updateSearchValueVehicle = async (value: any) => {
    search.value = value?.target?.value ?? '';
    getAllVehicle(search.value, order.value, page.value);
}

const updatePageVehicle = async (value: any) => {
    page.value = page.value + value;
    getAllVehicle(search.value, order.value, page.value);
}

const toggleActiveVehicle = async (id: string) => {
    try {
        const response = await VehicleClient.toggleEnabled(id);
        if (response.data.success) {
            getAllVehicle(search.value, order.value, page.value);
            const title = response.data.data.isEnabled ? 'Veicolo Attivo' : 'Veicolo Disattivo'
            store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
                title: 'Veicolo',
                description: 'Ora questo veicolo è pronto per essere usato nelle corse',
                type: AlertType.Success,
            } as Alert);
        }
    } catch (error) {
        console.log(error);
        store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
            title: 'Errore Generico',
            description: 'Errore nel rendere attivo o disattivo il veicolo',
            type: AlertType.Error,
        } as Alert);
    }
};


const deleteVehicle = async (id: string) => {
  try {
    loading.value = true;
    const deletedVehicle = await VehicleClient.deleteVehicle(id)
    if (deletedVehicle) {
      getAllVehicle(status.value, search.value, order.value, page.value);
    }
  }catch {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: 'Errore Generico',
      description: 'Descrizione Errore Generico',
      type: AlertType.Error,
    } as Alert);
  } finally {
    loading.value = false;
  }
};

const openDeleteModal = (id: string) => {
  deleteVehicleId.value = id;
  showModalDelete.value = !showModalDelete.value;
};
const confirmDeleteModal = () => {
  deleteVehicle(deleteVehicleId.value)
  showModalDelete.value = !showModalDelete.value;
};

const vehicle_document = (document: string) => {
    return baseIMGPath + '/' + document;
}

const book_document = (document: string) => {
    return baseIMGPath + '/' + document;
}
</script>

<template>
    <div class="wi-card wi-w-100">
        <table-page :has-add="false" :order="order" :order-value="order" :label="config.modules.vehicle.label"
            :orderBy="config.modules.vehicle.order" @update:orderValue="updateOrderValueVehicle($event)"
            @update:search="updateSearchValueVehicle" />
        <wi-modal v-if="showModalDelete" title="Sei sicuro di voler eliminare il veicolo" textButton="Procedi"
            textClose="Annulla" @close="showModalDelete = false" @confirm-entities="confirmDeleteModal()">
            <p class="wi-text-left wi-m-b-4">Sei sicuro di voler cancellare il veicolo in questo modo non potrai piu
                risalire ai dati che riguardano il veicolo</p>
        </wi-modal>
        <table-content :columns="config.modules.vehicle.columns" :paginate="paginate" :rowsCount="getVehiclelist?.length"
            :loading="loading" @next-page="updatePageVehicle(1)" @prev-page="updatePageVehicle(-1)">
            <tr class="mc-cursor-pointer" v-for="item in getVehiclelist" :key="item._id">
                <td class="content">
                    <input @change="toggleActiveVehicle(item._id)" type="checkbox" :checked="item.isEnabled">
                </td>
                <td class="content">{{ `${item.driver?.name ?? '- '}${item.driver?.surname ?? '-'}` }}</td>
                <td class="content">{{ item.make }}</td>
                <td class="content">{{ item.type }}</td>
                <td class="content">{{ moment(item.year).format("YYYY") }}</td>
                <td class="content">{{ item.model }}</td>
                <td class="content">{{ item.plate }}</td>
                <td class="content">
                    <a :href="book_document(item.book_document)">
                        <wi-icon icon="download"></wi-icon>
                    </a>
                </td>
                <td class="content">
                    <a :href="vehicle_document(item.vehicle_document)">
                        <wi-icon icon="download"></wi-icon>
                    </a>
                </td>
                <td class="content">
                    <a :href="vehicle_document(item.licenze_document)">
                        <wi-icon icon="download"></wi-icon>
                    </a>
                </td>
                <td class="content wi-flex wi-flex-row wi-justify-center">
                    <wi-icon height="17px" width="17px" icon="trash" class="cursor-pointer"
                        @click="openDeleteModal(item._id)"> </wi-icon>
                </td>
            </tr>
        </table-content>
    </div>
</template>
