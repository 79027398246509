import actions from '@/modules/companies-ncc/store/actions';
import getters from '@/modules/companies-ncc/store/getters';
import mutations from '@/modules/companies-ncc/store/mutations';
import { InjectionKey } from 'vue';
import { Store } from 'vuex';
import { Company, CompanyNccState } from '@/modules/companies-ncc/store/enums';
import { OrderBy } from '@/core/interfaces';

export const companiesState: CompanyNccState = {
  companiesForm: {} as unknown as Company,
  selectedCompany: null,
  errorCompanies: null,
  companies: [],
  filters:{
    search: '',
    orderBy: OrderBy.ASC,
    page: 1,
  },
  paginate: null,
  ridesList: [],
};

export const key: InjectionKey<Store<CompanyNccState>> = Symbol('Company Ncc Store');

export default ({
  state: (): CompanyNccState => (companiesState),
  getters: {
    ...getters,
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
});
