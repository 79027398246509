<script lang="ts" setup>
import { defineEmits, onMounted, defineProps } from 'vue';

const props = defineProps({
  search: {
    type: String,
    default: ''
  },
  placeholder: {
    type: String,
    default: 'Inserisci il proprietario del veicolo'
  },
  hasOpen: {
    type: Boolean,
    default: false,
  }
});

const emits = defineEmits(['update:search', 'reset:items']);


const setFilterSearch = (event: string) => {
  // TODO: Set trim in this section
  if (event.length > 2) {
    emits('update:search', event);
  }
};

const resetValue = () => {
  emits('update:search', '');
};

const closeDropdown = () => {
  emits('reset:items');
};
</script>

<template>
  <div class="wi-dropdown-container">
    <div class="input-container wi-flex wi-flex-row wi-justify-right wi-align-center">
      <input id="driver" type="text" :value="search" :placeholder="placeholder"
        v-debounce:1000ms="(event: string) => setFilterSearch(event)" debounce-events="input"
        v-click-outside="closeDropdown" class="wi-input wi-modal-input input">
      <button @click="resetValue" v-show="search.length > 0" class="delete">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
    <div class="wi-dropdown" v-if="hasOpen">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wi-dropdown {
  margin: auto;
  max-height: 250px;
  overflow-y: auto;
  border-radius: 0 0px 10px 10px;
  box-shadow: 0px 3px 6px #00000029;
  position: absolute;
  width: 100%;
  background-color: white;
  z-index: 10;
  &.bottom {
    bottom: 43px;
  }

  .item {
    width: 100%;
  }

  &-container {
    position: relative;
  }
}

input {
  background-color: transparent;
  height: 40px;
  border: none;
  width: 100%;
}

.input:focus {
  outline: 0 !important;
}

.input-container {
  width: 100%;
  display: flex;
  height: 40px;
  border: solid 1px #E9E9E9;
  margin-top: .3rem;
}

.input-container:focus {
  outline: 1px solid grey;
}

.delete {
  border: none;
  background-color: transparent;
}
</style>
