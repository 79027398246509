import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, vShow as _vShow, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-08a108e5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "mc-min-w-l-50 mc-w-xxl-100 wi-p-y-2" }
const _hoisted_2 = { class: "wi-card" }
const _hoisted_3 = { class: "wi-p-t-4 wi-p-x-2 wi-flex wi-flex-row wi-justify-between" }
const _hoisted_4 = { class: "wi-color-primary wi-text-left wi-text-l wi-text-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_table_content = _resolveComponent("table-content")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h1", _hoisted_4, _toDisplayString(_ctx.title), 1),
        _withDirectives(_createVNode(_component_router_link, {
          to: _ctx.routeExtra,
          class: "wi-text-right wi-text-xs wi-text-bold"
        }, {
          default: _withCtx(() => [
            _createTextVNode("vedi di più")
          ]),
          _: 1
        }, 8, ["to"]), [
          [_vShow, _ctx.hasShowMore]
        ])
      ]),
      _createVNode(_component_table_content, {
        columns: _ctx.columns,
        "rows-count": _ctx.rowsCount,
        "has-paginate": _ctx.hasPaginate
      }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ]),
        _: 3
      }, 8, ["columns", "rows-count", "has-paginate"])
    ])
  ]))
}