import { PaginateData } from '@/core/interfaces';
import { UserCompany } from '@/modules/auth/store/enums';
import constants from '@/modules/companies-ncc/store/constants';
import { Ride } from '@/modules/rides/store/enums';


export interface Company {
  _id: string
  name: string,
  surname: string,
  email: string,
  createdAt?: string,
  company: UserCompany,
  phone: string,
}

export interface CompanyFilter {
  search: string,
  orderBy: string,
  page: number
}

export interface CompanyNccState extends PaginateData {
  companiesForm: Company | null,
  selectedCompany: Company | null,
  errorCompanies: string | null,
  filters: CompanyFilter | null,
  companies: Company[] | null,
  ridesList: Ride[] | null,

}

/**
 * PUBLIC ACTIONS, MUTATIONS AND GETTERS EXPOSED TO THE COMPONENTS
 */
const enums = {
  ACTIONS: {
    SetAllCompanies: 'set-all-companies-ncc',
    SetPostCompany: 'set-post-company-ncc',
    SetCompanyForm: 'set-company-form-ncc',
    SetDeleteCompany: 'set-delete-company-ncc',
    SetUpdateCompany: 'set-update-company-ncc',
    SetSelectedCompany: 'set-selected-company-ncc',
    SetCompanyData: 'set-company-data-ncc',
    SetPage: 'set-company-page-ncc',
    SetRidesList: 'set-rides-company-ncc'
  },
  GETTERS: {
    GetAllCompanies: 'get-all-companies-ncc',
    GetSelectedCompany: 'get-Selected-company-ncc',
    GetCompanyForm: 'get-company-form-ncc',
    GetErrorCompany: 'get-error-company-ncc',
    GetCompanyFilters: 'get-company-filter-ncc',
    GetPage: 'get-company-page-ncc',
    GetRidesList: 'get-rides-company-ncc'
  },
  MUTATIONS: {
    UpdateAllCompanies: 'update-all-companies-ncc',
    UpdateSelectedCompany: 'update-selected-company-ncc',
    UpdateCompanyForm: 'update-company-form-ncc',
    UpdateCompanyFormData: 'update-company-formData-ncc',
    UpdateCompanyFormError: 'update-company-formError-ncc',
    UpdateCompanyFilter: 'update-company-filter-ncc',
    UpdatePage: 'update-company-page-ncc',
    UpdateRidesList: 'update-rides-company-ncc',
  },
};
export default {
  namespaced: {
    ACTIONS: {
      SetAllCompanies: `${constants.MODULE_NAME}/${enums.ACTIONS.SetAllCompanies}`,
      SetPostCompany: `${constants.MODULE_NAME}/${enums.ACTIONS.SetPostCompany}`,
      SetDeleteCompany: `${constants.MODULE_NAME}/${enums.ACTIONS.SetDeleteCompany}`,
      SetUpdateCompany: `${constants.MODULE_NAME}/${enums.ACTIONS.SetUpdateCompany}`,
      SetGetCompany: `${constants.MODULE_NAME}/${enums.ACTIONS.SetSelectedCompany}`,
      SetCompanyData: `${constants.MODULE_NAME}/${enums.ACTIONS.SetCompanyData}`,
      SetPage: `${constants.MODULE_NAME}/${enums.ACTIONS.SetPage}`,
      SetRidesList: `${constants.MODULE_NAME}/${enums.ACTIONS.SetRidesList}`,
    },
    GETTERS: {
      GetAllCompanies: `${constants.MODULE_NAME}/${enums.GETTERS.GetAllCompanies}`,
      GetSelectedCompany: `${constants.MODULE_NAME}/${enums.GETTERS.GetSelectedCompany}`,
      GetCompanyForm: `${constants.MODULE_NAME}/${enums.GETTERS.GetCompanyForm}`,
      GetErrorCompany: `${constants.MODULE_NAME}/${enums.GETTERS.GetErrorCompany}`,
      GetCompanyFilters: `${constants.MODULE_NAME}/${enums.GETTERS.GetCompanyFilters}`,
      GetPage: `${constants.MODULE_NAME}/${enums.GETTERS.GetPage}`,
      GetRidesList: `${constants.MODULE_NAME}/${enums.GETTERS.GetRidesList}`,
    },
    MUTATIONS: {
      UpdateAllCompanies: `${constants.MODULE_NAME}/${enums.MUTATIONS.UpdateAllCompanies}`,
      UpdateCompany: `${constants.MODULE_NAME}/${enums.MUTATIONS.UpdateSelectedCompany}`,
      UpdateCompanyFormData: `${constants.MODULE_NAME}/${enums.MUTATIONS.UpdateCompanyFormData}`,
      UpdateCompanyForm: `${constants.MODULE_NAME}/${enums.MUTATIONS.UpdateCompanyForm}`,
      UpdateCompanyFormError: `${constants.MODULE_NAME}/${enums.MUTATIONS.UpdateCompanyFormError}`,
      UpdatePage: `${constants.MODULE_NAME}/${enums.MUTATIONS.UpdatePage}`,
    },
  },
  enums,
};
