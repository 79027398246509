<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { Driver } from '../store/enums';
import RideList from '../components/RideList.vue';
import VehicleList from '../components/VehicleList.vue';
import DriverData from '../components/DriverData.vue';
import DriverArea from '../components/DriverArea.vue';
import UserClient from '@/core/services/user-client';
import { useStore } from 'vuex';
import alertVuexDefinitions, { Alert, AlertType } from '@/core/store/alert/enums';
import { useRoute } from 'vue-router';

const store = useStore();
const driver = ref<Driver>();
const route = useRoute();
const getDriver = async (id: string) => {
  try {
    const driverSelected = await UserClient.getSingleUser(id);
    if (driverSelected.data.success) {
      driver.value = driverSelected.data.data as Driver;
    } else {
      console.log('driver data not found')
      driver.value = undefined;
    }
  } catch (error) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: "Errore",
      description: error,
      type: AlertType.Error,
    } as Alert);
  }
};

onMounted(async () => {
  await getDriver(route.params.id as string);
});

const currentTab = ref('profile');
</script>
<template>
  <app-layout :has-back="true">
    <div class="wi-flex wi-flex-column wi-w-100">
      <div class="wi-flex wi-justify-s wi-align-center wi-m-y-2">
        <a @click="currentTab = 'profile'" class="wi-text cursor-pointer wi-m-e-2"
          :class="currentTab === 'profile' ? 'wi-text-bold' : ''">Dettagli Driver</a>
        <a @click="currentTab = 'rides'" class="wi-text cursor-pointer wi-m-e-2"
          :class="currentTab === 'rides' ? 'wi-text-bold' : ''">Lista Corse</a>
        <a @click="currentTab = 'vehicle'" class="wi-text cursor-pointer wi-m-e-2"
          :class="currentTab === 'vehicle' ? 'wi-text-bold' : ''">Veicoli</a>
        <a @click="currentTab = 'area'" class="wi-text cursor-pointer"
          :class="currentTab === 'area' ? 'wi-text-bold' : ''">Area di lavoro</a>
      </div>
      <div v-if="currentTab === 'profile'" class="wi-card wi-w-100 wi-m-b-5 wi-p-t-3 wi-p-x-2">
        <driver-data :driver="driver"></driver-data>
      </div>
      <div v-if="currentTab === 'rides'">
        <ride-list :driver="driver"></ride-list>
      </div>
      <div v-if="currentTab === 'vehicle'">
        <vehicle-list :driver="driver"></vehicle-list>
      </div>
      <div v-if="currentTab === 'area'">
        <driver-area :driver="driver"></driver-area>
      </div>
    </div>
  </app-layout>
</template>

<style lang="scss" scoped>
.vue-map-container {
  width: 80%;
}

.vip-button {
  padding: .5rem 1rem;
  background-color: black;
  color: white;

}

.text-body-modal {
  text-align: start;
}

.fa-download {
  color: white;
  width: 20px;
}

.column {
  width: 10%;
}

.fa-star {
  color: yellow;
  font-size: 1.5rem
}

.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 25px;
  margin: .1rem 2rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked+.slider {
  background-color: rgb(255, 255, 0);
  ;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
  border: solid 2px black;

}

.slider.round:before {
  border-radius: 50%;
  background-color: black;
}</style>
