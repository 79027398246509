<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { Driver } from '../store/enums';
import { useStore } from 'vuex';
import enumsConfig from '@/core/store/config/enums';
import UserClient from "@/core/services/user-client";
import alertVuexDefinitions, { Alert, AlertType } from "@/core/store/alert/enums";

const store = useStore();
const drivers = ref<Driver[]>();
const config = ref();
const role = ref("DRIVER");
const search = ref("");
const order = ref("asc");
const page = ref(1);
const paginate = ref();
//const showModalAddMember = ref(false);

onMounted(() => {
  getAllDriver(role.value, search.value, order.value, page.value);
  loadConfig();
});

const loadConfig = async () => {
  await store.dispatch(enumsConfig.enums.ACTIONS.SetConfig);
  config.value = store.getters[enumsConfig.enums.GETTERS.GetConfig];
};

const getAllDriver = async (role: string, search: string, order: string, page: number) => {
  try {
    const driversList = await UserClient.getAllUsers(role, search, order, page);
    if (driversList.data.success) {
      drivers.value = driversList.data.data as Driver[];
      paginate.value = driversList.data.paginate;
    } else {
      drivers.value = [];
    }
  } catch (error) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: "Errore",
      description: error,
      type: AlertType.Error,
    } as Alert);
  }
};

/*
TODO: implementare la funzione per l'ordinamento
const updateOrderValue = async (value: any) => {
  order.value = value?.target?.value;
  if(order.value === 'asc' || order.value === 'dsc') {
    getAllDriver(role.value, search.value, order.value, page.value);
  }
}; */

const updateSearchValue = async (value: any) => {
  search.value = value?.target?.value ?? "";
  getAllDriver(role.value, search.value, order.value, page.value);
};

const updatePage = async (value: any) => {
  page.value = page.value + value;
  getAllDriver(role.value, search.value, order.value, page.value);
};

</script>

<template>
  <app-layout>
    <div class="wi-card wi-w-100">
      <table-page
        :has-add="false"
        add-label="Drivers"
        :label="config?.modules?.driver?.label"
        :orderBy="config?.modules?.driver?.order"
        @update:search="updateSearchValue"
      />
      <table-content
        :columns="config?.modules?.driver?.columns"
        :paginate="paginate"
        :rows-count="drivers?.length"
        :loading="!drivers"
        @next-page="updatePage(1)"
        @prev-page="updatePage(-1)"
      >
        <tr class="mc-cursor-pointer" v-for="item in drivers" :key="item.name" @click="$router.push(`drivers/${item._id}`)">
          <td class="content">{{ item.name ? item.name : ' - ' }}</td>
          <td class="content">{{ item.surname ? item.surname : ' - ' }}</td>
          <td class="content">{{ item.email ? item.email : ' - ' }}</td>
          <td class="content">{{ item.phone ? item.phone : ' - ' }}</td>
          <td class="content">{{ item?.payout_active ? item?.payout_active : ' - ' }}</td>
          <td class="content">{{ item.payout_created ? item.payout_created : ' - ' }}</td>
        </tr>
      </table-content>
    </div>
  </app-layout>
</template>

<style lang="scss" scoped>
// TODO stile generico da riportare sul componente TableContent

table tr {
  cursor: pointer;
}

//
.color-vip{
  color: rgb(255, 255, 0);
  margin-top: .3rem;
  font-size: 1.3rem;
  margin-left: 1rem;
}

.d-inline{
  display: inline;
}
.d-none{
  display: none;
}

</style>
