import axios from 'axios';
import { CreateAuthUser, UpdateAuthUser } from '../store/store';

const authHeaders = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
};

const checkUsertoken = async () => {
  return axios.request({
    url: `${process.env.VUE_APP_API}/auth/me`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
  }).catch((e) => {
    return e.toJSON();
  });
};

const sendResetPasswordCode = async (username: string) => {
  return axios.request({
    url: `${process.env.VUE_APP_API}/auth/send-email-reset-password`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
    data: {
      username
    }
  }).catch((e) => {
    return e.toJSON();
  });
};

const sendVerificationCode = async (phoneNumber: string) => {
  return axios.request({
    url: `${process.env.VUE_APP_API}/auth/send-verification-code`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
    data: {
      phoneNumber
    }
  }).catch((e) => {
    return e.toJSON();
  });
};

const confirmVerificationCode = async (phoneNumber: string, code: string) => {
  return axios.request({
    url: `${process.env.VUE_APP_API}/auth/verify-code`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
    data: {
      phoneNumber,
      code,
    }
  }).catch((e) => {
    return e.toJSON();
  });
};

const loginWithPassword = async (username: string, password: string) => {
  return axios.request({
    url: `${process.env.VUE_APP_API}/auth/signin`,
    method: 'post',
    data: {
      username,
      password,
    }
  }).catch((e) => {
    return e.toJSON();
  });
};

const confirmVerificationPasswordCode = async (usernaname: string, code: string, password: string, passwordConfirm: string) => {
  return axios.request({
    url: `${process.env.VUE_APP_API}/auth/verify-reset-password`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
    data: {
      usernaname,
      password,
      passwordConfirm,
      code,
    }
  }).catch((e) => {
    return e.toJSON();
  });
};

const createAuthUser = async (data: CreateAuthUser) => {
  return axios({
    url: `${process.env.VUE_APP_API}/users`,
    method: 'post',
    headers:  {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
    data,
  });
};

const UpdateUser = async (data: UpdateAuthUser) => {
  return axios({
    url: `${process.env.VUE_APP_API}/users`,
    method: 'put',
    headers:  {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
    data,
  });
};

const logout = async () => {
  return axios({
    url: `${process.env.VUE_APP_API}/auth/revoke-token`,
    method: 'get',
    headers:  {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
  });
};

const AuthClient = {
  checkUsertoken,
  sendVerificationCode,
  createAuthUser,
  sendResetPasswordCode,
  confirmVerificationCode,
  confirmVerificationPasswordCode,
  loginWithPassword,
  UpdateUser,
  logout
};
  // Disallow new properties on our object
Object.freeze(AuthClient);
export default AuthClient;
