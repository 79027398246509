<script lang="ts" setup>
import { reactive, ref, defineEmits, watch, computed } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, requiredIf, maxValue } from '@vuelidate/validators';
import AppAutoComplete from '@/components/AppAutoComplete.vue';
import authEnums, { AuthUser } from '@/modules/auth/store/enums';
import MemberClient from '@/modules/members/services/member-client';
import { OrderBy } from '@/core/interfaces';
import alertVuexDefinitions, { Alert, AlertType } from "@/core/store/alert/enums";
import { Address, DataObjectWithOutResponseCreated, PayloadCreateRide } from '../store/enums';
import RidesClient from '../rides-services/ride-client';
import { useStore } from "vuex";
import moment from 'moment';
const emits = defineEmits(["update-form-value", "update-form-valid"]);

const store = useStore();
const formData = reactive({
  guest: '',
  dropoff_address: '',
  pickup_address: '',
  start_time: moment().format('HH:mm'),
  start_time_date: moment().format('YYYY-MM-DD'),
  request_type: '',
  request_seats: '',
});

const guests = ref<AuthUser[]>();
const dropoff_address = ref<Address[]>();
const pickup_address = ref<Address[]>();
const rideTypeList = ref<DataObjectWithOutResponseCreated>();
const dataPostRide = ref<PayloadCreateRide>({
  guest: '',
  start_time: null,
  dropoff_address: null,
  has_vip_price: true,
  only_info: true,
  pickup_address: null,
  request_type: '',
  request_seats: 0,
  company: '',
  notes: '',
});
const authUser = computed(() => store.getters[authEnums.enums.GETTERS.GetAuthUser]);
console.log(authUser.value);
const nowBefore = ref('now');

const rules = {
  guest: { required },
  dropoff_address: { required },
  pickup_address: { required },
  start_time: { required },
  start_time_date: { required },
  request_seats: { required, maxValue: maxValue(6) },
  request_type: {
    required: requiredIf(() => {
      return (
        formData.guest !== '' &&
        formData.dropoff_address !== '' &&
        formData.pickup_address !== '' &&
        formData.start_time !== '' &&
        formData.start_time_date !== ''
      );
    }),
  },
};

const v$ = useVuelidate(rules, formData);

watch(() => formData.request_type, (newValue) => {
  dataPostRide.value.request_type = newValue;
  updateFormValue();
}, { deep: true });

watch(() => formData.request_seats, (newValue) => {
  dataPostRide.value.request_seats = Number(newValue);
  updateFormValue();
}, { deep: true });

const updateMemberSearch = async (search: string) => {
  getPriceRideList(true);
  formData.guest = search;
  const response = await MemberClient.getAllMember(search, OrderBy.DESC, 1);
  guests.value = response.data.data as AuthUser[];
};

const selectMember = (newValue: AuthUser) => {
  formData.guest = newValue.name + ' ' + newValue.surname;
  dataPostRide.value.guest = newValue._id;
  guests.value = [];
  v$.value.guest.$touch();
  getPriceRideList(true);
  updateFormValue();
};

const selectStartTime = () => {
  v$.value.start_time.$touch();
  getPriceRideList(true);
  updateFormValue();
};

const selectStartTimeDate = () => {
  v$.value.start_time_date.$touch();
  getPriceRideList(true);
  updateFormValue();
};

const updatePickUpAddress = async (search: string) => {
  try {
    formData.pickup_address = search;
    if (search.length < 3) return;
    const response = await RidesClient.findAddress(formData.pickup_address);
    pickup_address.value = response.data.data;
    v$.value.pickup_address.$touch();
  } catch (error: any) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: "Errore",
      description: error.data.message,
      type: AlertType.Error,
    } as Alert);
  }
};

const updateDropOffAddress = async (search: string) => {
  try {
    formData.dropoff_address = search;
    if (search.length < 3) return;
    const response = await RidesClient.findAddress(formData.dropoff_address);
    dropoff_address.value = response.data.data;
    v$.value.dropoff_address.$touch();
  } catch (error: any) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: "Errore",
      description: error.data.message,
      type: AlertType.Error,
    } as Alert);
  }
};

const selectPickUpAddress = (newValue: Address) => {
  formData.pickup_address = newValue.formatted_address;
  dataPostRide.value.pickup_address = {
    address: newValue.formatted_address,
    position: {
      lat: newValue.geometry.location?.lat,
      lng: newValue.geometry.location?.lng
    }
  };
  pickup_address.value = [];
  v$.value.pickup_address.$touch();
  getPriceRideList(true);
  updateFormValue();
};

const selectDropOffAddress = (newValue: Address) => {
  formData.dropoff_address = newValue.formatted_address;
  dataPostRide.value.dropoff_address = {
    address: newValue.formatted_address,
    position: {
      lat: newValue.geometry.location?.lat,
      lng: newValue.geometry.location?.lng
    }
  };
  dropoff_address.value = [];
  console.log(dataPostRide.value.dropoff_address);
  v$.value.dropoff_address.$touch();
  getPriceRideList(true);
  updateFormValue();
};

const getPriceRideList = async (only_info: boolean) => {
  try {
    if (v$.value.guest.$invalid || v$.value.pickup_address.$invalid || v$.value.dropoff_address.$invalid || v$.value.start_time.$invalid) return;
    dataPostRide.value.start_time = new Date(formData.start_time + ' ' + formData.start_time_date).toISOString();
    const response = await RidesClient.create({
      guest: dataPostRide.value.guest,
      start_time: dataPostRide.value.start_time,
      dropoff_address: dataPostRide.value.dropoff_address,
      has_vip_price: true,
      only_info,
      pickup_address: dataPostRide.value.pickup_address,
      company: dataPostRide.value.company,
      notes: dataPostRide.value.notes
    });
    if (response.data.message === 0) {
      response.data.message.forEach((error: any) => {
        store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
          title: "Errore",
          description: error,
          type: AlertType.Error,
        } as Alert);
      });
    }
    if (response.data.success) {
      rideTypeList.value = response.data.data;
    }
    updateFormValue();
  } catch (error: any) {
    console.log(error);
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: "Errore",
      description: error,
      type: AlertType.Error,
    } as Alert);
  }
};

const updateFormValue = () => {
  emits("update-form-value", {
    ...dataPostRide.value,
    company: authUser.value.user?.company?._id,
    only_info: false,
  });
  emits("update-form-valid", v$.value.$invalid);
};
</script>
<template>
  <div class="wi-ride-form">
    <div class="wi-m-b-2 wi-flex wi-flex-column wi-align-s">
      <label for="name" class="wi-text-left wi-text-xs wi-text-bold wi-w-100 mc-w-l-100">
        Special Guest:
      </label>
      <app-auto-complete :search="v$.guest.$model" :has-open="!!guests" @update:search="updateMemberSearch($event)"
        @reset:items="guests = undefined" placeholder="Scegli un membro della tua company" class="wi-m-t-1 wi-w-100">
        <div class="wi-flex wi-flex-column" v-if="guests && guests?.length > 0">
          <div class="items" v-for="item in guests" :key="item._id">
            <div class="wi-ride-form-guest" @click="selectMember(item)">
              <wi-icon-svg icon="member" class="wi-icon wi-m-e-1" width="16" height="16"></wi-icon-svg>
              <div class="wi-flex wi-flex-column">
                <a class="wi-text-left wi-color-lc">{{ item.name }} {{ item.surname }} </a>
                <a class="wi-text-left wi-color-lc">{{ item.email }}</a>
              </div>
            </div>
          </div>
        </div>
        <div class="items" v-else>
          <div class="wi-ride-form-guest">
            <div class="wi-flex wi-flex-column">
              <p>Nessun membro trovato</p>
            </div>
          </div>
        </div>
      </app-auto-complete>
      <div class="input-errors wi-m-y-1" v-for="error of v$.guest.$errors" :key="error.$uid">
        <div class="wi-text-xxs">{{ error.$message }}</div>
      </div>
    </div>
    <div class="wi-flex wi-flex-row wi-justify-between wi-m-b-1">
      <label for="pickup_address" class="wi-text-left wi-text-xs wi-text-bold wi-w-44 mc-w-l-100">
        <div class="wi-flex">
          <wi-icon-svg icon="pickup" class="wi-icon wi-m-e-1" width="16" height="16"></wi-icon-svg>
          <p>Da:</p>
        </div>
        <app-auto-complete :search="v$.pickup_address.$model" :has-open="!!pickup_address"
          @update:search="updatePickUpAddress($event)" @reset:items="pickup_address = undefined"
          placeholder="Luogo di partenza" class="wi-m-t-1">
          <div class="wi-flex wi-flex-column" v-if="pickup_address && pickup_address.length > 0">
            <div class="items" v-for="(item, index) in pickup_address" :key="index">
              <div class="wi-ride-form-guest" @click="selectPickUpAddress(item as Address)">
                <wi-icon-svg icon="pickup" class="wi-icon wi-m-e-1" width="16" height="16"></wi-icon-svg>
                <div class="wi-flex wi-flex-column">
                  <a class="wi-text-left wi-color-lc">{{ item.formatted_address }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="items" v-else>
            <div class="wi-ride-form-guest">
              <div class="wi-flex wi-flex-column">
                <p>Nessun indirizzo trovato</p>
              </div>
            </div>
          </div>
        </app-auto-complete>
        <div class="input-errors wi-m-y-1" v-for="error of v$.pickup_address.$errors" :key="error.$uid">
          <div class="wi-text-xxs">{{ error.$message }}</div>
        </div>
      </label>
      <label for="dropoff_address" class="wi-text-left wi-text-xs wi-text-bold wi-w-44 mc-w-l-100">
        <div class="wi-flex">
          <wi-icon-svg icon="droppoff" class="wi-icon wi-m-e-1" width="16" height="16"></wi-icon-svg>
          <p>A:</p>
        </div>
        <app-auto-complete :search="v$.dropoff_address.$model" :has-open="!!dropoff_address"
          @update:search="updateDropOffAddress($event)" @reset:items="dropoff_address = undefined"
          placeholder="Luogo di arrivo" class="wi-m-t-1">
          <div class="wi-flex wi-flex-column" v-if="dropoff_address && dropoff_address.length > 0">
            <div class="items" v-for="(item, index) in dropoff_address" :key="index">
              <div class="wi-ride-form-guest" @click="selectDropOffAddress(item as Address)">
                <wi-icon-svg icon="droppoff" class="wi-icon wi-m-e-1" width="16" height="16"></wi-icon-svg>
                <div class="wi-flex wi-flex-column">
                  <a class="wi-text-left wi-color-lc">{{ item.formatted_address }}</a>
                </div>
              </div>
            </div>
          </div>
          <div class="items" v-else>
            <div class="wi-ride-form-guest">
              <div class="wi-flex wi-flex-column">
                <p>Nessun indirizzo trovato</p>
              </div>
            </div>
          </div>
        </app-auto-complete>
        <div class="input-errors wi-m-y-1" v-for="error of v$.dropoff_address.$errors" :key="error.$uid">
          <div class="wi-text-xxs">{{ error.$message }}</div>
        </div>
      </label>
    </div>
    <div class="wi-flex wi-flex-column wi-align-start wi-m-t-1">
      <fieldset>
        <legend class="wi-m-b-1 wi-text-left wi-text-xs wi-text-bold ">Vieni a prendermi:</legend>
        <div class="wi-flex wi-flex-row wi-align-center">
          <div @click="nowBefore = 'now'" :class="{ 'selected': nowBefore === 'now' }"
            class="option-radio cursor-pointer"></div>
          <label @click="nowBefore = 'now'" for="now" class="wi-m-e-2 wi-text-xs cursor-pointer">Adesso</label>

          <div @click="nowBefore = 'before'" :class="{ 'selected': nowBefore === 'before' }"
            class="option-radio cursor-pointer"></div>
          <label @click="nowBefore = 'before'" for="before" class="wi-text-xs cursor-pointer">Dopo</label>
        </div>
      </fieldset>
    </div>
    <div class="wi-flex wi-flex-row wi-justify-between wi-m-t-2" v-if="nowBefore === 'before'">
      <label for="pickup_address" class="wi-text-left wi-text-xs wi-text-bold wi-w-44 mc-w-l-100">
        <div class="wi-flex wi-m-b-1">
          <p>Data di ritiro:</p>
        </div>
        <input class="wi-input wi-w-100" @change="selectStartTimeDate" :min="moment().format('')" type="date"
          v-model="v$.start_time_date.$model" name="" id="">
        <div class="input-errors wi-m-y-1" v-for="error of v$.start_time_date.$errors" :key="error.$uid">
          <div class="wi-text-xxs">{{ error.$message }}</div>
        </div>
      </label>
      <label for="dropoff_address" class="wi-text-left wi-text-xs wi-text-bold wi-w-44 mc-w-l-100">
        <div class="wi-flex wi-m-b-1">
          <p>Ora di ritiro:</p>
        </div>
        <input class="wi-input wi-w-100" @change="selectStartTime" :min="moment().format('HH:mm')" type="time"
          v-model="v$.start_time.$model" name="" id="">
        <div class="input-errors wi-m-y-1" v-for="error of v$.start_time.$errors" :key="error.$uid">
          <div class="wi-text-xxs">{{ error.$message }}</div>
        </div>
      </label>
    </div>
    <div class="wi-flex wi-flex-column wi-justify-between wi-m-t-2">
      <label for="request_seats" class="wi-text-left wi-text-xs wi-text-bold wi-w-100 mc-w-l-100">
        <div class="wi-flex wi-m-b-1">
          <p>Posti Richiesti:</p>
        </div>
        <input class="wi-input wi-w-100" type="number" v-model="v$.request_seats.$model"
          placeholder="Inserisci il numero di partecipanti, max:6" @change="updateFormValue">
        <div class="input-errors wi-m-y-1" v-for="error of v$.request_seats.$errors" :key="error.$uid">
          <div class="wi-text-xxs">{{ error.$message }}</div>
        </div>
      </label>
      <label for="notes" class="wi-text-left wi-text-xs wi-text-bold wi-w-100 wi-m-t-2 mc-w-l-100">
        <div class="wi-flex wi-m-b-1">
          <p>Note:</p>
        </div>
        <textarea class="wi-input wi-font-lato wi-w-100" cols="30" rows="3" v-model="dataPostRide.notes"
          placeholder="Scrivi una nota per il conducente" @change="updateFormValue"></textarea>
      </label>
    </div>
    <div class="wi-flex wi-flex-column wi-justify-start wi-m-y-2">
      <label for="pickup_address" class="wi-text-left wi-text-xs wi-text-bold wi-w-100 wi-m-b-1">
        Scegli la tua corsa
      </label>
      <div class="wi-alert animate bounce_css wi-alert-error" v-if="!rideTypeList">
        <div class="wi-flex wi-flex-row wi-justify-between">
          <div class="wi-flex wi-flex-column wi-justify-center">
            <svg class="wi-icon wi-icon-pointer wi-m-e-1" width="25" height="25" fill="#FA0034" viewBox="0 0 24 24">
              <path
                d="M16 19c-0.802 0-1.555-0.312-2.122-0.879l-1.878-1.879-1.879 1.879c-1.133 1.133-3.109 1.133-4.243 0-0.566-0.566-0.878-1.32-0.878-2.121s0.312-1.555 0.879-2.122l1.878-1.878-1.878-1.879c-0.567-0.566-0.879-1.32-0.879-2.121s0.312-1.555 0.879-2.122c1.133-1.132 3.109-1.133 4.243 0.001l1.878 1.879 1.879-1.879c1.133-1.133 3.109-1.133 4.243 0 0.566 0.566 0.878 1.32 0.878 2.121s-0.312 1.555-0.879 2.122l-1.878 1.878 1.878 1.879c0.567 0.566 0.879 1.32 0.879 2.121s-0.312 1.555-0.879 2.122c-0.566 0.566-1.319 0.878-2.121 0.878zM12 13.414l3.293 3.293c0.378 0.378 1.037 0.377 1.414 0 0.189-0.189 0.293-0.439 0.293-0.707s-0.104-0.518-0.293-0.707l-3.292-3.293 3.292-3.293c0.189-0.189 0.293-0.44 0.293-0.707s-0.104-0.518-0.293-0.707c-0.378-0.379-1.037-0.378-1.414-0.001l-3.293 3.294-3.293-3.293c-0.378-0.378-1.037-0.377-1.414 0-0.189 0.189-0.293 0.44-0.293 0.707s0.104 0.518 0.293 0.707l3.292 3.293-3.292 3.293c-0.189 0.189-0.293 0.439-0.293 0.707s0.104 0.518 0.293 0.707c0.378 0.379 1.037 0.378 1.414 0.001l3.293-3.294z">
              </path>
            </svg>
          </div>
          <div class="wi-flex wi-flex-column wi-justify-between" v-if="v$.$invalid">
            <h1 class="wi-m-b-1 wi-text-s wi-text-s wi-text-bold">Dati mancanti</h1>
            <p class="wi-text-xs">Per prenotare la corsa devi prima selezionare un membro, il punto di ritiro e il punto
              di partenza ed un eventuale orario</p>
          </div>
          <div class="wi-flex wi-flex-column wi-justify-between" v-if="!rideTypeList && !v$.$invalid">
            <h1 class="wi-m-b-1 wi-text-s wi-text-s wi-text-bold">Dati non corretti</h1>
            <p class="wi-text-xs">Forse i dati non sono corretti, Seleziona i valori presenti nelle diverse select.</p>
          </div>
        </div>
      </div>
      <div class="wi-m-y-2" v-else>
        <div class="wi-flex wi-flex-row wi-justify-between wi-m-b-1 wi-ride-type-item"
          v-for="(item, index) in rideTypeList?.amountList" :key="index"
          :class="{ 'selected': v$.request_type.$model === item.vehicle_type }"
          @click="v$.request_type.$model = item.vehicle_type">
          <div class="wi-flex wi-flex-row wi-justify-start">
            <wi-icon :icon="item.vehicle_type.toLowerCase()" height="31px" width="93px"></wi-icon>
            <div class="wi-flex wi-flex-column wi-justify-start wi-m-s-2 wi-align-s">
              <label for="van" class="wi-text-xs wi-text-bold wi-m-b-1">{{ item.vehicle_type }}</label>
              <label for="van" class="wi-text-xs">Prezzi bassi e ritiri veloci</label>
            </div>
          </div>
          <div class="wi-flex wi-flex-column wi-justify-start">
            <label for="price" class="wi-text-xs wi-text-bold">{{ item.price }} €</label>
          </div>
        </div>
      </div>
      <div class="input-errors wi-m-y-1" v-for="error of v$.request_type.$errors" :key="error.$uid">
        <div class="wi-text-xxs">{{ error.$message }}</div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.wi-ride {
  &-form {
    display: flex;
    flex-direction: column;

    fieldset {
      width: max-content;
    }

    &-guest {
      display: flex;
      flex-direction: row;
      box-shadow: inset 3px 0 0 #acafb2;
      cursor: pointer;
      padding: 1rem;
      font-size: 13px;

      &:hover {
        background-color: #efefef;
      }
    }
  }

  &-type {
    &-item {
      background-color: #ffffff;
      cursor: pointer;
      padding: 1rem;

      &-price {
        font-weight: bold;
        font-size: 20px;
      }

      &:hover {
        background-color: #f7f7f7;
        box-shadow: 0 0 5px #00000029;
        transition: all 0.3s ease-in-out;
      }

      &.selected {
        background-color: #f7f7f7;
        box-shadow: 0 0 5px #00000029;
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

button:active {
  background-color: F7E462;
  color: black;
  outline: 1px solid F7E462;
}

.option-radio {
  height: 15px;
  width: 15px;
  border-radius: 100%;
  margin-right: 10px;
  border: 1px solid #E9E9E9;
  background-color: #f2f2f2;
  box-shadow: inset 0 0 0 2px white;
  transition: background-color 0.3s;

  &.selected {
    background-color: yellow;
  }
}</style>