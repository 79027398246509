import AuthClient from '@/modules/auth/services/auth-client';
import router from '@/router';

export default async function guestGuard() {
  const result: any = await AuthClient.checkUsertoken();
  const value = sessionStorage.getItem('token');
  if (value || result?.success) {
    router.push('/');
  }
}
