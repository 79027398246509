const firebaseConfig = {
  apiKey: 'AIzaSyB3UDEm4m96k_pkxEvehCHvEl366Tc8SFk',
  authDomain: 'wiicabs-test.firebaseapp.com',
  projectId: 'wiicabs-test',
  storageBucket: 'wiicabs-test.appspot.com',
  messagingSenderId: '886421698743',
  appId: '1:886421698743:web:0d882a0aafb158d55d3532',
  measurementId: 'G-BDKR38X0K0',
};

// Initialize Firebase
export default firebaseConfig;
