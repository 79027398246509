<script lang="ts" setup>
import { onMounted, ref, computed } from 'vue';
import TourClient from './tours-services/tour-client';
import moment from 'moment';
import alertVuexDefinitions, { Alert, AlertType } from '@/core/store/alert/enums';
import { useStore } from 'vuex';
import enums from "@/core/store/config/enums";
import { useRouter } from 'vue-router';
import { Tour } from './store/enums';
import TourForm from './components/TourForm.vue';
const router = useRouter();
const store = useStore();
const config = computed(() => store.getters[enums.enums.GETTERS.GetConfig]);
const items = ref<Tour[]>();
const search = ref('');
const page = ref(1);
const order = ref('asc');
const status = ref();
const rideValues = ref();
const loading = ref(false);
const paginate = ref();
const showModalEnabled = ref();
const showModalAdd = ref();

onMounted(async () => {
  getAllTour(status.value, search.value, order.value, page.value);
});

const getAllTour = async (status: string, search: string, order: string, page: number) => {
  try {
    loading.value = true;
    let ridesList;
    if(!status) {
      ridesList = await TourClient.getAllTours();
    } else {
      ridesList = await TourClient.getAllTours({status, search, order, page});
    }
    if(ridesList.data.success) {
      items.value = ridesList.data.data as Tour[];
      paginate.value = ridesList.data.paginate;
    } else {
      items.value = [];
    }
  } catch(error: any) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: 'Errore',
      description: error,
      type: AlertType.Error,
    } as Alert);
  } finally {
    loading.value = false;
  }
}

const deleteTour = async () => {
  try {
    const ridesList = await TourClient.cancelTour(rideValues.value?._id ?? "");
    if (ridesList.data.success) {
      store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
        title: "Eliminato",
        description: "Corsa annullata con successo",
        type: AlertType.Success,
      } as Alert);
      getAllTour(search.value, status.value, order.value, page.value);
    }
  } catch (error: any) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: "Errore",
      description: error.data.message,
      type: AlertType.Error,
    } as Alert);
  } finally {
    showModalEnabled.value = false;
    rideValues.value = null;
  }
};

const updateOrderValue = async (value: any) => {
  order.value = value?.target?.value ?? '';
  getAllTour(status.value, search.value, order.value, page.value);
}

const updateSearchValue = async (value: any) => {
  search.value = value?.target?.value ?? '';
  getAllTour(status.value, search.value, order.value, page.value);
}
const updatePage = async (value: any) => {
  page.value = page.value + value;
  getAllTour(status.value, search.value, order.value, page.value);
}
const openDeleteRide = async (ride: Tour) => {
  showModalEnabled.value = true;
  rideValues.value = ride;
};

const setPostTour = () => {
  console.log('set post tour');
}
</script>

<template>
  <app-layout>
    <div class="wi-card wi-w-100">
      <table-page
        :has-add="true"
        :filter-value="status"
        :order="order"
        :order-value="order"
        :label="config.modules.tour.label"
        :orderBy="config.modules.tour.order"
        :filter-by="config.modules.tour.filter"
        @update:orderValue="updateOrderValue($event)"
        @update:filterValue="getAllTour($event.target?.value, search, order, page)"
        @update:search="updateSearchValue"
        @add-entities="showModalAdd = !showModalAdd"
      />
      <table-content :loading="loading" :columns="config.modules.tour.columns" :paginate="paginate" :rows-count="items?.length" @next-page="updatePage(1)" @prev-page="updatePage(-1)">
        <tr
          class="mc-cursor-pointer"
          v-for="item in items"
          :key="item._id"
        >
          <td class="content">{{ moment(item.start_time).format('DD-MM-YYYY HH:mm') }}</td>
          <td class="content">€{{item.amount}}</td>
          <td class="content">{{ item.customer?.name }} {{item.customer?.surname }}</td>
          <td class="content">{{ item.driver?.name }} {{item.driver?.surname }}</td>
          <td class="content wi-flex wi-flex-row wi-justify-center wi-m-w-none wi-w-auto">
            <wi-icon-svg icon="view" width="24" height="24" class="wi-icon wi-icon-pointer wi-m-e-1" @click="router.push('/rides/' + item._id)"></wi-icon-svg>
            <wi-icon-svg v-if="item.status !== 'COMPLETED' && item.status !== 'CANCELLED'" icon="delete" width="24" height="24"  class="wi-icon wi-icon-pointer wi-m-e-1" @click="openDeleteRide(item)"></wi-icon-svg>
          </td>
        </tr>
      </table-content>
      <wi-modal
        v-if="showModalEnabled == true"
        @close="showModalEnabled = false"
        textClose="Annulla"
        textButton="Procedi"
        title="Cancella la corsa"
        @confirm-entities="deleteTour"
      >
        <p class="wi-text-left wi-m-b-4">Sei Sicuro di voler cancella questa corsa?</p>
      </wi-modal>
      <wi-modal
        v-if="showModalAdd"
        @close="showModalAdd = false"
        textButton="Aggiungi"
        textClose="Annulla"
        title="Aggiungi un nuovo tour"
        @confirm-entities="setPostTour"

      >
        <tour-form ref="submitForm"></tour-form>
      </wi-modal>
    </div>
  </app-layout>
</template>

<style lang="scss" scoped>
</style>
