import { InjectionKey } from 'vue';
import { Store } from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import { ReferentState } from '@/modules/referents/store/enums';
import { OrderBy } from '@/core/interfaces';

export const referentState : ReferentState = {
  referentList: [],
  filters:{
    search: '',
    orderBy: OrderBy.ASC,
  },
  paginate: null
};

export const key: InjectionKey<Store<ReferentState>> = Symbol('Referent Store');

export default ({
  state: (): ReferentState => (referentState),
  getters: {
    ...getters,
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
});
