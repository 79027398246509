import actions from '@/modules/auth/store/actions';
import getters from '@/modules/auth/store/getters';
import mutations from '@/modules/auth/store/mutations';
import { InjectionKey } from 'vue';
import { Store } from 'vuex';
import { AuthState } from '@/modules/auth/store/enums';

export const authState = {
  email: null,
  firebaseUid: null,
  authUser: null,
  errorProfile: null,
};

export interface CreateAuthUser {
  name: string
  surname: string
  email: string
  role: string
  password: string,
  passwordConfirm: string,
  terms: boolean
}

export interface UpdateAuthUser {
  name: string
  surname: string
}

export const key: InjectionKey<Store<AuthState>> = Symbol('Auth Store');

export default ({
  state: (): AuthState => (authState),
  getters: {
    ...getters,
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
});
