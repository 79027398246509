<script lang="ts" setup>
import { ref, defineProps, onMounted } from 'vue';
import { Loader } from "@googlemaps/js-api-loader";
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import enviroment from '@/enviroment';
import UserClient from '@/core/services/user-client';
import { Driver } from '../store/enums';
import alertVuexDefinitions, { Alert, AlertType } from '@/core/store/alert/enums';

const store = useStore();
const route = useRoute();
const coordinates = ref();
const selectRegion = ref<HTMLElement | null>();
// eslint-disable-next-line no-undef
const selectRegionMap = ref<google.maps.Map>();
// eslint-disable-next-line no-undef
const area = ref<google.maps.Circle>();

const driver = ref<any>();

const getDriver = async (id: string) => {
    try {
        const driverSelected = await UserClient.getSingleUser(id);
        if (driverSelected.data.success) {
            driver.value = driverSelected.data.data as Driver;
        } else {
            console.log('driver data not found')
            driver.value = undefined;
        }
    } catch (error) {
        store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
            title: "Errore",
            description: error,
            type: AlertType.Error,
        } as Alert);
    }
};

const createMapIstance = async () => {
  const loader = new Loader({
    apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY ?? '', // Sostituisci con la tua chiave API di Google Maps
    version: 'weekly',
  });
  await loader.load();
  console.log(driver.value)
    // eslint-disable-next-line no-undef
  selectRegionMap.value = new google.maps.Map(
    document.getElementById('selectRegion'),
    {
      center: driver.value?.userDriver?.area?.position,
      zoom: 8,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      fullscreenControl: false,
    })
    const raggioCerchio = driver.value?.userDriver?.area?.diameter * 1000; // Ad esempio, 1000 metri (1 km)

    // Opzioni del cerchio
    const opzioniCerchio = {
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,     
      strokeWeight: 2,        
      fillColor: '#FF0000',   
      fillOpacity: 0.35,      
      map: selectRegionMap.value,             
      center: driver.value?.userDriver?.area?.position,  
      radius: raggioCerchio,  
    };
    // eslint-disable-next-line no-undef
    area.value = new google.maps.Circle(opzioniCerchio);

    area.value.setMap(selectRegionMap.value);
};

onMounted(async () => {
    await getDriver(route.params.id as string);
    if(driver.value.userDriver?.area?.position && driver.value.userDriver?.area?.diameter) {
      createMapIstance();
    } else {
      store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
        title: "Errore",
        description: 'Errore nella creazione della mappa, contattare l\'amministratore del sistema',
        type: AlertType.Error,
      } as Alert);
    }
});

</script>
<template>
    <div>
        <div id="selectRegion" class="mapSelectRegione wi-m-y-2" ref="selectRegion"></div>
    </div>
</template>

<style scoped lang="scss">
.mapSelectRegione {
  height: 47vh;
  width: 100%;
  position: relative;
}

</style>