<script lang="ts">
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { OrderOptios } from '@/core/interfaces';


export default defineComponent({
  emits: ['add-entities', 'update:searchValue','update:search','update:selectedValue', 'update:orderValue', 'update:reset', 'update:filterValue', 'update:rangeDate'],
  data() {
    return {
      searchValue: '',
      startDate: '',
      endDate: '',
    }
  },
  props: {
    label: {
      type: String,
      default: '',
    },
    hasAdd: {
      type: Boolean,
      default: true,
    },
    hasOrder: {
      type: Boolean,
      default: true,
    },
    hasSearch: {
      type: Boolean,
      default: true,
    },
    hasRangeDate: {
      type: Boolean,
      default: false,
    },
    addLabel: {
      type: String,
      default: 'Aggiungi',
    },
    orderValue: {
      type: String,
      default: '',
    },
    filterValue: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Cerca...',
    },
    orderBy: {
      type: Array as PropType<OrderOptios[]>,
      default: () => [{
        name: '',
        value: '',
      }],
    },
    filterBy: {
      type: Array as PropType<OrderOptios[]>,
      default: () => null,
    },
  },
  methods: {
    resetInput() {
      this.searchValue = '';
      this.$emit('update:search', '');
    }
  }
 
});
</script>

<template>
<div class="wi-flex wi-flex-row wi-justify-between wi-w-100 mc-flex-l-column mc-flex-l-wrap">
    <div class=
    "wi-flex wi-color-blackm wi-text-bold wi-text-l wi-align-center wi-p-s-2 wi-p-y-3 wi-m-b-4 mcl-m-b-1 mcl-py-1">
        {{label}}
    </div>
    <div class="wi-flex wi-justify-e wi-align-e wi-p-x-2 wi-p-y-3 mc-flex-l-column mc-flex-l-wrap fit-height-content mcl-py-1">
      <button
        v-show="hasAdd"
        @click="$emit('add-entities')"
        class="wi-button wi-text-bold wi-m-e-3 wi-button-add wi-p-x-2"
      >
        {{ addLabel }}
        <wi-icon icon="add"></wi-icon>
      </button>
       <label v-if="hasSearch" for="search" class="wi-flex wi-align-center wi-input wi-searchbar wi-m-e-2 mcl-m-e-0 mcl-m-b-1">
          <wi-icon  icon="referent" class="wi-m-x-1 wi-m-y-1" height="16px" width="16px"></wi-icon>
          <input type="search"
          class="wi-label"
          v-model="searchValue"
          @blur= "e => $emit('update:search', e)"
          :placeholder="placeholder"
          >
          <div @click="resetInput">
            <i class="fa-solid fa-x"></i>
          </div>
        </label>
        <div class="wi-flex wi-m-e-2 mcl-m-e-0 mcl-m-b-1" v-if="filterBy">
          <select class="wi-filter"  @change="e => $emit('update:filterValue', e)">
            <option v-for="(filter) in filterBy"
              :selected="filter.value === filterValue"
              :key="filter.value" :value="filter?.value ?? ''">
              {{ filter?.name ?? '' }} 
            </option>
          </select>
        </div>
        <label v-if="hasRangeDate" for="rangeDate" class="wi-flex wi-flex-column fit-height-content wi-justify-baseline wi-font-lato wi-text-xs wi-m-e-2 mcl-m-e-0 mcl-m-b-1">
          <span class="wi-text-bold wi-text-left">Data Inizio</span>
          <input
            type="date"
            class="wi-input"
            v-model="startDate"
            @blur= "e => $emit('update:rangeDate', {start: e, end: endDate})"
          >
        </label>
        <label v-if="hasRangeDate" for="endDate" class="wi-flex wi-flex-column fit-height-content wi-justify-baseline wi-font-lato wi-text-xs wi-m-e-2 mcl-m-e-0 mcl-m-b-1">
          <span class="wi-text-bold wi-text-left">Data Fine</span>
          <input
            type="date"
            class="wi-input"
            v-model="endDate"
            @blur= "e => $emit('update:rangeDate', {start: startDate, end: e})"
          >
        </label>
        <div class="wi-flex wi-align-e fit-height-content" v-if="hasOrder">
          <select class="wi-filter"  @change="e => $emit('update:orderValue', e)">
            <option v-for="(order) in orderBy"
              :selected="order.value === orderValue"
              :key="order.value" :value="order?.value ?? ''">
            {{ order?.name ?? '' }} 
            </option>
          </select>
        </div>
    </div>
</div>
</template>

<style lang="scss">
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}

.fit-height-content {
  height: fit-content;
}

.fa-x{
  font-size: .6rem;
}
</style>
