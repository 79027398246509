<script setup lang="ts">
import { computed, nextTick, onMounted, reactive, ref } from 'vue';
import PinCode from '@/components/PinCode.vue';
import { useStore } from 'vuex'
import authEnums from '@/modules/auth/store/enums';
import AuthClient from '../services/auth-client';
import { CreateAuthUser } from '@/modules/auth/store/store';
import alertVuexDefinitions, { Alert, AlertType } from '@/core/store/alert/enums';
import { useRouter } from "vue-router";
import { RootState } from "@/store";
import useVuelidate from '@vuelidate/core';
import { required, minLength, maxLength, numeric, sameAs } from '@vuelidate/validators';
import AppAuthLayout from '../components/AppAuthLayout.vue';

const router = useRouter();
const store = useStore<RootState>();
const email = computed(() => store.getters[authEnums.namespaced.GETTERS.GetEmail]);
// states
const loading = ref(false);
// states
const state = reactive({
  pincode: '',
  password: '',
  confirmPassword: '',
});

const rules = {
  pincode: {
    required,
    numeric,
    maxLength: maxLength(6),
    minLength: minLength(6),
  },
  password: {
    required,
    minLength: minLength(8),
    regex: ( value: string ) => /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(value),
  },
  confirmPassword: {
    required,
    sameAs: (value: string) => value === state.password || !state.password,
  },
};

const v$ = useVuelidate(rules, state);

// methods
const resendCode = async () => {
  try {
    const email = store.getters[authEnums.namespaced.GETTERS.GetEmail];
    loading.value = true;
    const response = await AuthClient.sendResetPasswordCode(email);
    // TODO: Implement sms verify code
    if(response.data.success) {
      store.dispatch(authEnums.namespaced.ACTIONS.SetEmail, email);
      loading.value = false;
      sendedSmsSuccess();
    } else {
      throw new Error(response.message);
    }
  } catch (e) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: 'Errore Reset Password',
      description: 'Errore, controlla il numero di telefono e riprova',
      type: AlertType.Error,
    } as Alert);
    loading.value = false;
  }
};

const sendPinCode = async () => {
  try {
    loading.value = true;
    const response = await AuthClient.confirmVerificationPasswordCode(
      email.value,
      state.pincode,
      state.password,
      state.confirmPassword,
    );
    // store.dispatch(authEnums.namespaced.ACTIONS.SetToken, user.accessToken);

    if(response?.data && response?.data.success) {
      const authUser: CreateAuthUser = response?.data.data.user;
      await store.dispatch(authEnums.namespaced.ACTIONS.SetProfile, { ...authUser });
      sessionStorage.setItem('token', response.data.data.jwt)
      nextTick();
      loading.value = false;
      router.push('/login');
    } else {
      throw  new Error(response.data.message);
    } 
    sendedSuccess()
  } catch(e) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: 'Errore Reset Password',
      description: 'The Email verification code used to create the phone auth credential is invalid',
      type: AlertType.Error,
    } as Alert);
    loading.value = false;
  }
}

const sendedSuccess = async () => {
  store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
    title: 'Errore Reset Password',
    description: 'Congratulazioni la tua password è stata modificata con successo.',
    type: AlertType.Success,
  } as Alert);
};

const sendedSmsSuccess = async () => {
  store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
    title: 'Password Reset Success',
    description: 'E\' stato inviato un codice di verifica tramite email.',
    type: AlertType.Success,
  } as Alert);
};
const maskedEmail = (email: string): string => {
  if (email) {
    const [name, domain] = email.split('@');
    const { length: len } = name;
    const maskedName = `${name[0]}***${name[len - 1]}`;
    const maskedEmail = `${maskedName}@${domain}`;
    return maskedEmail;
  }
  return '';
};
// mounted
onMounted(async () => {
  sendedSmsSuccess();
});
</script>


<template>
  <AppAuthLayout>
    <h1 class="wi-text-l wi-text-bold wi-text-left wi-p-b-3">Controlla la tua email</h1>
    <p class="wi-text-xs wi-text-left wi-p-b-1 wi-w-75 wi-color-dark-grey">
      Abbiamo inviato un’e-mail con le informazioni
      sulla reimpostazione della password a {{ maskedEmail(email) }}
    </p>
    <p class="wi-text-xs wi-text-left wi-p-b-1 wi-w-75 wi-color-dark-grey wi-m-b-1">
      Non hai ricevuto l’e-mail? Controlla la cartella spam
    </p>
    <pin-code v-model="v$.pincode.$model" @action:resend-code="resendCode" class="wi-w-75 wi-m-b-1"></pin-code>
    <div class="wi-w-75 wi-flex wi-flex-column">
      <label class="wi-m-b-1 wi-text-s wi-text-bold wi-text-left">Password</label>
      <input :class="{ 'wi-input-error wi-m-b-1': v$.password.$error, 'wi-m-b-2': !v$.password.$error }" class="wi-input" type="password" placeholder="Inserisci la tua password" v-model="v$.password.$model"/>
      <div class="input-errors wi-m-b-1" v-for="error of v$.password.$errors" :key="error.$uid">
        <div class="wi-text-xs">{{ error.$message }}</div>
      </div>
    </div>
    <div class="wi-w-75 wi-flex wi-flex-column">
      <label class="wi-m-b-1 wi-text-s wi-text-bold wi-text-left">Conferma Password</label>
      <input :class="{ 'wi-input-error wi-m-b-1': v$.confirmPassword.$error, 'wi-m-b-2': !v$.confirmPassword.$error }" class="wi-input" type="password" placeholder="Conferma la tua password" v-model="v$.confirmPassword.$model"/>
      <div class="input-errors wi-m-b-1" v-for="error of v$.confirmPassword.$errors" :key="error.$uid">
        <div class="wi-text-xs">{{ error.$message }}</div>
      </div>
    </div>
    <h1
      class="wi-p-t-1 wi-p-b-2 wi-color-grey wi-text-right wi-w-75 cursor-pointer"
      @click="$router.push('/login')"
      @keypress="$router.push('/login')"
    >
      Torna al login
    </h1>
    <button
      @click="sendPinCode()"
      class="wi-button wi-w-75 wi-text-bold"
    >
      Cambia Password
    </button>
  </AppAuthLayout>
</template>

<style lang="scss" scoped>
</style>
