<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CardAnalisys',
  props: {
    type: {
      type: String,
      default: 'first',
    },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    hasDashboardPage: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<template>
  <div class="mc-w-xxl-100 wi-p-y-2" :class="{ 'mc-min-w-xl-25' : hasDashboardPage }">
    <div class="wi-w-100 wi-card-dashboard" :class="'wi-card-dashboard-' + type">
      <wi-icon :icon="icon" width="38px" height="38px" class="wi-m-e-1"></wi-icon>
      <div class="wi-card-dashboard-inner">
        <h1 class="wi-text-l wi-text-bold">{{ title }}</h1>
        <h1 class="wi-text-xs wi-text-left">{{ description }}</h1>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wi-card-dashboard {
  color: white;
  &-inner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-left: 20px;
  }
  &-first {
    background:
      transparent linear-gradient(112deg, #DB6F51 0%, #FDAF5B 100%) 0% 0% no-repeat padding-box;
  }
  &-second {
    background:
      transparent linear-gradient(112deg, #27BFDB 0%, #09F078 100%) 0% 0% no-repeat padding-box;
  }
  &-third {
    background: #000000;
  }
  &-fourth {
    background:
      transparent linear-gradient(111deg, #002CBF 0%, #D788FE 100%) 0% 0% no-repeat padding-box;
  }
}
</style>
