import vuexDefinitions, { ReferentState, ReferentFilter } from '@/modules/referents/store/enums';
import { ActionContext } from 'vuex';
import store, { RootState } from '@/store';
import UserClient from '@/core/services/user-client';
import { AuthRole } from '@/modules/auth/store/enums';
import { OrderBy } from '@/core/interfaces';

type ReferentActionContext = ActionContext<ReferentState, RootState>

export default {
    [vuexDefinitions.enums.ACTIONS.FetchReferentList]: // per avere lo stesso nome del metodo in qualsiasi punto
        async (context: ReferentActionContext, payload: ReferentFilter, page: number): Promise<void> => {
            // il context in questo caso rappresenta il nostro modulo vuex
            context.commit(vuexDefinitions.enums.MUTATIONS.UpdateReferentFilter, payload);
            const referentList = await UserClient.getAllUsers(
              AuthRole.REFERENT,
              context.state.filters?.search,
              context.state.filters?.orderBy,
              page
            );
        context.commit(vuexDefinitions.enums.MUTATIONS.UpdateReferentList, referentList.data.data);
        context.commit(vuexDefinitions.enums.MUTATIONS.UpdatePaginate, referentList.data.paginate);
    },
};
