<script lang="ts" setup>
import { useRoute, useRouter } from 'vue-router';
import { Driver } from '../store/enums';
import { useStore } from 'vuex';
import UserClient from "@/core/services/user-client";
import { onMounted, ref } from 'vue';
import RidesClient from '@/modules/rides/rides-services/ride-client';
import alertVuexDefinitions, { Alert, AlertType } from '@/core/store/alert/enums';
import DriverClient from '../services/driver-client';
import moment from 'moment';

const store = useStore();
const isActive = ref();
const route = useRoute();

const showModal = ref(false);
const driver = ref<Driver>();
const kb = ref();
const idcard = ref();
const driving_license_doc = ref();
const baseIMGPath = process.env.VUE_APP_API_PHOTO ?? '';
// Load drive on mounted
onMounted(async () => {
    console.log('driver id', route.params.id)
    await getDriver(route.params.id as string);
    isActive.value = driver.value?.isEnabled;
    kb.value = baseIMGPath + '/' + (driver.value?.userDriver?.documents?.kb ?? driver.value?._id)
    idcard.value = baseIMGPath + '/' + (driver.value?.userDriver?.documents?.idcard ?? driver.value?._id)
    driving_license_doc.value = baseIMGPath + '/' + (driver.value?.userDriver?.documents?.driving_license_doc ?? driver.value?._id)
});

const getDriver = async (id: string) => {
    try {
        const driverSelected = await UserClient.getSingleUser(id);
        if (driverSelected.data.success) {
            console.log('driver data', driverSelected.data.data.userDriver?.documents);
            driver.value = driverSelected.data.data as Driver;
        } else {
            driver.value = undefined;
        }
    } catch (error) {
        store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
            title: "Errore",
            description: error,
            type: AlertType.Error,
        } as Alert);
    }
};

// Function for active user
const updateEnableDriver = async () => {
    await UserClient.setEnabledUser(route.params.id as string);
};

// Function driver vip
const confirmDriverVip = async () => {
    try {
        // TODO aggiungere funzione per attivare lo stato vip su db 
        const response = await DriverClient.setDriverVip(route.params.id as string);
        console.log('Confirm driver vip');
        driver.value = response.data.data as Driver;
        if(response.data.success) {
            store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
                title: "Successo",
                description: 'Driver vip aggiornato con successo',
                type: AlertType.Success,
            } as Alert);
        } else {
            throw new Error('Il Driver non è stato aggiornato perchè il suo profilo non è completo');
        }
        showModal.value = false;
    } catch(error) {
        store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
            title: "Errore",
            description: error,
            type: AlertType.Error,
        } as Alert);
    } finally {
        await getDriver(route.params.id as string);
    }
}
</script>
<template>
    <div>
        <div class="loader-container" v-if="!driver">
            <div class="circle-loader"></div>
        </div>
        <div v-else>
            <div class="wi-m-b-5 wi-flex wi-justify-between">
                <div class="wi-flex wi-justify-between wi-align-items-center contain">
                    <h1 class="wi-text-left wi-color-black wi-text-bold wi-text-l wi-text-capitalize">{{ driver?.name }} {{
                        driver?.surname }}</h1>
            
                </div>
        
            <!-- Bottone che apre il modal di conferma del rendi vip  -->
        
                <button v-if="!driver?.userDriver?.has_vip" @click="showModal = true"
                    class="wi-button wi-text-bold wi-m-e-3 wi-button-add wi-p-x-2">
                    Rendi vip
                    <wi-icon icon="add"></wi-icon>
                </button>
                <button v-else @click="showModal = true" class="wi-button wi-text-bold wi-m-e-3 wi-button-add wi-p-x-2">
                    Disabilita vip
                    <wi-icon icon="remove"></wi-icon>
                </button>
        
                <wi-modal v-if="showModal" @close="showModal = false" textClose="Annulla" textButton="Procedi"
                    @confirm-entities="confirmDriverVip()"
                    :title="driver?.userDriver?.has_vip == false ? 'sei sicuro di voler rendere vip il driver ?' : 'sei sicuro di voler rimuovere il flag vip al driver?'">
                    <p v-if="driver?.userDriver?.has_vip == false" class="text-body-modal">
                        Sei sicuro di rendere vip {{ driver.name }} ?
                        in questo modo il driver potra gestire le corse della companies
                    </p>
            
                    <p v-else class="text-body-modal">
                        Sei sicuro di non rendere vip {{ driver.name }} ?
                        in questo modo il driver non potra gestire le corse della companies
                    </p>
                </wi-modal>
            </div>
        
            <div class="wi-flex wi-flex-row wi-flex-wrap wi-justify-between wi-m-b-5">
                <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
                    <p class="wi-color-lc wi-m-e-5 wi-text-left wi-text-bold">Nome</p>
                    <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left wi-text-capitalize">{{ driver?.name }}</p>
                </div>
                <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
                    <p class="wi-color-lc wi-m-e-5 wi-text-left wi-text-bold">Congnome</p>
                    <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left wi-text-capitalize">{{ driver?.surname }}</p>
                </div>
                <div class="wi-flex wi-flex-column">
                    <p class="wi-color-lc wi-m-e-5 wi-text-left wi-text-bold">Email</p>
                    <p class=" wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">{{ driver?.email }}</p>
                </div>
                <div class="wi-flex wi-flex-column">
                    <p class="wi-color-lc wi-m-e-5 wi-text-left wi-text-bold">Telefono</p>
                    <p class=" wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">{{ driver?.phone }}</p>
                </div>
            </div>
        </div>
        <div class="wi-flex wi-flex-row wi-flex-wrap wi-m-b-5">
            <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
                <p class="wi-color-lc wi-m-e-5 wi-text-left wi-text-bold">Creato il</p>
                <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left wi-text-capitalize">{{
                    moment(driver?.createdAt).format('DD/MM/YYYY') }}</p>
            </div>
            <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
                <p class="wi-color-lc wi-m-e-5 wi-text-left wi-text-bold">Termini e condizioni accettato</p>
                <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left wi-text-capitalize">{{ driver?.terms ? 'Accettati' :
                    'Non accettato' }}</p>
            </div>
            <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
                <p class="wi-color-lc wi-m-e-5 wi-m-b-1 wi-text-left wi-text-bold">Attivo</p>
                <wi-toggle v-model="isActive" @update:toggle="updateEnableDriver" id="myToggle"></wi-toggle>
            </div>
        </div>
        
        <h2 class="wi-text-left wi-color-black wi-text-bold wi-text-l wi-m-b-5">Documenti Caricati</h2>
        <div class="wi-flex wi-flex-row wi-flex-wrap wi-m-b-5" v-if="driver?.userDriver?.documents?.kb && driver?.userDriver?.documents?.idcard && driver?.userDriver?.documents?.driving_license_doc">
            <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2" v-if="kb">
                <p class="wi-color-lc wi-m-e-5 wi-m-b-2 wi-text-left">Scarica KB</p>
                <a class="wi-button wi-button-download" :href="kb">
                  <i class="fa-solid fa-download"></i>
                </a>
            </div>
            <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2" v-if="idcard">
                <p class="wi-color-lc wi-m-e-5 wi-m-b-2 wi-text-left">Scarica Patente</p>
                <a class="wi-button wi-button-download" :href="idcard">
                  <i class="fa-solid fa-download"></i>
                </a>
            </div>
        </div>
        <div class="wi-flex wi-flex-row wi-flex-wrap wi-m-b-5" v-else>
            <p>Non sono stati caricati documenti</p>   
        </div>
    </div>
</template>

<style lang="scss" scoped>
.wi-button-download {
    width: fit-content;
    padding: 15px 40px;
}
</style>