import { IResponse } from '@/core/interfaces';
import axios from 'axios';
// import { PayloadCreateTour } from '../store/enums';

const getAllPayouts = async (params?: any) : Promise<IResponse> => axios({
  url: `${process.env.VUE_APP_API}/payouts`,
  params,
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}` ?? '',
  },
});


const updatePayoutStatus = async (id: string, status: string) => {
  return axios.request({
    url: `${process.env.VUE_APP_API}/payouts/${id}`,
    method: 'patch',
    data: { status },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
  }).catch((e) => {
    return e.toJSON();
  });
};


const getSinglePayout = async (id: string) => {
  return axios.request({
    url: `${process.env.VUE_APP_API}/payouts/${id}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
  }).catch((e) => {
    return e.toJSON();
  });
};

const PayoutClient = {
  getAllPayouts,
  getSinglePayout,
  updatePayoutStatus,
};
Object.freeze(PayoutClient);
export default PayoutClient;