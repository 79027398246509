<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppIcon',
  props: {
    icon: {
      default: false,
    },
    height: {
      default: '23px',
    },
    width: {
      default: '23px',
    },
  },
});

</script>

<template>
    <div class="wi-icon">
        <div
          :class="'wi-icon-image wi-icon-' + icon"
          :style="'height:' + height + ';' + 'width:' + width + ';'">
        </div>
    </div>
</template>

<style lang="scss" scoped>
.wi-icon {
  margin-left: 4px;

  &-referent {
    background-image: url('@/assets/wicon/referent.png');
  }
  &-members {
    background-image: url('@/assets/wicon/members.png');
  }
  &-drive {
    background-image: url('@/assets/wicon/drive.png');
  }
  &-payment {
    background-image: url('@/assets/wicon/payment.png');
  }
  &-download {
    background-image: url('@/assets/wicon/download.png');
  }
  &-download-black {
    background-image: url('@/assets/wicon/download-black.png');
  }
  &-dashboard {
    background-image: url('@/assets/wicon/dashboard.png');
  }
  &-customers {
    background-image: url('@/assets/wicon/customers.png');
  }
  &-settings {
    background-image: url('@/assets/wicon/settings.png');
  }
  &-exit {
    background-image: url('@/assets/wicon/exit.png');
  }
  &-add {
    background-image: url('@/assets/wicon/add.png');

    &:hover{
      background-image: url('@/assets/wicon/add-black.png');
    }
  }
  &-remove {
    background-image: url('@/assets/wicon/remove.png');

    &:hover{
      background-image: url('@/assets/wicon/remove-black.png');
    }
  }
  &-vehicles {
    background-image: url('@/assets/wicon/vehicles.png');
  }
  &-companies {
    background-image: url('@/assets/wicon/companies.png');
  }
  &-account {
    background-image: url('@/assets/wicon/account.png');
    border-radius: 50%;
  }
  &-edit {
    background-image: url('@/assets/wicon/edit.png');
  }
  &-trash {
    background-image: url('@/assets/wicon/trash.png');
  }
  &-dollars_white {
    background-image: url('@/assets/wicon/dollars_white.png');
  }
  &-drive_white {
    background-image: url('@/assets/wicon/drive_white.png');
  }
  &-wallet_white {
    background-image: url('@/assets/wicon/wallet_white.png');
  }
  &-work_white {
    background-image: url('@/assets/wicon/work_white.png');
  }
  &-check {
    background-image: url('@/assets/wicon/check.png');
  }
  &-van {
    background-image: url('@/assets/wicon/van.png');
  }
  &-economy {
    background-image: url('@/assets/wicon/economy.png');
  }
  &-confort {
    background-image: url('@/assets/wicon/confort.png');
  }
  &-marker-y {
    background-image: url('@/assets/wicon/marker-y.png');
  }
  &-marker-start {
    background-image: url('@/assets/wicon/markerstart.png');
  }
  &-download {
    background-image: url('@/assets/wicon/download.png');
  }
}
</style>
