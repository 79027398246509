<script lang="ts" setup>
import { onMounted, ref, onUnmounted } from 'vue';
import CardAnalisys from '@/modules/dashboard/components/CardAnalisys.vue';
import { useRoute } from 'vue-router';
import RidesClient from './rides-services/ride-client';
import { useStore } from 'vuex';
import alertVuexDefinitions, { Alert, AlertType } from "@/core/store/alert/enums";
import moment from 'moment';
import AppAutoComplete from '@/components/AppAutoComplete.vue';
import { AuthRole, AuthUser } from '../auth/store/enums';
import { OrderBy } from '@/core/interfaces';
import UserClient from '@/core/services/user-client';

const route = useRoute();
const store = useStore();
const center = ref();
const currentRide = ref();
const drivers = ref();
const driverForm = ref()

const updateMemberSearch = async (search: string) => {
  driverForm.value = search;
  const response = await UserClient.getAllUsers(AuthRole.DRIVER, search, OrderBy.DESC, 1);
  drivers.value = response.data.data as AuthUser[];
};

const selectMember = (newValue: AuthUser) => {
  driverForm.value = newValue.name + ' ' + newValue.surname;
  drivers.value = [];
};

const updateRide = async () => {
  try {
    const response = await RidesClient.getSingleRide(route.params.id.toString());
    if (response.data.success) {
      console.log(response.data.data);
      currentRide.value = response.data.data;
      center.value = response.data.data.pickup_address?.position;
    } else {
      store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
        title: "Errore",
        description: 'Errore nel recupero del dettaglio della corsa',
        type: AlertType.Error,
      } as Alert);
    }
  } catch (error) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: "Errore",
      description: error,
      type: AlertType.Error,
    } as Alert);
  }
};

onMounted(async () => {
  updateRide();
});

</script>

<template>
  <app-layout :has-back="true">
    <div class="wi-flex wi-flex-column wi-w-100">
      <div class="wi-flex wi-flex-row wi-justify-between wi-ride-single-header">
        <wi-map-viewr map-id="mapRide" class="wi-p-y-2" :center="currentRide?.pickup_address?.position"
          :has-route-line="true" :origin="currentRide?.pickup_address?.position"
          :destination="currentRide?.dropoff_address?.position" />
        <div class="wi-flex wi-flex-column wi-w-40">
          <card-analisys type="third" icon="marker-start"
            :title="moment(currentRide?.start_time).format('DD-MM-YYYY HH:mm')" description="Orario di partenza"
            :has-dashboard-page="false"></card-analisys>
          <card-analisys type="third" icon="marker-y"
            :title="moment(currentRide?.start_time).add(currentRide?.route_line.duration, 'seconds').format('DD-MM-YYYY HH:mm')"
            description="Orario di arrivo stimato" :has-dashboard-page="false"></card-analisys>
        </div>
      </div>
      <div class="wi-card wi-w-100 wi-m-b-5 wi-p-t-3 wi-p-x-2">
        <div class="wi-flex wi-flex-row wi-align-center wi-m-b-5">
          <h1 class="wi-text-left wi-color-black wi-text-bold wi-text-l">
            Informazioni Corsa
          </h1>
          <wi-icon-svg icon="update" class="wi-m-s-2 cursor-pointer" height="25" @click="updateRide"></wi-icon-svg>
        </div>
        <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-5">
          <p class="wi-color-lc wi-m-e-5 wi-text-left">Driver Assegnato</p>
          <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left" v-if="currentRide?.driver">{{
            currentRide?.driver?.name }} {{ currentRide?.driver?.surname }}</p>
          <div class="wi-m-e-5 wi-m-t-1 wi-w-100" v-else>
            <app-auto-complete :search="driverForm" :has-open="!!drivers"
              @update:search="updateMemberSearch($event)" @reset:items="drivers = undefined"
              placeholder="Seleziona un autista" class="wi-m-t-1 wi-w-100">
              <div class="wi-flex wi-flex-column" v-if="drivers && drivers?.length > 0">
                <div class="items" v-for="item in drivers" :key="item._id">
                  <div class="wi-ride-form-guest" @click="selectMember(item)">
                    <wi-icon-svg icon="member" class="wi-icon wi-m-e-1" width="16" height="16"></wi-icon-svg>
                    <div class="wi-flex wi-flex-column">
                      <a class="wi-text-left wi-color-lc">{{ item.name }} {{ item.surname }} </a>
                      <a class="wi-text-left wi-color-lc">{{ item.email }}</a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="items" v-else>
                <div class="wi-ride-form-guest">
                  <div class="wi-flex wi-flex-column">
                    <p>Nessun membro trovato</p>
                  </div>
                </div>
              </div>
            </app-auto-complete>
          </div>
        </div>
        <div class="wi-flex wi-flex-row wi-flex-wrap  wi-m-b-5 wi-justify-between">
          <div class="wi-flex wi-flex-column wi-flex-wrap wi-justify-start">
            <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-4">
              <p class="wi-color-lc wi-m-e-5 wi-text-left">Membro</p>
              <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">{{ currentRide?.customer?.name }} {{
                currentRide?.customer?.surname }}</p>
            </div>
            <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-4">
              <p class="wi-color-lc wi-m-e-5 wi-text-left">Recapito Telefonico</p>
              <a :href="'tel:' + currentRide?.customer?.phone" class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">{{
                currentRide?.customer?.phone }}</a>
            </div>
          </div>
          <div class="wi-flex wi-flex-column wi-flex-wrap wi-justify-start">
            <div class="wi-flex wi-flex-column wi-m-b-4">
              <p class="wi-color-lc wi-m-e-5 wi-text-left">Stato Corsa</p>
              <p class=" wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">{{ currentRide?.status }}</p>
            </div>
            <div class="wi-flex wi-flex-column">
              <p class="wi-color-lc wi-m-e-5 wi-text-left">Prezzo</p>
              <p class=" wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">{{ currentRide?.amount }} €</p>
            </div>
          </div>
          <div class="wi-flex wi-flex-column wi-flex-wrap wi-justify-start">
            <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-4">
              <p class="wi-color-lc wi-m-e-5 wi-text-left">Punto di ritiro:</p>
              <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">{{ currentRide?.pickup_address?.address }}</p>
            </div>
            <div class="wi-flex wi-flex-column wi-m-e-5">
              <p class="wi-color-lc wi-m-e-5 wi-text-left">Destinazione</p>
              <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">{{ currentRide?.dropoff_address?.address }}</p>
            </div>
          </div>
        </div>
        <div class="wi-flex wi-flex-column wi-m-b-4" v-if="currentRide?.notes">
          <p class="wi-color-lc wi-m-e-5 wi-text-left">Note</p>
          <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">{{ currentRide?.notes }}</p>
        </div>
      </div>
    </div>
  </app-layout>
</template>

<style lang="scss" scoped>
.wi-modal-input {
  min-width: 225px;
  margin-top: 4px;
}

.vue-map-container {
  width: 80%;
}

.wi-ride-single-header {
  gap: 50px;
}
</style>
