<!-- eslint-disable no-underscore-dangle -->
<script lang="ts" setup>
import { watch, ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';
import { Vehicle } from '../store/enums';
import AppVehicleForm from '../components/AppVehicleForm.vue';
import VehicleClient from '../services/vehicle-client';
import { VehicleForm } from '../store/enums';
import alertVuexDefinitions, { Alert, AlertType } from '@/core/store/alert/enums';
import moment from 'moment';
import configEnums from "@/core/store/config/enums";

const store = useStore();
const loading = ref(false);
const config = computed(() => store.getters[configEnums.enums.GETTERS.GetConfig]);
const showModalCreate = ref(false);
const showModal = ref(false);
const deleteVehicleId = ref('');
const searchValue = ref('');
const showModalDelete = ref(false);
const showModalEdit = ref(false);
const search = ref('');
const page = ref(1);
const order = ref('asc');
const status = ref();
const paginate = ref();

const getVehiclelist = ref<Vehicle[]>([]);

const deleteVehicle = async (id: string) => {
  try {
    loading.value = true;
    const deletedVehicle = await VehicleClient.deleteVehicle(id)
    if (deletedVehicle) {
      getAllVehicle(search.value, order.value, page.value);
    }
  }catch {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: 'Errore Generico',
      description: 'Descrizione Errore Generico',
      type: AlertType.Error,
    } as Alert);
  } finally {
    loading.value = false;
  }
};

const getAllVehicle = async (search: string, order: string, page: number) => {
  try {
    loading.value = true;
    const vehicle = await VehicleClient.getAllVehicles({search, order, page});
    if(vehicle.data?.success) {
      getVehiclelist.value = vehicle.data.data as Vehicle[];
      paginate.value = vehicle.data.paginate;
    } else {
      getVehiclelist.value = [];
    }
  } catch(error: any) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: 'Errore',
      description: error,
      type: AlertType.Error,
    } as Alert);
  } finally {
    loading.value = false;
  }
}
const confirmUpdateVehicle = async () => {
 console.log('error')
}

const updateOrderValue = async (value: any) => {
  order.value = value?.target?.value ?? '';
  getAllVehicle(search.value, order.value, page.value);
}

const updateSearchValue = async (value: any) => {
  search.value = value?.target?.value ?? '';
  getAllVehicle(status.value, order.value, page.value);
}
const updatePage = async (value: any) => {
  page.value = page.value + value;
  getAllVehicle(status.value, order.value, page.value);
}

watch(showModal, async (newValue, oldValue) => {
  if (newValue) {
    // fetchBrandVehicleList();
  }
});

const updateVehicle = async (item: VehicleForm) => {
  showModalEdit.value = !showModalEdit.value;
};

const openDeleteModal = (id: string) => {
  deleteVehicleId.value = id;
  showModalDelete.value = !showModalDelete.value;
};
const confirmDeleteModal = () => {
  deleteVehicle(deleteVehicleId.value)
  showModalDelete.value = !showModalDelete.value;
};

const toggleActiveVehicle = async (id: string) => {
  try {
    const response = await VehicleClient.toggleEnabled(id);
    if (response.data.success) {
      getAllVehicle(status.value, order.value, page.value);
      const title = response.data.data.isEnabled ?  'Veicolo Attivo' : 'Veicolo Disattivo'
      store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
        title: 'Veicolo' ,
        description: 'Ora questo veicolo è pronto per essere usato nelle corse',
        type: AlertType.Success,
      } as Alert);
    }
  } catch(error) {
    console.log(error);
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: 'Errore Generico',
      description: 'Errore nel rendere attivo o disattivo il veicolo',
      type: AlertType.Error,
    } as Alert);
  }
};

const baseIMGPath = process.env.VUE_APP_API_PHOTO;

onMounted(async () => {
  getAllVehicle(status.value, order.value, page.value);
});
</script>

<template>
  <app-layout>
    <div class="wi-flex wi-flex-column wi-w-100">
      <button
        class="wi-button wi-text-bold wi-m-e-3 wi-m-b-1 wi-button-add wi-p-x-2 wi-m-b-2 wi-w-25"
        v-if="false"
      >
        Aggiungi Brand
        <wi-icon icon="add"></wi-icon>
      </button>
      <div class="wi-card wi-w-100">
        <table-page
          :has-add="false"
          :order="order"
          :order-value="order"
          :label="config.modules.vehicle.label"
          :orderBy="config.modules.vehicle.order"
          @add-entities="openCreateModal"
          @update:orderValue="updateOrderValue($event)"
          @update:search="updateSearchValue"
        />
        <wi-modal
          v-if="showModalEdit"
          title="Modifica dati del veicolo"
          textClose="Annulla"
          textButton="Aggiorna"
          @confirm-entities="confirmUpdateVehicle"
          @close="showModalEdit = false"
        >
          <p class="wi-text-left wi-m-b-4">Modifica i campi per apportare i cambiamenti desiderati.</p>
          <app-vehicle-form></app-vehicle-form>
        </wi-modal>
        <wi-modal
          v-if="showModalDelete"
          title="Sei sicuro di voler eliminare il veicolo"
          textButton="Procedi"
          textClose="Annulla"
          @close="showModalDelete = false"
          @confirm-entities="confirmDeleteModal()"
        >
        <p  class="wi-text-left wi-m-b-4">Sei sicuro di voler cancellare il veicolo in questo modo non potrai piu risalire ai dati che riguardano il veicolo</p>
        </wi-modal>
        <wi-modal
          v-if="showModalCreate"
          title="Aggiungi veicolo"
          textButton="Aggiungi"
          textClose="Annulla"
          @close="showModalCreate = false"
          @confirm-entities="confirmCreateModal"
        >
          <p class="wi-text-left wi-m-b-4">Compila i campi per aggiungere un veicolo.</p>
          <app-vehicle-form></app-vehicle-form>
        </wi-modal>
        <table-content 
          :columns="config.modules.vehicle.columns"
          :paginate="paginate"
          :rowsCount="getVehiclelist.length"
          :loading="loading"
          @next-page="updatePage(1)" 
          @prev-page="updatePage(-1)"
        >
          <tr
            class="mc-cursor-pointer"
            v-for="item in getVehiclelist"
            :key="item._id">
            <td class="content">
              <input @change="toggleActiveVehicle(item._id)" type="checkbox" :checked="item.isEnabled">
            </td>
            <td class="content">{{ `${item.driver?.name ?? '- '}${item.driver?.surname ?? '-'}` }}</td>
            <td class="content">{{item.make}}</td>
            <td class="content">{{item.type}}</td>
            <td class="content">{{ moment(item.year).format("YYYY") }}</td>
            <td class="content">{{item.model}}</td>
            <td class="content">{{item.plate }}</td>
            <td class="content">
              <a :href="baseIMGPath + item.book_document">
                <wi-icon icon="download"></wi-icon>
              </a>
            </td>
            <td class="content">
              <a :href="baseIMGPath + item.vehicle_document">
                <wi-icon icon="download"></wi-icon>
              </a>
            </td>
            <td class="content">
              <a :href="baseIMGPath + item.licenze_document">
                <wi-icon icon="download"></wi-icon>
              </a>
            </td>
            <td class="content wi-flex wi-flex-row wi-justify-center">
              <wi-icon v-if="false" height="17px" width="17px" icon="edit" class="cursor-pointer wi-m-e-2"  @click="updateVehicle(item)"></wi-icon>
              <wi-icon height="17px" width="17px" icon="trash" class="cursor-pointer" @click="openDeleteModal(item._id)"> </wi-icon>
            </td>
          </tr>
        </table-content>
      </div>
    </div>
  </app-layout>
</template>
