<template>
    <div class="wi-toggle-switch">
      <input
        type="checkbox"
        :id="id"
        v-model="isChecked"
        class="wi-toggle-switch-checkbox"
      />
      <label :for="id" @click="emit('update:toggle', $event)"  class="wi-toggle-switch-label">
        <span class="wi-toggle-switch-inner"></span>
        <span class="wi-toggle-switch-switch"></span>
      </label>
    </div>
  </template>
  
  <script lang="ts" setup>
  import { ref, watchEffect, withDefaults, defineProps, defineEmits } from 'vue';
  
  const props = withDefaults(defineProps<{
    modelValue?: boolean;
    id: string;
  }>(), {
    modelValue: false,
  });
  
  let isChecked = ref(props.modelValue);
  
  watchEffect(() => {
    isChecked.value = props.modelValue;
  });
  
  const emit = defineEmits(['update:modelValue', 'update:toggle']);
  
  watchEffect(() => {
    emit('update:modelValue', isChecked.value);
  });
  </script>
  
  <style lang="scss" scoped>
  .wi-toggle-switch {
    position: relative;
    width: 40px;
    height: 30px;
    display: inline-block;
    user-select: none;
  
    &-checkbox {
      display: none;
    }
  
    &-label {
        display: block;
        overflow: hidden;
        cursor: pointer;
        border: 2px solid #999999;
        border-radius: 20px;
        background-color: #ffffff;
        height: 25px;
  
      & .wi-toggle-switch-inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.3s ease-in 0s;
      }
  
      & .wi-toggle-switch-switch {
        display: block;
        width: 18px;
        height: 19px;
        background: #999999;
        position: absolute;
        top: 3px;
        bottom: 0;
        right: 18px;
        border: 2px solid #999999;
        border-radius: 47px;
        transition: all 0.3s ease-in 0s;
      }
    }
  }
  
  .wi-toggle-switch-checkbox:checked + .wi-toggle-switch-label .wi-toggle-switch-inner {
    margin-left: 0;
  }
  
  .wi-toggle-switch-checkbox:checked + .wi-toggle-switch-label .wi-toggle-switch-switch {
    right: 4px;
    background: #4caf50;
  }
  </style>