<script lang="ts" setup>
import { computed, ref, onMounted, reactive } from "vue";
import { Referent } from "@/modules/referents/index.d";
import { useStore } from "vuex";
import moment from 'moment';
import enums from "@/core/store/config/enums";
import alertVuexDefinitions, { Alert, AlertType } from "@/core/store/alert/enums";
import BillingClient from "../services/billing-client";
import AppReferentForm from "@/modules/referents/components/AppReferentForm.vue";
import { Invoice } from "../index.d";
import { RangeDateFilter } from "@/core/interfaces";
import { formatErrorMessages } from "@/core/addons/functions";

const store = useStore();
const config = computed(() => store.getters[enums.enums.GETTERS.GetConfig]);
// TODO: remove mock data after dev
const items = ref<Invoice[]>();
const rangeDate = ref<RangeDateFilter>({
  start: '',
  end: '',
});
const page = ref(1);
const order = ref("asc");
const paginate = ref();

onMounted(async () => {
  getAllProforma(rangeDate.value, order.value, page.value);
});

const getAllProforma = async (rangeDate: RangeDateFilter, order: string, page: number) => {
  try {
    const ridesList = await BillingClient.getAllProformas(rangeDate.start, rangeDate.end, order, page);
    if (ridesList.data.success) {
      items.value = ridesList.data.data as Invoice[];
      paginate.value = ridesList.data.paginate;
    } else {
      items.value = [];
    }
  } catch (error: any) {
    const errorCode = error.code;
    if(errorCode === "ERR_BAD_REQUEST") {
      error.response.data.message.forEach((message: string) => {
        store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
          title: errorCode,
          description: message ?? '',
          type: AlertType.Error,
        } as Alert);
      });
    } else {
      store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
        title: errorCode,
        description: 'Errore generico',
        type: AlertType.Error,
      } as Alert);
    }
  }
};

const updateRange = async (value: RangeDateFilter) => {
  rangeDate.value = value;
  getAllProforma(rangeDate.value, order.value, page.value);
};

const updateOrderValue = async (value: string) => {
  order.value = value;
  console.log('order', order.value);
  getAllProforma(rangeDate.value, order.value, page.value);
};

const updatePage = async (value: any) => {
  page.value = page.value + value;
  getAllProforma(rangeDate.value, order.value, page.value);
};

</script>

<template>
  <app-layout>
    <div class="wi-card wi-w-100">
      <table-page
        :has-add="false"
        :has-search="false"
        :has-range-date="true"
        :label="config.modules.billing.label"
        :orderBy="config.modules.billing.order"
        @update:orderValue="updateOrderValue"
        @update:rangeDate="updateRange"
      />
      <table-content
        :columns="config.modules.billing.columns"
        :paginate="paginate"
        :rows-count="items?.length"
        @next-page="updatePage(1)"
        @prev-page="updatePage(-1)"
      >
        <tr class="mc-cursor-pointer" v-for="item in items" :key="item.id">
          <td class="content">{{ item.id }}</td>
          <td class="content">{{ item.customer }}</td>
          <td class="content">{{ item.total }}</td>
          <td class="content">{{ item.dateIssued }}</td>
          <td class="content">{{ item.status }}</td>
          <td class="content wi-flex wi-flex-row wi-justify-e wi-w-auto">
            <wi-icon-svg icon="delete" width="24" height="24"  class="wi-icon wi-icon-pointer wi-m-e-1"></wi-icon-svg>
          </td>
        </tr>
      </table-content>
    </div>
  </app-layout>
</template>

<style lang="scss" scoped></style>
