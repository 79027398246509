import constants from './constants';

export interface OrderConfig {
  name: string,
  value: string
}

export interface ReferentConfig {
  columns: string[],
  orderBy: OrderConfig[],
  label: string
}
export interface MemberConfig {
  columns: string[],
  orderBy: OrderConfig[],
  label: string
}
export interface RideConfig {
  columns: string[],
  orderBy: OrderConfig[],
  filter: OrderConfig[],
  label: string
}

export interface BillingConfig {
  columns: string[],
  orderBy: OrderConfig[],
  filter: OrderConfig[],
  label: string
}

export interface ConfigState {
  modules: {
    referent: ReferentConfig,
    member: MemberConfig,
    ride: RideConfig,
    billing: BillingConfig,
  }
  region: string[]
}

/**
 * PUBLIC ACTIONS, MUTATIONS AND GETTERS EXPOSED TO THE COMPONENTS
 */
const enums = {
  ACTIONS: {
    SetConfig: 'set-config',
  },
  GETTERS: {
    GetConfig: 'get-config',
  },
  MUTATIONS: {
    UpdateConfig: 'update-config',
  },
};
export default {
  namespaced: {
    ACTIONS: {
      SetConfig: `${constants.MODULE_NAME}/${enums.ACTIONS.SetConfig}`,
    },
    GETTERS: {
      GetConfig: `${constants.MODULE_NAME}/${enums.GETTERS.GetConfig}`,
    },
    MUTATIONS: {
      UpdateConfig: `${constants.MODULE_NAME}/${enums.MUTATIONS.UpdateConfig}`,
    },
  },
  enums,
};
