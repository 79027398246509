
<script lang="ts" setup>
import { ref, onMounted, computed, watch } from 'vue';
import moment from 'moment';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import enums from '../store/enums';
import configEnums from "@/core/store/config/enums";
import { OrderBy } from '@/core/interfaces';
import UserClient from '@/core/services/user-client';
import RidesClient from '@/modules/rides/rides-services/ride-client';
import alertVuexDefinitions, { Alert, AlertType } from '@/core/store/alert/enums';
import { Ride } from '@/modules/rides/store/enums';

const store = useStore();
const route = useRoute();
const router = useRouter();
const currentTab = ref('profile');
const getSelectedCompany = computed(() => store.getters[enums.enums.GETTERS.GetSelectedCompany]);
const getRidesList = ref<Ride[]>();

const isActive = ref();
const search = ref('');
const page = ref(1);
const order = ref('asc');
const status = ref();
const config = computed(() => store.getters[configEnums.enums.GETTERS.GetConfig]);
const paginate = ref();
const loading = ref(false);
const rideValues = ref();
const showModalEnabled = ref();

onMounted(async () => {
  const routerId = route.params.id;
  await store.dispatch(enums.enums.ACTIONS.SetSelectedCompany, routerId);
  console.log(getSelectedCompany.value.isEnabled);
  isActive.value = getSelectedCompany.value?.isEnabled;
  getAllRide(status.value, search.value, order.value, page.value);
});

const updateEnableUser = async (event: boolean) => {
  if(isActive.value !== event) {
    await UserClient.setEnabledUser(getSelectedCompany.value._id);
    await store.dispatch(enums.enums.ACTIONS.SetSelectedCompany, route.params.id);
  }
};

const getAllRide = async (status: string, search: string, order: string, page: number) => {
  try {
    loading.value = true;
    let ridesList;
    console.log(getSelectedCompany.value?.company)
    if(!status) {
      ridesList = await RidesClient.getAllRides({company: getSelectedCompany.value?.company?._id, search, order, page});
    } else {
      ridesList = await RidesClient.getAllRides({company: getSelectedCompany.value?.company?._id, status, search, order, page});
    }
    if(ridesList.data.success) {
      getRidesList.value = ridesList.data.data as Ride[];
      paginate.value = ridesList.data.paginate;
    } else {
      getRidesList.value = [];
    }
  } catch(error: any) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: 'Errore',
      description: error,
      type: AlertType.Error,
    } as Alert);
  } finally {
    loading.value = false;
  }
}

const updateOrderValue = async (value: any) => {
  order.value = value?.target?.value ?? '';
  getAllRide(status.value, search.value, order.value, page.value);
}

const updateSearchValue = async (value: any) => {
  search.value = value?.target?.value ?? '';
  getAllRide(status.value, search.value, order.value, page.value);
}
const updatePage = async (value: any) => {
  page.value = page.value + value;
  getAllRide(status.value, search.value, order.value, page.value);
}
const openDeleteRide = async (ride: Ride) => {
  showModalEnabled.value = true;
  rideValues.value = ride;
};

const deleteRide = async () => {
  try {
    const ridesList = await RidesClient.cancelRide(rideValues.value?._id ?? "");
    if (ridesList.data.success) {
      store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
        title: "Eliminato",
        description: "Corsa annullata con successo",
        type: AlertType.Success,
      } as Alert);
      getAllRide(search.value, status.value, order.value, page.value);
    }
  } catch (error: any) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: "Errore",
      description: error.data.message,
      type: AlertType.Error,
    } as Alert);
  } finally {
    showModalEnabled.value = false;
    rideValues.value = null;
  }
};
const momentFunc = moment;
</script>

<template>
  <app-layout :has-back="true">
    <div class="wi-flex wi-flex-column wi-w-100">
      <div class="wi-flex wi-justify-s wi-align-center wi-m-y-2">
        <a @click="currentTab = 'profile'" class="wi-text cursor-pointer wi-m-e-2" :class="currentTab === 'profile' ? 'wi-text-bold' : ''">Dettagli Company</a>
        <a @click="currentTab = 'rides'" class="wi-text cursor-pointer" :class="currentTab === 'rides' ? 'wi-text-bold' : ''">Lista Corse</a>
      </div>
      <div v-if="currentTab === 'profile'" class="wi-card wi-w-100 wi-m-b-5 wi-p-t-3 wi-p-x-2">
        <h1 class="wi-text-left wi-color-black wi-text-bold wi-text-l wi-m-b-2">Gestore Azienda</h1>
        <p class="wi-text-left wi-color-black wi-text-s wi-m-b-2">Dati gestore azienda, utente di ruolo COMPANY</p>
        <h1 class="wi-text-left wi-color-black wi-text-bold wi-text-m wi-m-b-5">
        {{getSelectedCompany?.name + ' ' + getSelectedCompany?.surname}}
        </h1>
        <div class="wi-flex wi-flex-row wi-flex-wrap  wi-m-b-5">
          <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
            <p class="wi-color-lc wi-m-e-5 wi-text-left">Nome</p>
            <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">{{ getSelectedCompany?.name }}</p>
          </div>
          <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
            <p class="wi-color-lc wi-m-e-5 wi-text-left">Cognome</p>
            <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">{{ getSelectedCompany?.email }}</p>
          </div>
          <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
            <p class="wi-color-lc wi-m-e-5 wi-text-left">Email</p>
            <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">{{ getSelectedCompany?.email }}</p>
          </div>
          <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
            <p class="wi-color-lc wi-m-e-5 wi-text-left">Numero di telefono</p>
            <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">{{ getSelectedCompany?.phone }}</p>
          </div>
          <div class="wi-flex wi-flex-column">
            <p class="wi-color-lc wi-m-e-5 wi-text-left">N° Membri</p>
            <p class=" wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">{{ getSelectedCompany?.company?.members?.length }}</p>
          </div>
        </div>
        <div class="wi-flex wi-flex-row wi-flex-wrap  wi-m-b-5">
          <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
            <p class="wi-color-lc wi-m-e-5 wi-text-left">Creato il</p>
            <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">{{ moment(getSelectedCompany?.createdAt).format('DD/MM/YYYY') }}</p>
          </div>
          <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
            <p class="wi-color-lc wi-m-e-5 wi-text-left">Termini e condizioni</p>
            <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">{{ getSelectedCompany?.terms ? 'Accettati': 'Non accettati' }}</p>
          </div>
          <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
            <p class="wi-color-lc wi-m-e-5 wi-m-b-1  wi-text-left">Attivo</p>
            <wi-toggle v-model="isActive" @update:toggle="updateEnableUser" id="myToggle"></wi-toggle>
          </div>
        </div>
      </div>
      <div v-if="currentTab === 'rides'" class="wi-card wi-w-100">
        <table-page
          :has-add="false"
          :filter-value="status"
          :order="order"
          :order-value="order"
          :label="config.modules.ride.label"
          :orderBy="config.modules.ride.order"
          :filter-by="config.modules.ride.filter"
          @update:orderValue="updateOrderValue($event)"
          @update:filterValue="getAllRide($event.target?.value, search, order, page)"
          @update:search="updateSearchValue"
        />
        <table-content :columns="config.modules.ride.columns" :paginate="paginate" :rows-count="getRidesList?.length" :loading="loading" @next-page="updatePage(1)" @prev-page="updatePage(-1)">
          <tr class="mc-cursor-pointer" v-for="item in getRidesList" :key="item?._id">
            <td class="content">{{ moment(item.start_time).format('DD-MM-YYYY HH:mm') }}</td>
            <td class="content">{{item.pickup_address?.address}}</td>
            <td class="content">{{item.dropoff_address?.address}}</td>
            <td class="content">€{{item.amount}}</td>
            <td class="content">{{ item.customer?.name }} {{item.customer?.surname }}</td>
            <td class="content">{{ item.driver?.name }} {{item.driver?.surname }}</td>
            <td class="content wi-flex wi-flex-row wi-justify-center wi-m-w-none wi-w-auto">
              <wi-icon-svg icon="view" width="24" height="24" class="wi-icon wi-icon-pointer wi-m-e-1" @click="router.push('/rides/' + item._id)"></wi-icon-svg>
              <wi-icon-svg v-if="item.status !== 'COMPLETED' && item.status !== 'CANCELLED'" icon="delete" width="24" height="24"  class="wi-icon wi-icon-pointer wi-m-e-1" @click="openDeleteRide(item)"></wi-icon-svg>
            </td>
          </tr>
        </table-content>
        <wi-modal
          v-if="showModalEnabled == true"
          @close="showModalEnabled = false"
          textClose="Annulla"
          textButton="Procedi"
          title="Cancella la corsa"
          @confirm-entities="deleteRide"
        >
          <p class="wi-text-left wi-m-b-4">Sei Sicuro di voler cancella questa corsa?</p>
        </wi-modal>
      </div>
    </div>
  </app-layout>
</template>

<style lang="scss" scoped>
.wi-modal-input {
  min-width: 225px;
  margin-top: 4px;
}

</style>
