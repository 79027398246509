import { IResponse } from '@/core/interfaces';
import axios from 'axios';
import { PayloadCreateTour } from '../store/enums';

const getAllTours = async (params?: any) : Promise<IResponse> => axios({
  url: `${process.env.VUE_APP_API}/tour`,
  params,
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}` ?? '',
  },
});


const create = async (data: PayloadCreateTour) => {
  console.log(data);
  return axios.request({
    url: `${process.env.VUE_APP_API}/tour`,
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
    data,
  });
};

const cancelTour = async (id: string) => {
  return axios.request({
    url: `${process.env.VUE_APP_API}/tour/${id}`,
    method: 'delete',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
  }).catch((e) => {
    return e.toJSON();
  });
};


const getSingleTour = async (id: string) => {
  return axios.request({
    url: `${process.env.VUE_APP_API}/tour/${id}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
  }).catch((e) => {
    return e.toJSON();
  });
};

const ToursClient = {
  getAllTours,
  create,
  getSingleTour,
  cancelTour,
};
Object.freeze(ToursClient);
export default ToursClient;