import vuexDefinitions, { AuthState } from '@/modules/auth/store/enums';

export default {
  [vuexDefinitions.enums.GETTERS.GetEmail]:
    (state: AuthState) => state.email,
  [vuexDefinitions.enums.GETTERS.GetFirebaseUid]:
    (state: AuthState) => state.firebaseUid,
  [vuexDefinitions.enums.GETTERS.GetErrorProfile]:
    (state: AuthState) => state.errorProfile,
  [vuexDefinitions.enums.GETTERS.GetAuthUser]:
    (state: AuthState) => state.authUser,
};
