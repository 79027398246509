
<script lang="ts" setup>
import { ref, onMounted, computed, watch } from 'vue';
import moment from 'moment';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import configEnums from "@/core/store/config/enums";
import { OrderBy } from '@/core/interfaces';
import UserClient from '@/core/services/user-client';
import PayoutClient from '@/modules/payout/payout-services/payout-client';
import alertVuexDefinitions, { Alert, AlertType } from '@/core/store/alert/enums';
import { Ride } from '@/modules/rides/store/enums';

const store = useStore();
const route = useRoute();
const router = useRouter();
const currentTab = ref('profile');
const getRidesList = ref<Ride[]>();

const isActive = ref();
const search = ref('');
const page = ref(1);
const order = ref('asc');
const status = ref();
const config = computed(() => store.getters[configEnums.enums.GETTERS.GetConfig]);
const paginate = ref();
const loading = ref(false);
const rideValues = ref();
const currentPayout = ref();
const textButton = computed(() => {
  return currentPayout.value?.status === 'pending' ? 'Conferma' : 'Annulla';
});

// Define a type for the status keys
type StatusKey = 'CREATED' | 'VERIFIED';

// Define the statusToLabel object with explicit types
const statusToLabel: Record<StatusKey, string> = {
  'CREATED': 'Verifica',
  'VERIFIED': 'Completa',
};

const paymentTotalCard = computed(() => {
  return getRidesList.value?.reduce((acc: number, item: any) => {
    console.log('item', item.payments_methods.type)
    if(item.payments_methods.type === 'card') {
      acc += item.amount;
    }
    return acc;
  }, 0);
});

const paymentTotalCash = computed(() => {
  return getRidesList.value?.reduce((acc: number, item: any) => {
    console.log('item', item.payments_methods.type)
    if(item.payments_methods.type === 'cash') {
      acc += item.amount;
    }
    return acc;
  }, 0);
});

const changeStatus = async (status: string) => {
  try {
    const routerId = route.params.id;
    const response = await PayoutClient.updatePayoutStatus(routerId as string, status);
    currentPayout.value = response.data.data;
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: "Successo",
      description: "Stato del payout modificato con successo",
      type: AlertType.Success,
    } as Alert);
  } catch (error: any) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: "Errore",
      description: error,
      type: AlertType.Error,
    } as Alert);
  }
  console.log('changeStatus')
}

const getLabelForStatus = (status: StatusKey) => {
  return statusToLabel[status] ?? '';
};

const driverTotalAmount = computed(() => {
  const totalWiiCabs = (paymentTotalCard.value ?? 0 )- (paymentTotalCash.value ?? 0);
  const total = (paymentTotalCard.value ?? 0 ) + (paymentTotalCash.value ?? 0);
  return total - (( (totalWiiCabs ?? 1) * 5) / 100);
});

const driverTotalPercentage = computed(() => {
  const totalWiiCabs = (paymentTotalCard.value ?? 0 )- (paymentTotalCash.value ?? 0);
  return (( (totalWiiCabs ?? 1) * 5) / 100);
});

onMounted(async () => {
  try {
    const routerId = route.params.id;
    const response = await PayoutClient.getSinglePayout(routerId as string);
    currentPayout.value = response.data.data;
    getRidesList.value = response.data.data.rides as Ride[];
  } catch (error: any) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: "Errore",
      description: error,
      type: AlertType.Error,
    } as Alert);
  }  
});

const momentFunc = moment;
</script>

<template>
  <app-layout :has-back="true">
    <div class="wi-flex wi-flex-column wi-w-100">
      <div class="wi-flex wi-justify-s wi-align-center wi-m-y-2">
        <a @click="currentTab = 'profile'" class="wi-text cursor-pointer wi-m-e-2" :class="currentTab === 'profile' ? 'wi-text-bold' : ''">Dettagli Payout</a>
        <a @click="currentTab = 'rides'" class="wi-text cursor-pointer" :class="currentTab === 'rides' ? 'wi-text-bold' : ''">Lista Corse Payout</a>
      </div>
      <div v-if="currentTab === 'profile'" class="wi-card wi-w-100 wi-m-b-5 wi-p-t-3 wi-p-x-2">
        <h1 class="wi-text-left wi-color-black wi-text-bold wi-text-l wi-m-b-2">Dati Driver</h1>
        <p class="wi-text-left wi-color-black wi-text-s wi-m-b-2">Dati gestore azienda, utente di ruolo DRIVER</p>
        <div class="wi-flex wi-flex-row wi-flex-wrap  wi-m-b-5">
          <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
            <p class="wi-color-lc wi-m-e-5 wi-text-left">Nome</p>
            <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left"> {{ currentPayout?.driver?.name }}</p>
          </div>
          <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
            <p class="wi-color-lc wi-m-e-5 wi-text-left">Cognome</p>
            <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">{{ currentPayout?.driver?.surname }}</p>
          </div>
          <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
            <p class="wi-color-lc wi-m-e-5 wi-text-left">Email</p>
            <a class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left" :href="'mailto:' + currentPayout?.driver?.email">{{ currentPayout?.driver?.email }}</a>
          </div>
          <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
            <p class="wi-color-lc wi-m-e-5 wi-text-left">Numero di telefono</p>
            <a class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left" :href="'telto:' + currentPayout?.driver?.phone">{{ currentPayout?.driver?.phone }}</a>
          </div>
          <div class="wi-flex wi-flex-column">
            <p class="wi-color-lc wi-m-e-5 wi-text-left">Iban</p>
            <p class=" wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">{{ currentPayout?.driver?.userDriver?.iban }}</p>
          </div>
        </div>
        <h1 class="wi-text-left wi-color-black wi-text-bold wi-text-l wi-m-b-2">Dati Payout</h1>
        <div class="wi-flex wi-flex-row wi-flex-wrap  wi-m-b-5">
          <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
            <p class="wi-color-lc wi-m-e-5 wi-text-left">Payout periodo da</p>
            <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left"> {{ moment(currentPayout?.payoutFrom).format('DD MMMM YYYY') }} </p>
          </div>
          <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
            <p class="wi-color-lc wi-m-e-5 wi-text-left">Payout periodo a</p>
            <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left"> {{ moment(currentPayout?.payoutTo).format('DD MMMM YYYY') }} </p>
          </div>
          <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
            <p class="wi-color-lc wi-m-e-5 wi-text-left">Pagamenti contanti</p>
            <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">€{{ paymentTotalCash }} </p>
          </div>
          <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
            <p class="wi-color-lc wi-m-e-5 wi-text-left">Pagamenti con carta</p>
            <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">€{{ paymentTotalCard }}</p>
          </div>
        </div>
        <h1 class="wi-text-left wi-color-black wi-text-bold wi-text-l wi-m-b-2">Totale</h1>
        <div class="wi-flex wi-flex-row wi-flex-wrap  wi-m-b-5">
          <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
            <p class="wi-color-lc wi-m-e-5 wi-text-left">Totale draiver</p>
            <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">€{{ driverTotalAmount  }}</p>
          </div>
          <div class="wi-flex wi-flex-column wi-m-e-5 wi-m-b-2">
            <p class="wi-color-lc wi-m-e-5 wi-text-left">Commissioni</p>
            <p class="wi-color-black wi-m-e-5 wi-m-t-1 wi-text-left">€{{ driverTotalPercentage }}</p>
          </div>
        </div>
        <div class="wi-flex wi-flex-row wi-flex-wrap  wi-m-b-5">
          <button
            @click="changeStatus('COMPLETED')"
            v-if="getLabelForStatus(currentPayout?.status)"
            class="wi-button wi-text-bold wi-m-b-1 wi-p-x-2"
          >
            {{ getLabelForStatus(currentPayout?.status) }}
          </button>
          <button
            @click="changeStatus('DECLINED')"
            v-if="currentPayout?.status === 'CREATED'"
            class="wi-button wi-button-alert wi-text-bold wi-m-b-1 wi-p-x-2"
          >
            Declina
          </button>
          <p class="wi-text" v-if="currentPayout?.status === 'COMPLETED'">Completato</p>
          <p class="wi-text" v-if="currentPayout?.status === 'DECLINED'">Declinato</p>
        </div>
      </div>
      <div v-if="currentTab === 'rides'" class="wi-card wi-w-100">
        <table-page
          :has-add="false"
          :has-order="false"
          :has-search="false"
          :label="config.modules.ride.label"
        />
        <table-content :columns="config.modules.ride.columns" :paginate="paginate" :rows-count="getRidesList?.length" :loading="loading" @next-page="updatePage(1)" @prev-page="updatePage(-1)">
          <tr class="mc-cursor-pointer" v-for="item in getRidesList" :key="item?._id">
            <td class="content">{{ moment(item.start_time).format('DD-MM-YYYY HH:mm') }}</td>
            <td class="content">{{item.pickup_address?.address}}</td>
            <td class="content">{{item.dropoff_address?.address}}</td>
            <td class="content">€{{item.amount}}</td>
            <td class="content">{{ item.customer?.name }} {{item.customer?.surname }}</td>
            <td class="content">{{ item.driver?.name }} {{item.driver?.surname }}</td>
            <td class="content wi-flex wi-flex-row wi-justify-center wi-m-w-none wi-w-auto">
              <wi-icon-svg icon="view" width="24" height="24" class="wi-icon wi-icon-pointer wi-m-e-1" @click="router.push('/rides/' + item._id)"></wi-icon-svg>
            </td>
          </tr>
        </table-content>
      </div>
    </div>
  </app-layout>
</template>

<style lang="scss" scoped>
.wi-modal-input {
  min-width: 225px;
  margin-top: 4px;
}
.wi-button-alert {
  background-color: #ff0000;
  color: #fff;
  border: 1px solid #ff0000;
  border-radius: 0;
}
.wi-button {
  border-radius: 0;
}
</style>
