import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = { class: "dropbtn wi-flex" }
const _hoisted_3 = { class: "wi-flex wi-text-bold wi-align-center" }
const _hoisted_4 = { class: "dropdown-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wi_icon = _resolveComponent("wi-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.icon)
        ? (_openBlock(), _createBlock(_component_wi_icon, {
            key: 0,
            icon: _ctx.icon,
            width: "16px",
            height: "16px",
            class: "wi-m-e-1"
          }, null, 8, ["icon"]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.name), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}