import vuexDefinitions, { ReferentFilter, ReferentState } from '@/modules/referents/store/enums';

export default {
  [vuexDefinitions.enums.GETTERS.GetReferentList]:
    (state: ReferentState) => state.referentList,
  [vuexDefinitions.enums.GETTERS.GetReferentFilter]:
    (state: ReferentState) => state.filters,
  [vuexDefinitions.enums.GETTERS.GetPaginate]:
    (state: ReferentState) => state.paginate,
    
};
