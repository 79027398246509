<script lang="ts" setup>
import { defineEmits } from "vue";
import { reactive, ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email, helpers } from "@vuelidate/validators";

const emits = defineEmits(["update-form-value", "update-form-valid"]);

const state = reactive({
  name: "",
  surname: "",
  email: "",
  phone: "",
});

const rules = {
  name: { required },
  surname: { required },
  email: { required, email },
  phone: { 
    required,
    phone: {
      $validator: value => {
        const regex = /^\+\d{9,15}$/;
        return regex.test(value);
      },
      $message: 'Invalid phone number. It must start with + and followed by 9-15 digits.',
    },
  },
};

const v$ = useVuelidate(rules, state);

const updateFormValue = () => {
  emits("update-form-value", { name: state.name, surname: state.surname, email: state.email, phone: state.phone });
  emits("update-form-valid", v$.value.$invalid);
};
</script>
<template>
  <div class="wi-ride-form">
    <div class="wi-flex wi-flex-row wi-justify-between wi-m-b-1">
      <!-- Input for name and surname -->
      <label for="name" class="wi-text-left wi-text-xs wi-text-bold wi-w-44 mc-w-l-100">
        Nome:
        <input
          @input="updateFormValue"
          v-model="v$.name.$model"
          type="text"
          placeholder="Inserisci il tuo nome"
          id="name"
          :class="{ 'wi-input-error wi-m-b-1': v$.name.$error, 'wi-m-b-1': !v$.name.$error }"
          class="wi-input wi-w-100 wi-m-t-1"
        />
        <div class="input-errors wi-m-b-1" v-for="error of v$.name.$errors" :key="error.$uid">
          <div class="wi-text-xxs">{{ error.$message }}</div>
        </div>
      </label>

      <label for="surname" class="wi-text-left wi-text-xs wi-text-bold wi-w-44 mc-w-l-100">
        Cognome:
        <input
          @input="updateFormValue"
          v-model="v$.surname.$model"
          type="text"
          placeholder="Inserisci il tuo cognome"
          id="surname"
          :class="{ 'wi-input-error wi-m-b-1': v$.surname.$error, 'wi-m-b-1': !v$.surname.$error }"
          class="wi-input wi-w-100 wi-m-t-1"
        />
        <div class="input-errors wi-m-b-1" v-for="error of v$.surname.$errors" :key="error.$uid">
          <div class="wi-text-xxs">{{ error.$message }}</div>
        </div>
      </label>
    </div>

    <!-- Input for email and phone -->
    <div class="wi-flex wi-flex-row wi-justify-between">
      <label for="email" class="wi-text-left wi-text-xs wi-text-bold wi-w-44 mc-w-l-100">
        Email:
        <input
          @input="updateFormValue"
          v-model="v$.email.$model"
          type="text"
          placeholder="Inserisci la tua email"
          id="email"
          :class="{ 'wi-input-error wi-m-b-1': v$.email.$error, 'wi-m-b-1': !v$.email.$error }"
          class="wi-input wi-w-100 wi-m-t-1"
        />
        <div class="input-errors wi-m-b-1" v-for="error of v$.email.$errors" :key="error.$uid">
          <div class="wi-text-xxs">{{ error.$message }}</div>
        </div>
      </label>

      <label for="phone" class="wi-text-left wi-text-xs wi-text-bold wi-w-44 mc-w-l-100">
        Telefono:
        <input
          @input="updateFormValue"
          @blur="v$.phone.$touch()"
          v-model="v$.phone.$model"
          type="text"
          placeholder="Inserisci il tuo numero di telefono"
          id="phone"
          :class="{ 'wi-input-error wi-m-b-1': v$.phone.$error, 'wi-m-b-1': !v$.phone.$error }"
          class="wi-input wi-w-100 wi-m-t-1"
        />
        <div class="input-errors wi-m-b-1" v-for="error of v$.phone.$errors" :key="error.$uid">
          <div class="wi-text-xxs">{{ error.$message }}</div>
        </div>
      </label>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.wi-ride {
  &-form {
    display: flex;
    flex-direction: column;
    fieldset {
      width: max-content;
    }
  }
}

button:active {
  background-color: F7E462;
  color: black;
  outline: 1px solid F7E462;
}
</style>
