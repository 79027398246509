import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-6a9cc476"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "wi-card-dashboard-inner" }
const _hoisted_2 = { class: "wi-text-l wi-text-bold" }
const _hoisted_3 = { class: "wi-text-xs wi-text-left" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_wi_icon = _resolveComponent("wi-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["mc-w-xxl-100 wi-p-y-2", { 'mc-min-w-xl-25' : _ctx.hasDashboardPage }])
  }, [
    _createElementVNode("div", {
      class: _normalizeClass(["wi-w-100 wi-card-dashboard", 'wi-card-dashboard-' + _ctx.type])
    }, [
      _createVNode(_component_wi_icon, {
        icon: _ctx.icon,
        width: "38px",
        height: "38px",
        class: "wi-m-e-1"
      }, null, 8, ["icon"]),
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.title), 1),
        _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.description), 1)
      ])
    ], 2)
  ], 2))
}