import { IResponse } from '@/core/interfaces';
import axios from 'axios';

const setDriverVip = async (id: string) : Promise<IResponse> => axios({
  url: `${process.env.VUE_APP_API}/drivers/toggle-vip/${id}`,
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}` ?? '',
  },
}).catch((e) => e.toJSON());



const DriverClient = {
  setDriverVip,
};
Object.freeze(DriverClient);
export default DriverClient;