import AuthClient from '@/modules/auth/services/auth-client';
import enums, { AuthUser } from '@/modules/auth/store/enums';
import router from '@/router';
import store from '@/store/index';
import alertVuexDefinitions, { Alert, AlertType } from '../store/alert/enums';

export default async function authGuard() {
  try {
    const result = await AuthClient.checkUsertoken();
    const value = sessionStorage.getItem('token');
    if (value === '' || result?.status === 401 || result?.status === 403) {
      router.push('/login');
    } else {
      const authUser: AuthUser = result?.data.data;
      store.dispatch(enums.enums.ACTIONS.SetProfile, { ...authUser });
    }
  } catch(error: any) {
    router.push('/login');
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: "Errore",
      description: 'Sessione interrotta, effettuare nuovamente il login per continuare',
      type: AlertType.Error,
    } as Alert);
  }
}
