import { AlertState } from '@/core/store/alert/enums';
import { InjectionKey } from 'vue';
import { Store } from 'vuex';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

export const alertState = {
  alerts: [],
};

export const key: InjectionKey<Store<AlertState>> = Symbol('Alert Store');

export default ({
  state: (): AlertState => (alertState),
  getters: {
    ...getters,
  },
  mutations: {
    ...mutations,
  },
  actions: {
    ...actions,
  },
});
