<script lang="ts" setup>
import { defineEmits, defineProps } from 'vue';

import { Paginate } from '@/core/interfaces';
import { PropType } from 'vue';

const emit = defineEmits(['load-page', 'prev-page', 'next-page']);

const props = defineProps({
  columns: {
    type: Array as PropType<Array<string>>,
    required: true,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  rowsCount: {
    type: Number,
    default: 0,
  },
  paginate: {
    type: Object as PropType<Paginate>,
    required: false
  },
  hasPaginate: {
    type: Boolean,
    default: true,
  }
});

</script>

<template>
  <div class="mc-w-100 mc-table table-content">
    <div class="table-loader" v-if="props.loading">
      <div class="circle-loader"></div>
    </div>
    <table id="customers" v-else-if="props.rowsCount > 0">
      <tr>
        <th class="content" v-for="(col, index) in columns" :key="col" :class="{ 'wi-w-auto wi-m-w-none': index + 1 === columns.length }">{{ col }}</th>
      </tr>
      <slot />
    </table>
    <!-- <div v-else class="loader"></div> -->
    <p v-else class="wi-text-s wi-font-bold wi-text-bold wi-m-y-4 wi-text-left wi-p-x-2">Nessun risultato trovato, prova cambiando i parametri di ricerca</p>
  </div>
  <div class="buttons wi-flex wi-justify-e wi-align-center wi-p-b-2 wi-p-t-1" v-if="rowsCount && hasPaginate">
    <p class="pagination">Oggetti per pagine: {{ rowsCount }}</p>
    <p class="pagination">pagine: {{ paginate?.pageCount }}</p>
    <p class="pagination">totale elementi: {{ paginate?.itemCount }}</p>
    <button :disabled="!paginate?.hasPreviousPage"  class="button-direction" @click="emit('prev-page')" >
      <i class="fa-solid fa-chevron-left"></i>
    </button>
    <button :disabled="!paginate?.hasNextPage"   @click="emit('next-page')" class="button-direction last-button">
      <i class="fa-solid fa-chevron-right"></i>
    </button>
  </div>
</template>

<style lang="scss" scoped>
.table-content {
  min-height: 50px;
}
.table-loader {
  align-items: center;
  column-gap: 4px;
  display: flex;
  justify-content: center;
  padding: 1rem 0;
}
.button-direction{
  padding: .5rem;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.pagination{
  font-size: .7rem;
  margin: 0 .5rem;
}
.last-button{
  margin-right: 1rem;
}
</style>
