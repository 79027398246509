import { IResponse } from '@/core/interfaces';
import axios from 'axios';

const getAllConfig = async () : Promise<IResponse> => axios({
  url: `${process.env.VUE_APP_API}/configs`,
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}` ?? '',
  },
}).catch((e) => e.toJSON());


const ConfigClient = {
  getAllConfig,
};
  // Disallow new properties on our object
Object.freeze(ConfigClient);
export default ConfigClient;
