<script lang="ts">
import { defineComponent } from 'vue';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';
import CompaniesForm from '../components/companies-form.vue';
import enums from '../store/enums';
import { OrderBy } from '@/core/interfaces';


export default defineComponent({
  name: 'CompaniesIndex',
  components: {
    CompaniesForm,
  },
  data() {
    return {
      columns: [
        'Data', 'Nome', 'Cogome', 'Email', 'Ragione Sociale', 'SDI', 'Pec', 'Città', 'Partita Iva',
      ],
      showModal: false,
      createCompanyId: '',
      orderBy: [
        {
          name: 'Più recente',
          value: OrderBy.ASC
        },{
          name: 'Meno recente',
          value: OrderBy.DESC
        }
      ],
      searchValue: ''
    };
  },
  async mounted() {
    await this.setCompaniesList();
  },

  computed: {
    ...mapGetters({
      getAllCompanies: enums.enums.GETTERS.GetAllCompanies,
      companyFilter: enums.enums.GETTERS.GetCompanyFilters,
      paginate: enums.enums.GETTERS.GetPage,
    }),
  },
  methods: {
    ...mapActions({
      setCompaniesList: enums.enums.ACTIONS.SetAllCompanies,
      setPostCompany: enums.enums.ACTIONS.SetPostCompany,
    }),
    moment,

    resetValue() {
      this.searchValue = '';
      this.setCompaniesList({
        ...this.companyFilter,
        search: this.searchValue, // Imposta il valore di ricerca nel filtro a una stringa vuota
      });
    }
  }
});
</script>

<template>
  <app-layout>
    <div class="wi-card wi-w-100">
      <table-page
        label="Companies"
        :orderBy="orderBy"
        @update:search="setCompaniesList(
          {
            ...companyFilter,
            search: $event.target.value,
          },
          paginate.page,
        )"
        @update:orderValue="setCompaniesList(
          {
            ...companyFilter,
            orderBy: $event.target.value,
          },
          paginate.page,
        )"
        @add-entities="showModal = !showModal"
        @update:reset="resetValue()"
      />
      <wi-modal
        v-if="showModal"
        @close="showModal=false"
        textButton="Aggiungi"
        textClose="Annulla"
        title="Aggiungi una company"
        @confirm-entities="setPostCompany"

      >
        <companies-form ref="submitForm"></companies-form>
      </wi-modal>
      <table-content
        :columns="columns"
        :paginate="paginate"
        :rows-count="getAllCompanies.length"
        @prev-page="setCompaniesList(
          {
            ...companyFilter,
            page: parseInt(paginate.page ?? '1') - 1
          }
        )"
        @next-page="setCompaniesList(
          {
            ...companyFilter,
            page: parseInt(paginate.page ?? '1') + 1
          }
        )"
      >
        <tr
          class="mc-cursor-pointer"
          v-for="item in getAllCompanies"
          :key="item.id"
          @click="$router.push(`companies/${item._id}`)"
        >
          <td class="content">{{ moment(item.createdAt).format("MMM Do YY") }}</td>
          <td class="content">{{item.name}}</td>
          <td class="content">{{item.surname}}</td>
          <td class="content">{{item.email}}</td>
          <td class="content">{{item.company?.bName}}</td>
          <td class="content">{{item.company?.sdi}}</td>
          <td class="content">{{item.company?.pec}}</td>
          <td class="content">{{item.company?.country}}</td>
          <td class="content">{{item.company?.pIva}}</td>
        </tr>
      </table-content>
    </div>
  </app-layout>
</template>

<style lang="scss" scoped>
.wi-modal-input {
  min-width: 225px;
  margin-top: 4px;
}
</style>
