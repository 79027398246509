<script lang="ts" setup>
import { computed, ref, onMounted, reactive } from "vue";
import { Referent } from "@/modules/referents/index.d";
import { useStore } from "vuex";
import moment from 'moment';
import enums from "@/core/store/config/enums";
import alertVuexDefinitions, { Alert, AlertType } from "@/core/store/alert/enums";
import ReferentClient from "../referents/services/referent-client";
import AppReferentForm from "@/modules/referents/components/AppReferentForm.vue";

const store = useStore();
const selectedReferent = ref<Referent>();
const config = computed(() => store.getters[enums.enums.GETTERS.GetConfig]);
// TODO: remove mock data after dev
const items = ref<Referent[]>();
const search = ref("");
const page = ref(1);
const order = ref("asc");
const paginate = ref();
const referentData = reactive({ formData: null });
const referentDetail = reactive({ referent: null });
const referentFormData = ref(false);
const showModalAddReferent = ref(false);
const showModalEnabled = ref(false);
const showModalReferentDetail = ref(false);

onMounted(async () => {
  getAllReferent(search.value, order.value, page.value);
});

const getAllReferent = async (search: string, order: string, page: number) => {
  try {
    const ridesList = await ReferentClient.getAllReferent(search, order, page);
    if (ridesList.data.success) {
      items.value = ridesList.data.data as Referent[];
      paginate.value = ridesList.data.paginate;
    } else {
      items.value = [];
    }
  } catch (error: any) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: "Errore",
      description: error.data.message,
      type: AlertType.Error,
    } as Alert);
  }
};

const getFormData = (data: any) => {
  referentData.formData = data;
};

const addReferent = async () => {
  // Add a new referent
  try {
    const ridesList = await ReferentClient.addReferent(referentData.formData);

    if (ridesList.data.success) {
      store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
        title: "Aggiunta utente",
        description: "Utente aggiunto con successo",
        type: AlertType.Success,
      } as Alert);
      getAllReferent(search.value, order.value, page.value);
    } else {
      store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
        title: "Errore",
        description: ridesList.data.data.toString(),
        type: AlertType.Error,
      } as Alert);
    }
  } catch (error: any) {
    if(error.response.data?.message && Array.isArray(error.response.data?.message)) {
      error.response.data?.message.forEach((message: any) => {
        store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
          title: "Errore",
          description: message,
          type: AlertType.Error,
        } as Alert);
      });
    } else {
      store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
        title: "Errore",
        description: error,
        type: AlertType.Error,
      } as Alert);
    }
  } finally {
    showModalAddReferent.value = false;
  }
};

const deleteReferent = async () => {
  try {
    const ridesList = await ReferentClient.deleteReferent(selectedReferent.value?._id ?? "");
    if (ridesList.data.success) {
      store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
        title: "Eliminato",
        description: "Utente eliminato con successo",
        type: AlertType.Success,
      } as Alert);
      getAllReferent(search.value, order.value, page.value);
    }
  } catch (error: any) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: "Errore",
      description: error.data.message,
      type: AlertType.Error,
    } as Alert);
  } finally {
    showModalEnabled.value = false;
  }
};

const showReferent = (referent:any) => {
  showModalReferentDetail.value = true;
  referentDetail.referent = referent;
}

const updateOrderValue = async (value: any) => {
  order.value = value?.target?.value ?? "";
  getAllReferent(search.value, order.value, page.value);
};

const updateSearchValue = async (value: any) => {
  search.value = value?.target?.value ?? "";
  getAllReferent(search.value, order.value, page.value);
};
const updatePage = async (value: any) => {
  page.value = page.value + value;
  getAllReferent(search.value, order.value, page.value);
};

const openDeleteReferent = async (referent: Referent) => {
  showModalEnabled.value = true;
  selectedReferent.value = referent;
};
</script>

<template>
  <app-layout>
    <div class="wi-card wi-w-100">
      <table-page
        :has-add="true"
        add-label="Aggiungi Referente"
        :label="config.modules.referent.label"
        :orderBy="config.modules.referent.order"
        @add-entities="showModalAddReferent = true"
        @update:orderValue="updateOrderValue"
        @update:search="updateSearchValue"
      />
      <table-content
        :columns="config.modules.referent.columns"
        :paginate="paginate"
        :rows-count="items?.length"
        @next-page="updatePage(1)"
        @prev-page="updatePage(-1)"
      >
        <tr class="mc-cursor-pointer" v-for="item in items" :key="item.phone">
          <td class="content">{{ item.name }}</td>
          <td class="content">{{ item.surname }}</td>
          <td class="content">{{ item.email }}</td>
          <td class="content">{{ item.phone }}</td>
          <td class="content wi-flex wi-flex-row wi-justify-e wi-w-auto">
            <wi-icon-svg icon="view" width="24" height="24" class="wi-icon wi-icon-pointer wi-m-e-1" @click="showReferent(item)"></wi-icon-svg>
            <wi-icon-svg icon="delete" width="24" height="24"  class="wi-icon wi-icon-pointer wi-m-e-1" @click="openDeleteReferent(item)"></wi-icon-svg>
          </td>
        </tr>
      </table-content>
    </div>
    <wi-modal
      v-if="showModalAddReferent == true"
      @close="showModalAddReferent = false"
      textClose="Annulla"
      textButton="Aggiungi"
      title="Aggiungi Referente"
      :disable-add-button="referentFormData || referentData.formData === null"
      @confirm-entities="addReferent"
    >
      <p class="wi-text-left wi-m-b-4">Compila i dati per aggiungere un referente</p>
      <app-referent-form @update-form-value="getFormData" @update-form-valid="referentFormData = $event"></app-referent-form>
    </wi-modal>
    <wi-modal
      v-if="showModalEnabled == true"
      @close="showModalEnabled = false"
      textClose="Annulla"
      textButton="Procedi"
      title="Elimina Referente"
      @confirm-entities="deleteReferent"
    >
      <p class="wi-text-left wi-m-b-4">Sei Sicuro di voler eliminare questo referente?</p>
    </wi-modal>

    <wi-modal
      v-if="showModalReferentDetail == true"
      @close="showModalReferentDetail = false"
      textClose="Chiudi"
      textButton=""
      title="Dettagli referente"
      hideFooter="true"
    >
      <div class="wi-flex wi-flex-column">
        <div class="wi-flex wi-flex-column wi-m-b-2 wi-bb-1 wi-p-b-1">
          <p class="wi-text-xs wi-text-bold wi-text-left wi-m-r-2">Nome:</p>
          <p class="wi-text-s wi-text-left">{{referentDetail.referent.name}}</p>
        </div> 
        <div class="wi-flex wi-flex-column wi-m-b-2 wi-bb-1 wi-p-b-1">
          <p class="wi-text-xs wi-text-bold wi-text-left wi-m-r-2">Cognome:</p>
          <p class="wi-text-s wi-text-left">{{referentDetail.referent.surname}}</p>
        </div>  
        <div class="wi-flex wi-flex-column wi-m-b-2 wi-bb-1 wi-p-b-1">
          <p class="wi-text-xs wi-text-bold wi-text-left wi-m-r-2">Email:</p>
          <p class="wi-text-s wi-text-left">{{referentDetail.referent.email}}</p>
        </div>
        <div class="wi-flex wi-flex-column wi-m-b-2 wi-bb-1 wi-p-b-1">
          <p class="wi-text-xs wi-text-bold wi-text-left wi-m-r-2">Telefono:</p>
          <p class="wi-text-s wi-text-left">{{referentDetail.referent.phone}}</p>
        </div>
        <div class="wi-flex wi-flex-column wi-m-b-2 wi-bb-1 wi-p-b-1">
          <p class="wi-text-xs wi-text-bold wi-text-left wi-m-r-2">Registrato il:</p>
          <p class="wi-text-s wi-text-left">{{moment(referentDetail.referent.createdAt).format('GG-MM-YYYY')}}</p>
        </div>
        <div class="wi-flex wi-flex-column wi-m-b-2 wi-bb-1 wi-p-b-1">
          <p class="wi-text-xs wi-text-bold wi-text-left wi-m-r-2">Utente attivo:</p>
          <p class="wi-text-s wi-text-left wi-flex wi-align-center" v-if="!referentDetail.referent.isEnabled">
            <svg class="wi-icon wi-icon-pointer" width="24" height="24" fill="#3D9970" viewBox="0 0 24 24">
              <path d="M11 20c-0.801 0-1.555-0.312-2.121-0.879l-4-4c-0.567-0.566-0.879-1.32-0.879-2.121s0.312-1.555 0.879-2.122c1.133-1.133 3.109-1.133 4.242 0l1.188 1.188 3.069-5.523c0.526-0.952 1.533-1.544 2.624-1.544 0.507 0 1.012 0.131 1.456 0.378 0.7 0.39 1.206 1.028 1.427 1.798s0.127 1.581-0.263 2.282l-5 9c-0.454 0.818-1.279 1.384-2.206 1.514-0.139 0.019-0.277 0.029-0.416 0.029zM7 12c-0.268 0-0.518 0.104-0.707 0.293s-0.293 0.439-0.293 0.707 0.104 0.518 0.293 0.707l4 4c0.223 0.221 0.523 0.33 0.844 0.283 0.312-0.043 0.586-0.232 0.737-0.504l5-9c0.13-0.233 0.161-0.503 0.088-0.76s-0.243-0.47-0.478-0.6c-0.473-0.264-1.101-0.078-1.357 0.388l-4.357 7.841-3.062-3.062c-0.19-0.189-0.44-0.293-0.708-0.293z"></path>
            </svg>
            <span>Attivo</span>
          </p>
          <p class="wi-text-s wi-text-left wi-flex wi-align-center" v-else>
            <svg class="wi-icon wi-icon-pointer" width="24" height="24" fill="#FA0034" viewBox="0 0 24 24">
              <path d="M16 19c-0.802 0-1.555-0.312-2.122-0.879l-1.878-1.879-1.879 1.879c-1.133 1.133-3.109 1.133-4.243 0-0.566-0.566-0.878-1.32-0.878-2.121s0.312-1.555 0.879-2.122l1.878-1.878-1.878-1.879c-0.567-0.566-0.879-1.32-0.879-2.121s0.312-1.555 0.879-2.122c1.133-1.132 3.109-1.133 4.243 0.001l1.878 1.879 1.879-1.879c1.133-1.133 3.109-1.133 4.243 0 0.566 0.566 0.878 1.32 0.878 2.121s-0.312 1.555-0.879 2.122l-1.878 1.878 1.878 1.879c0.567 0.566 0.879 1.32 0.879 2.121s-0.312 1.555-0.879 2.122c-0.566 0.566-1.319 0.878-2.121 0.878zM12 13.414l3.293 3.293c0.378 0.378 1.037 0.377 1.414 0 0.189-0.189 0.293-0.439 0.293-0.707s-0.104-0.518-0.293-0.707l-3.292-3.293 3.292-3.293c0.189-0.189 0.293-0.44 0.293-0.707s-0.104-0.518-0.293-0.707c-0.378-0.379-1.037-0.378-1.414-0.001l-3.293 3.294-3.293-3.293c-0.378-0.378-1.037-0.377-1.414 0-0.189 0.189-0.293 0.44-0.293 0.707s0.104 0.518 0.293 0.707l3.292 3.293-3.292 3.293c-0.189 0.189-0.293 0.439-0.293 0.707s0.104 0.518 0.293 0.707c0.378 0.379 1.037 0.378 1.414 0.001l3.293-3.294z"></path>
            </svg>
            <span>Non attivo</span>
          </p>
        </div>
        <div class="wi-flex wi-flex-column wi-m-b-2 wi-bb-1 wi-p-b-1">
          <p class="wi-text-xs wi-text-bold wi-text-left wi-m-r-2">Termini e condizioni accettate:</p>
            <p class="wi-text-s wi-text-left">{{ referentDetail.referent && referentDetail.referent?.terms ? 'Si' : 'No' }}</p>
        </div>
        
      </div>
    </wi-modal>

  </app-layout>
</template>

<style lang="scss" scoped></style>
