import { PaginateData } from '@/core/interfaces';
import constants from './constants';
import { Ride } from '@/modules/rides/store/enums';


export interface Customer {
  _id: string
  name: string
  surname: string
  email: string
  phone: string
}

export interface Filters {
  search: string,
  orderBy: string,
}


export interface CustomerState extends PaginateData {
  customerList: Customer[] | null,
  selectedCustomer: Customer | null,
  ridesList: Ride[] | null,
  filters: Filters | null,
}

/**
 * PUBLIC ACTIONS, MUTATIONS AND GETTERS EXPOSED TO THE COMPONENTS
 */
const enums = {
  ACTIONS: {
    FetchCustomerList: 'fetch-customer-list', // Call Api all driver
    SetPage: 'set-customer-page',
    SetSelectedCustomer: 'set-selected-customer',
    SetRidesList: 'set-rides-customer'

  },
  GETTERS: {
    GetCustomerList: 'get-customer-list', // get all driver from state driverList
    GetFilters: 'get-customer-filter',
    GetPage: 'get-customer-page',
    GetSelectedCustomer: 'get-selected-customer',
    GetRidesList: 'get-rides-customer',
  },
  MUTATIONS: {
    UpdateCustomerList: 'update-customer-list', // change driver list in DriverState
    UpdateFilters: 'get-customer-filter',
    UpdatePage: 'update-customer-page',
    UpdateSelectedCustomer: 'update-selected-customer',
    UpdateRidesList: 'update-rides-customer',
  },
};
export default {
  namespaced: {
    ACTIONS: {
      FetchCustomerList: `${constants.MODULE_NAME}/${enums.ACTIONS.FetchCustomerList}`,
      SetPage: `${constants.MODULE_NAME}/${enums.ACTIONS.SetPage}`,
      SetSelectedCustomer: `${constants.MODULE_NAME}/${enums.ACTIONS.SetSelectedCustomer}`,
      SetRidesList: `${constants.MODULE_NAME}/${enums.ACTIONS.SetRidesList}`,
    },
    GETTERS: {
      GetCustomerList: `${constants.MODULE_NAME}/${enums.GETTERS.GetCustomerList}`,
      GetPage: `${constants.MODULE_NAME}/${enums.GETTERS.GetPage}`,
      GetFilters: `${constants.MODULE_NAME}/${enums.GETTERS.GetFilters}`,
      GetSelectedCustomer: `${constants.MODULE_NAME}/${enums.GETTERS.GetSelectedCustomer}`,
      GetRidesList: `${constants.MODULE_NAME}/${enums.GETTERS.GetRidesList}`,
    },
  },
  enums,
};
