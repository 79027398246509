import { Paginate } from '@/core/interfaces';
import vuexDefinitions, { Company, CompanyFilter, CompanyNccState } from '@/modules/companies-ncc/store/enums';

export default {
  [vuexDefinitions.enums.MUTATIONS.UpdateAllCompanies](
    state: CompanyNccState,
    companies: Company[],
  ): void {
    state.companies = companies;
  },
  [vuexDefinitions.enums.MUTATIONS.UpdateSelectedCompany](
    state: CompanyNccState,
    company: Company,
  ): void {
    state.selectedCompany = company;
  },
  [vuexDefinitions.enums.MUTATIONS.UpdateCompanyForm](
    state: CompanyNccState,
    companiesForm: Company,
  ): void {
    state.companiesForm = companiesForm;
  },
  [vuexDefinitions.enums.MUTATIONS.UpdateCompanyFormError](
    state: CompanyNccState,
    errorCompanies: string,
  ): void {
    state.errorCompanies = errorCompanies;
  },
  [vuexDefinitions.enums.MUTATIONS.UpdateCompanyFilter](
    state: CompanyNccState,
    filterCompanies: CompanyFilter,
  ): void {
    state.filters = filterCompanies;
  },
  [vuexDefinitions.enums.MUTATIONS.UpdatePage](
    state: CompanyNccState,
    paginate: Paginate,
  ): void {
    state.paginate = paginate;
  },
};
