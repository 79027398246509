import { PaginateData } from '@/core/interfaces';
import constants from './constants';


export interface Referent {
  _id: string
  name: string
  surname: string
  email: string
  n_phone: number
}

export interface  ReferentFilter {
  search: string,
  orderBy: string,
}

export interface ReferentState extends PaginateData {
  referentList: Referent[] | null,
  filters: ReferentFilter | null,

}

/**
 * PUBLIC ACTIONS, MUTATIONS AND GETTERS EXPOSED TO THE COMPONENTS
 */
const enums = {
  ACTIONS: {
    FetchReferentList: 'fetch-referent-list', // Call Api all driver
    SetPage: 'set-referent-page',
  },
  GETTERS: {
    GetReferentList: 'get-referent-list', // get all driver from state driverList
    GetPaginate: 'get-referent-paginate',
    GetReferentFilter: 'get-referent-filter',
  },
  MUTATIONS: {
    UpdateReferentList: 'update-referent-list', // change driver list in DriverState
    UpdatePaginate: 'update-referent-paginate',
    UpdateReferentFilter: 'update-referent-filter',
  },
};
export default {
  namespaced: {
    ACTIONS: {
      FetchReferentList: `${constants.MODULE_NAME}/${enums.ACTIONS.FetchReferentList}`,
    },
    GETTERS: {
      GetReferentList: `${constants.MODULE_NAME}/${enums.GETTERS.GetReferentList}`,
      GetPaginate: `${constants.MODULE_NAME}/${enums.GETTERS.GetPaginate}`,
      GetReferentFilter: `${constants.MODULE_NAME}/${enums.GETTERS.GetReferentFilter}`,
    },
  },
  enums,
};
