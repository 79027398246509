import vuexDefinitions, { AlertState, Alert } from '@/core/store/alert/enums';
import { ActionContext } from 'vuex';
import store, { RootState } from '@/store';

type VehicleActionContext = ActionContext<AlertState, RootState>

export default {
  [vuexDefinitions.enums.ACTIONS.SetAlert]:
    async (context: VehicleActionContext, alert: Alert): Promise<void> => {
      setTimeout(() => store.dispatch(vuexDefinitions.enums.ACTIONS.AutoHideAlert), 5000);
      context.commit(vuexDefinitions.enums.MUTATIONS.AddAlert, alert);
    },
  [vuexDefinitions.enums.ACTIONS.RemoveAlert]:
    async (context: VehicleActionContext, alert: Alert): Promise<void> => {
      context.commit(vuexDefinitions.enums.MUTATIONS.RemoveAlert, alert);
    },
  [vuexDefinitions.enums.ACTIONS.AutoHideAlert]:
    async (context: VehicleActionContext): Promise<void> => {
      context.commit(vuexDefinitions.enums.MUTATIONS.PopAlert);
    },
};
