import constants from '@/modules/auth/store/constants';

export interface UserCompany {
  bName: string,
  fc: string,
  pIva: string,
  country: string,
  sdi: string,
  lAddress: string,
}

export interface AuthUser {
  _id?: string
  name: string
  surname: string
  email: string
  role: string
  phone?: string
}

export interface AuthState {
  email: string | null,
  firebaseUid: string | null,
  errorProfile: string | null,
  authUser: AuthUser | null,
}

// eslint-disable-next-line no-shadow
export enum AuthRole {
  SUPERADMIN = 'SUPERADMIN',
  COMPANYDRIVERS = 'COMPANYDRIVERS',
  COMPANY = 'COMPANY',
  DRIVER = 'DRIVER',
  CUSTOMER = 'CUSTOMER',
  REFERENT = 'REFERNT'
}

/**
 * PUBLIC ACTIONS, MUTATIONS AND GETTERS EXPOSED TO THE COMPONENTS
 */
const enums = {
  ACTIONS: {
    Login: 'Login',
    Logout: 'Logout',
    ResetPassword: 'ResetPassword',
    SetAuthUser: 'SetAuthUser',
    SetEmail: 'SetEmail',
    SetProfile: 'SetProfile',
    CreateProfile: 'CreateProfile',
    SetToken: 'SetToken',
    ClearToken: 'ClearToken',
  },
  GETTERS: {
    GetEmail: 'GetEmail',
    GetFirebaseUid: 'GetFirebaseUid',
    GetErrorProfile: 'GetErrorProfile',
    GetAuthUser: 'GetAuthUser',
    GetToken: 'GetToken',
  },
  MUTATIONS: {
    UpdateEmail: 'UpdateEmail',
    UpdateProfile: 'UpdateProfile',
    UpdateError: 'UpdateError',
    UpdateToken: 'UpdateToken',
  },
};
export default {
  namespaced: {
    ACTIONS: {
      SetEmail: `${constants.MODULE_NAME}/${enums.ACTIONS.SetEmail}`,
      SetProfile: `${constants.MODULE_NAME}/${enums.ACTIONS.SetProfile}`,
      SetToken: `${constants.MODULE_NAME}/${enums.ACTIONS.SetToken}`,
      ClearToken: `${constants.MODULE_NAME}/${enums.ACTIONS.ClearToken}`,
    },
    GETTERS: {
      GetEmail: `${constants.MODULE_NAME}/${enums.GETTERS.GetEmail}`,
      GetFirebaseUid: `${constants.MODULE_NAME}/${enums.GETTERS.GetFirebaseUid}`,
      GetErrorProfile: `${constants.MODULE_NAME}/${enums.GETTERS.GetErrorProfile}`,
      GetAuthUser: `${constants.MODULE_NAME}/${enums.GETTERS.GetAuthUser}`,
      GetToken: `${constants.MODULE_NAME}/${enums.GETTERS.GetToken}`,
    },
    MUTATIONS: {
      UpdateToken: `${constants.MODULE_NAME}/${enums.MUTATIONS.UpdateToken}`,
      UpdateProfile: `${constants.MODULE_NAME}/${enums.MUTATIONS.UpdateProfile}`,
    },
  },
  enums,
};
