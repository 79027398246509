<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { Customer } from '../store/enums';
import CustomerData from '../components/CustomerData.vue';
import RideList from '../components/RideList.vue';
import UserClient from '@/core/services/user-client';
import { useStore } from 'vuex';
import alertVuexDefinitions, { Alert, AlertType } from '@/core/store/alert/enums';
import { useRoute } from 'vue-router';

const store = useStore();

const customer = ref<Customer>();
const route = useRoute();

const getCustomer = async (id: string) => {
  try {
    const customerSelected = await UserClient.getSingleUser(id);
    if (customerSelected.data.success) {
      customer.value = customerSelected.data.data as Customer;
    } else {
      console.log('driver data not found')
      customer.value = undefined;
    }
  } catch (error) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: "Errore",
      description: error,
      type: AlertType.Error,
    } as Alert);
  }
};

onMounted(async () => {
  await getCustomer(route.params.id as string);
});

const currentTab = ref('profile');

</script>

<template>
  <app-layout :has-back="true">
    <div class="wi-flex wi-flex-column wi-w-100">
      <div class="wi-flex wi-justify-s wi-align-center wi-m-y-2">
        <a @click="currentTab = 'profile'" class="wi-text cursor-pointer wi-m-e-2"
          :class="currentTab === 'profile' ? 'wi-text-bold' : ''">Dettagli Customer</a>
        <a @click="currentTab = 'rides'" class="wi-text cursor-pointer wi-m-e-2"
          :class="currentTab === 'rides' ? 'wi-text-bold' : ''">Lista Corse</a>
      </div>
      <div v-if="currentTab === 'profile'" class="wi-card wi-w-100 wi-m-b-5 wi-p-t-3 wi-p-x-2">
        <customer-data :data="customer" />
      </div>
      <div v-if="currentTab === 'rides'">
        <ride-list :customer="customer" />
      </div>
    <!--<div class="wi-flex wi-flex-column wi-w-100">
      <div  class="wi-card wi-w-100 wi-m-b-5 wi-p-t-3 wi-p-x-2">
        <h1 class="wi-text-left wi-color-black wi-text-bold wi-text-l wi-m-b-5">
        Anagrafica Customers
        </h1>
      </div>
      <div class="wi-card wi-w-100">
        <table-page
          label="Lista Corse"
          :hasAdd="false"
          :orderBy="orderBy"
          @update:search="setRidesList(
          {
            ...getFilters,
            search: $event.target.value,
          },
          getPaginate.page,
        )"
        @update:orderValue="setRidesList(
          {
            ...getFilters,
            orderBy: $event.target.value,
          },
          getPaginate.page,
        )"
        />
        <table-content 
        :columns="columns"
        :paginate="getPaginate"
        :rowsCount="getRidesList.length"
        @set-page="setRidesList(
          getFilters,
          $event
        )"
        >
          <tr
            class="mc-cursor-pointer"
            v-for="item in getRidesList"
            :key="item._id">
            <td class="content">{{item.n_ride}}</td>
            <td class="content">{{item.start_time}}</td>
            <td class="content">{{item.payment_method}}</td>
            <td class="content">€{{item.amount}}</td>
            <td class="content" :class="{
              'wi-text-warn': item.status === 'In Corso',
              'wi-text-verify': item.status === 'Completata',
              'wi-text-error': item.status === 'Cancellata'
            }">{{item.status}}</td>
            <td
              v-if="item.status === 'In Corso'"
              class="content-action wi-flex wi-flex-row wi-justify-center"
            >
              <wi-icon icon="trash"></wi-icon>
            </td>
            <td v-else class="content-action"></td>
          </tr>
        </table-content>
      </div> -->
    </div> 
  </app-layout>
</template>

<style lang="scss" scoped>
.wi-modal-input {
  min-width: 225px;
  margin-top: 4px;
}
</style>
