import vuexDefinitions, { CompanyFilter, CompanyState } from '@/modules/companies/store/enums';

export default {
  [vuexDefinitions.enums.GETTERS.GetAllCompanies]:
    (state: CompanyState) => state.companies,
  [vuexDefinitions.enums.GETTERS.GetSelectedCompany]:
    (state: CompanyState) => state.selectedCompany,
  [vuexDefinitions.enums.GETTERS.GetErrorCompany]:
    (state: CompanyState) => state.errorCompanies,
  [vuexDefinitions.enums.GETTERS.GetCompanyForm]:
    (state: CompanyState) => state.companiesForm,
  [vuexDefinitions.enums.GETTERS.GetCompanyFilters]:
    (state: CompanyState) => state.filters,
  [vuexDefinitions.enums.GETTERS.GetPage]:
    (state: CompanyState) => state.paginate,
  [vuexDefinitions.enums.GETTERS.GetRidesList]:
    (state: CompanyState) => state.ridesList,
};
