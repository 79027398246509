<script lang="ts">
import { reactive } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, sameAs } from '@vuelidate/validators';
import { useRoute } from 'vue-router';

import AppAuthLayout from '../components/AppAuthLayout.vue';
// TODO - Viene importato ed implentato sotto a riga 31
// import AuthClient from '../services/auth-client';

export default {
  components: {
    AppAuthLayout,
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      password: '',
      confirmPassword: '',
      params: route?.query,
    });
    const rules = {
      password: { required },
      confirmPassword: {
        required,
        sameAsPassword: sameAs(state.password),
      },
    };

    /* 
    TODO - Non so se occorre ma crea un errore all'avvio del progetto.
    const resetPassword = (newpass: string) => {
      const auth = getAuth();
      if (state.params.mode === 'resetPassword') {
        // AuthClient.handleResetPassword(auth, state.params.oobCode as string, '/password-resetted', newpass);
      }
    }; 
    
    const v$ = useVuelidate(rules, state);
    return { state, v$, resetPassword }; */
  },
};
</script>

<template>
  <AppAuthLayout>
    <h1 class="wi-text-l wi-text-bold wi-text-left wi-p-b-3">Reset Password</h1>
    <p class="wi-text-m wi-text-bold wi-text-left wi-p-b-1 wi-w-75 wi-color-dark-grey">
    Scegli una nuova password per il tuo account
    </p>
    <h3 class="wi-text-s wi-text-bold wi-text-left wi-p-t-2 wi-p-b-1">Password</h3>
    <label for="newpass" class="wi-text-left wi-w-75">
      <input
      v-model="v$.password.$model"
      type="password"
      :class="{ 'wi-input-error wi-m-b-1': v$.password.$error, 'wi-m-b-2': !v$.password.$error}"
      placeholder="Inserisci la tua nuova password"
      id="newpass"
      class="wi-input wi-w-100">
      <div class="input-errors wi-m-b-1" v-for="error of v$.password.$errors" :key="error.$uid">
        <div class="wi-text-xs">{{ error.$message }}</div>
      </div>
    </label>
    <h3 class="wi-text-s wi-text-bold wi-text-left wi-p-t-2 wi-p-b-1">Conferma password</h3>
    <label for="confirmpass" class="wi-text-left wi-w-75">
      <input
      v-model="v$.confirmPassword.$model"
      type="password"
      placeholder="Conferma la tua nuova password"
      :class="{
        'wi-input-error wi-m-b-1': v$.confirmPassword.$error,
        'wi-m-b-2': !v$.confirmPassword.$error
      }"
      id="confirmpass"
      class="wi-input wi-w-100">
      <div
        class="input-errors wi-m-b-1"
        v-for="error of v$.confirmPassword.$errors"
        :key="error.$uid"
      >
        <div class="wi-text-xs">{{ error.$message }}</div>
      </div>
    </label>
    <h1 class="wi-p-t-1 wi-p-b-2 wi-color-grey wi-text-right wi-w-75">Torna alla home</h1>
    <button
      :disabled="v$.password.$invalid"
      class="wi-button wi-w-75 wi-text-bold"
      @click="resetPassword(v$.confirmPassword.$model)"
    >
      Richiedi una nuova Password
    </button>
  </AppAuthLayout>
</template>
