<script lang="ts" setup>
import { ref, onMounted } from 'vue';
import { Customer } from '../store/enums';
import { useStore } from 'vuex';
import enumsConfig from '@/core/store/config/enums';
import UserClient from "@/core/services/user-client";
import alertVuexDefinitions, { Alert, AlertType } from "@/core/store/alert/enums";

const store = useStore();
const customers = ref<Customer[]>();
const config = ref();
const role = ref("CUSTOMER");
const search = ref("");
const order = ref("asc");
const page = ref(1);
const paginate = ref();

onMounted(() => {
  getAllCustomers(role.value, search.value, order.value, page.value);
  loadConfig();
});

const loadConfig = async () => {
  await store.dispatch(enumsConfig.enums.ACTIONS.SetConfig);
  config.value = store.getters[enumsConfig.enums.GETTERS.GetConfig];
};

const getAllCustomers = async (role: string, search: string, order: string, page: number) => {
  try {
    const customersList = await UserClient.getAllUsers(role, search, order, page);
    if (customersList.data.success) {
      customers.value = customersList.data.data as Customer[];
      paginate.value = customersList.data.paginate;
    } else {
      customers.value = [];
    }
  } catch (error) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: "Errore",
      description: error,
      type: AlertType.Error,
    } as Alert);
  }
};

const updateSearchValue = async (value: any) => {
  search.value = value?.target?.value ?? "";
  getAllCustomers(role.value, search.value, order.value, page.value);
};

const updatePage = async (value: any) => {
  page.value = page.value + value;
  getAllCustomers(role.value, search.value, order.value, page.value);
};

</script>

<template>
  <app-layout>
    <div class="wi-card wi-w-100">

      <table-page
        :has-add="false"
        add-label="Customers"
        :label="config?.modules.customer.label"
        :orderBy="config?.modules.customer.order"
        @update:search="updateSearchValue"
      />
      <table-content
        :columns="config?.modules.customer.columns"
        :paginate="paginate"
        :rows-count="customers?.length"
        :loading="!customers"
        @prev-page="updatePage(1)"
        @next-page="updatePage(-1)"
      >
        <tr
          class="mc-cursor-pointer"
          v-for="item in customers"
          :key="item._id"
          @click="$router.push(`customers/${item._id}`)"
        >
          <td class="content">{{item.name}}</td>
          <td class="content">{{item.surname}}</td>
          <td class="content">{{item.email}}</td>
          <td class="content">{{item.phone}}</td>
        </tr>
      </table-content>
    </div>
  </app-layout>
</template>
