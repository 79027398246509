<template>
  <div class="wi-maps">
    <div :id="mapId" :class="fullHeight ? 'map-full' : 'map'" ref="mapContainer"></div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref, PropType, onUpdated, onUnmounted, watch, computed, defineEmits, defineProps } from "vue";
import { Loader } from "@googlemaps/js-api-loader";
import markerStart from "@/assets/wicon/markerstart.png";
import markerEnd from "@/assets/wicon/marker-y.png";
import markerPosition from "@/assets/wicon/markercar.png";
import markerPerson from "@/assets/wicon/person.png";

const props = defineProps({
  mapId: {
    type: String,
    default: 'map',
  },
  hasRouteLine: {
    type: Boolean,
    default: false,
  },
  hasClick: {
    type: Boolean,
    default: false,
  },
  loaderDriver: {
    type: Boolean,
    default: false,
  },
  center: {
    type: Object,
    default: () => ({ lat: 13.007813, lng: 42.623855 }),
  },
  currentRide: {
    type: Object,
    default: () => ({}),
  },
  origin: {
    type: Object as PropType<{
      lat: number,
      lng: number
    }>,
    default: null,
  },
  driverPosition: {
    type: Object as PropType<{
      lat: number,
      lng: number
    }>,
    default: null,
  },
  fullHeight: {
    type: Boolean,
    default: false,
  },
  destination: {
    type: Object as PropType<{
      lat: number,
      lng: number
    }>,
    default: null,
  }
});

const emits = defineEmits(['update:destination', 'update:origin', 'action:header'])

// states
const status = ref("init");
// eslint-disable-next-line no-undef
const map = ref<google.maps.Map | null>();
const myPosition = ref<any>();
// eslint-disable-next-line no-undef
const markerA = ref(null);
const markerB = ref(null);
const markerC = ref(null);
const markerL = ref(null);

const init = () => {
  status.value = "loading";
  status.value = "done";
};

// Definizione delle immagini dei marker personalizzati
const markerAIcon = {
  url: markerStart,
  // eslint-disable-next-line no-undef
  scaledSize: new google.maps.Size(25, 25),
};

const markerBIcon = {
  url: markerEnd,
  // eslint-disable-next-line no-undef
  scaledSize: new google.maps.Size(25, 25),
};

const markerCIcon = {
  url: markerPosition,
  // eslint-disable-next-line no-undef
  scaledSize: new google.maps.Size(30, 54.97),
};

const markerPIcon = {
  url: markerPerson,
  // eslint-disable-next-line no-undef
  scaledSize: new google.maps.Size(30, 30),
};

const mapContainer = ref<HTMLElement | null>(null);


const handleScroll = (event: Event) => {
  if (mapContainer.value) {
    const scrollTop = mapContainer.value.scrollTop;
    const scrollHeight = mapContainer.value.scrollHeight;
    const clientHeight = mapContainer.value.clientHeight;

    if (scrollTop === 0) {
      // Blocca lo scrolling verso l'alto quando si raggiunge il limite superiore.
      event.preventDefault();
    } else if (scrollTop === scrollHeight - clientHeight) {
      // Blocca lo scrolling verso il basso quando si raggiunge il limite inferiore.
      event.preventDefault();

    }
  }
};

onMounted(async () => {
  init();
  if (mapContainer.value) {
    mapContainer.value.addEventListener('scroll', handleScroll, { passive: false });
  }
  createMapIstance();
  handleCenterChange(props.center);
});

onUpdated(async () => {
  init();
  if (mapContainer.value) {
    mapContainer.value.addEventListener('scroll', handleScroll, { passive: false });
  }
  createMapIstance();
  handleCenterChange(props.center);
});

const makeMarker = (position: any, icon: any, title: string, map: any) => {
  // eslint-disable-next-line no-undef
  return new google.maps.Marker({
    position: position,
    map: map,
    icon: icon,
    title: title
  });
}

const createMapIstance = async () => {
  const loader = new Loader({
    apiKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY || '', // Sostituisci con la tua chiave API di Google Maps
    version: 'weekly',
  });
  await loader.load()
  // eslint-disable-next-line no-undef
  map.value = new google.maps.Map(
    document.getElementById(props.mapId) as Element,
    {
      center: props.center,
      zoom: 13,
      mapTypeControl: false,
      streetViewControl: false,
      zoomControl: false,
      fullscreenControl: false,
    });
  myPosition.value = makeMarker(props.center, markerPIcon, "title", map.value);
  if (props.hasClick) {
    map.value.addListener('click', (event: any) => {
      emits('update:destination', {
        formatted_address: 'Destinazione',
        geometry: {
          location: {
            lat: event.latLng.lat(),
            lng: event.latLng.lng()
          }
        }
      })
    })
  }
  // eslint-disable-next-line no-undef
  const directionsRenderer = new google.maps.DirectionsRenderer();
  // eslint-disable-next-line no-undef
  const directionsService = new google.maps.DirectionsService();
  if (props.hasRouteLine) {
    // eslint-disable-next-line no-undef
    // Se directionsRenderer non esiste, crealo
    directionsRenderer.setMap(map.value);
    const request = {
      origin: props.origin,
      destination: props.destination,
      // eslint-disable-next-line no-undef
      travelMode: google.maps.TravelMode.DRIVING,
    };
    console.log('request', request);
    directionsService.route(request, (result, status) => {
      const polylineOptions = {
        strokeColor: '#FBE343', // Sostituisci con il colore desiderato in formato esadecimale o nome colore
        strokeOpacity: 1,
        strokeWeight: 5,
      };

      // Imposta le opzioni della linea con il colore personalizzato
      directionsRenderer?.setOptions({
        polylineOptions,
        suppressMarkers: true,
      });
      // eslint-disable-next-line no-undef
      if (status === google.maps.DirectionsStatus.OK) {

        directionsRenderer?.setDirections(result);
        markerA.value = makeMarker(props.origin, markerAIcon, "title", map.value);
      } else {
        console.error('Impossibile creare il percorso. Errore:', status);
      }
    });
  }
  markerL.value = null
  markerB.value = makeMarker(props.destination, markerBIcon, 'title', map.value);
  if (!markerC.value) {
    //markerC.value?.setPosition( {lat: props.driverPosition.lat, lng: props.driverPosition.lng});
    markerC.value = makeMarker(props.driverPosition, markerCIcon, 'title', map.value);
  }
};

onUnmounted(() => {
  if (mapContainer.value) {
    mapContainer.value.removeEventListener('scroll', handleScroll);
  }
});


// Funzione per gestire il cambio della prop center
const handleCenterChange = (newCenter: any) => {
  console.log('combiamento centro app', newCenter);
  if (map.value) {
    map.value.setCenter(newCenter);
  }
};


watch(() => props.loaderDriver, async (loader) => {
  console.log('combiamento centro app', loader);
  if (!loader) {
    markerL.value = null;
    markerB.value = makeMarker(props.destination, markerBIcon, 'title', map.value);
  } else {
    markerB.value = null;
  }
});

watch(() => props.driverPosition, async (position) => {
  if (position && markerC.value) {
    markerC.value.setPosition({ lat: position?.lat, lng: position?.lng });
  }
  markerL.value = null;
});

</script>

<style scoped lang="scss">
.map {
  height: 100%;
  width: 100%;
  position: relative;
}

.map-full {
  height: 100vh;
  width: 100%;
}

.btn-current-button {
  position: absolute;
  bottom: 20px;
  right: 36px;
  background-color: white;
  border-radius: 100%;
  height: 31px;
  width: 31px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.wi-maps {
  position: relative;
  width: 100%;
  min-height: 250px;
  &-header {

    ion-icon {
      font-size: 30px;
    }
  }
}
</style>