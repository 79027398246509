import { IResponse } from "@/core/interfaces";
import { Member } from "..";
import axios from "axios";

const getAllMember = async (search?: string, order?: string, page?: number): Promise<IResponse> =>
  axios({
    url: `${process.env.VUE_APP_API}/members`,
    params: {
      search,
      order,
      page,
    },
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}` ?? "",
    },
  }).catch((e) => e.toJSON());

const addMember = async (data: Member): Promise<IResponse> =>
  axios({
    url: `${process.env.VUE_APP_API}/members`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}` ?? "",
    },
  }).catch((e) => e.toJSON());

const deleteMember = async (id: string): Promise<IResponse> =>
  axios({
    url: `${process.env.VUE_APP_API}/members/${id}`,
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}` ?? "",
    },
  }).catch((e) => e.toJSON());

const MemberClient = {
  getAllMember,
  addMember,
  deleteMember,
};
Object.freeze(MemberClient);
export default MemberClient;
