<template>
    <div class="wi-flex wi-flex-column">
        <div class="wi-pin-code" @click="pincodeElement.focus()" >
          <PinCodeItems :focused="pincode?.length == 0" :value="pincode[0]" />
          <PinCodeItems :focused="pincode?.length == 1" :value="pincode[1]" />
          <PinCodeItems :focused="pincode?.length == 2" :value="pincode[2]" />
          <PinCodeItems :focused="pincode?.length == 3" :value="pincode[3]" />
          <PinCodeItems :focused="pincode?.length == 4" :value="pincode[4]" />
          <PinCodeItems :focused="pincode?.length == 5" :value="pincode[5]" />
        </div>
        <p class="wi-text-xs wi-color-secondary wi-m-y-1 wi-text-left">Non ho ricevuto il codice. <span class="wi-text-extra-bold wi-color-black wi-text-italic" @click="emit('action:resendCode')">Rivia il codice di verifica</span></p>
        <input inputmode="numeric" style="opacity: 0; height: 0;" ref="pincodeElement" v-model="pincode" maxlength="6" />
    </div>
</template>

<script lang="ts" setup>
import { ref, watch, defineProps, defineEmits } from 'vue';
import PinCodeItems from './PinCodeItems.vue';

const pincode = ref('');
const pincodeElement = ref();
const focused = ref(true);
const emit = defineEmits(['update:modelValue', 'pin-code-input', 'action:resendCode']);

const props = defineProps({
  modelValue: {
    type: String,
    required: false,
    default: '',
  },
});

watch(pincode, (newValue, oldValue) => {
  emit('update:modelValue', newValue);
  if(newValue.length == 6) {
    emit('pin-code-input', pincode.value);
  }
});

</script>

<style lang="scss" scoped>

input {
  position: absolute;
  width: 0;
}
</style>