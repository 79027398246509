import { IResponse } from "@/core/interfaces";
import { Referent } from "../index.d";
import axios from "axios";

const getAllReferent = async (search?: string, order?: string, page?: number): Promise<IResponse> =>
  axios({
    url: `${process.env.VUE_APP_API}/referents`,
    params: {
      search,
      order,
      page,
    },
    method: "get",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}` ?? "",
    },
  });

const addReferent = async (data: Referent | null): Promise<IResponse> =>
  axios({
    url: `${process.env.VUE_APP_API}/referents`,
    method: "post",
    data,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}` ?? "",
    },
  });

const deleteReferent = async (id: string): Promise<IResponse> =>
  axios({
    url: `${process.env.VUE_APP_API}/referents/${id}`,
    method: "delete",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${sessionStorage.getItem("token")}` ?? "",
    },
  });

const ReferentClient = {
  getAllReferent,
  addReferent,
  deleteReferent,
};
Object.freeze(ReferentClient);
export default ReferentClient;
