<script lang="ts">
export default {
};
</script>

<template>
  <div class="wi-bg-light wi-h-100vh">
    <div class="wi-flex wi-flex-row mc-flex-l-columnreverse mc-flex-l-wrap wi-bg-light">
      <div class="
        wi-w-50
        mc-w-l-100
        wi-flex
        wi-flex-column
        wi-align-center
        wi-justify-center
        wi-m-y-2
      ">
        <slot />
        <p class="wi-m-t-4">@copyright FraCap S.a.s p.iva 04508660612, Via San N. la strada (CE) 81030 Caserta</p>
      </div>
      <div class="wi-w-50 mc-w-l-100 wi-flex wi-h-100vh mc-h-l-50vh wi-image-login">
        <div class="wi-login-logo"></div>
      </div>
  </div>
  </div>
</template>

<style lang="scss" scoped>
.wi-image-login {
  background-image: url('@/assets/WC-01.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
}

.wi-login-logo {
  position: absolute;
  top: 33px;
  right: 22px;
  background-image: url('@/assets/logo-white.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 176px;
  height: 56px;
}
</style>
