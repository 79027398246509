import vuexDefinitions, { AuthState, AuthUser } from '@/modules/auth/store/enums';
import alertVuexDefinitions, { Alert, AlertType } from '@/core/store/alert/enums';
import { ActionContext } from 'vuex';
import store, { RootState } from '@/store';
import router from '@/router';
import AuthClient from '../services/auth-client';

type AuthActionContext = ActionContext<AuthState, RootState>

export default {
  [vuexDefinitions.enums.ACTIONS.SetToken]: (context: AuthActionContext, token: string): void => {
    context.commit(vuexDefinitions.enums.MUTATIONS.UpdateToken, token);
  },
  [vuexDefinitions.enums.ACTIONS.ClearToken]: (context: AuthActionContext): void => {
    context.commit(vuexDefinitions.enums.MUTATIONS.UpdateToken, null);
  },
  [vuexDefinitions.enums.ACTIONS.SetEmail]: (context: AuthActionContext, email: string): void => {
    context.commit(vuexDefinitions.enums.MUTATIONS.UpdateEmail, email);
  },
  [vuexDefinitions.enums.ACTIONS.Logout]:
    async (context: AuthActionContext) => {
      await AuthClient.logout();
      sessionStorage.setItem('token', '');
      router.replace('/login');
    },
  [vuexDefinitions.enums.ACTIONS.ResetPassword]:
    async (context: AuthActionContext, email: string) => {
      try {
        const result = await AuthClient.sendResetPasswordCode(email);
        context.dispatch(vuexDefinitions.enums.ACTIONS.SetEmail, email);
        if(result.data.success) {
          store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
            title: 'Email Inviata con successo',
            description: 'Troverai un codice di verifica nell\'email appena ricevuta',
            type: AlertType.Success,
          } as Alert);
          router.replace('/email-send');
        } else {
          store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
            title: 'Errore Email',
            description: result.data.data,
            type: AlertType.Error,
          } as Alert);
        }
      } catch(error) {
        store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
          title: 'Errore Reset Password',
          description: 'Email non presente o non corretta',
          type: AlertType.Error,
        } as Alert);
      }
    },
  [vuexDefinitions.enums.ACTIONS.SetProfile]:
    (context: AuthActionContext, authUser: AuthUser): void => {
      context.commit(vuexDefinitions.enums.MUTATIONS.UpdateProfile, authUser);
    },
};
