<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import authEnums from '@/modules/auth/store/enums';
import companyEnums from '@/modules/companies/store/enums';
import { mapActions, mapGetters } from 'vuex';

import { Company } from '../store/enums';
import store from '../store/store';

export default defineComponent({
  name: 'CompaniesForm',
  setup() {
    const formData = reactive({
      name: '',
      surname: '',
      email: '',
      company: {
        bName: '',
        fc: '',
        pIva: '',
        pec: '',
        country: '',
        sdi: '',
        lAddress: '',
      },
      phone: '',
      terms: false,
    });

    const rules = {
      name: { required },
      surname: { required },
      email: { required, email },
      company: {
        bName: { required },
        fc: { required },
        pIva: { required },
        pec: { required },
        country: { required },
        sdi: { required },
        lAddress: { required },
      },
      phone: { required },
      terms: false,
    };

    const v$ = useVuelidate(rules, formData);
    return { formData, v$ };
  },
  computed: {
    ...mapGetters({
      companies: companyEnums.enums.GETTERS.GetAllCompanies,
      companyForm: companyEnums.enums.GETTERS.GetCompanyForm,
      companyFilter: companyEnums.enums.GETTERS.GetCompanyFilters
    }),
    name: {
      get(): string { return this.formData.name; },
      set(name: string) {
        this.v$.name.$touch();
        this.formData.name = name;
        this.setCompanyForm({ name });
      },
    },
    surname: {
      get(): string { return this.formData.surname; },
      set(surname: string) {
        this.v$.surname.$touch();
        this.formData.surname = surname;
        this.setCompanyForm({ surname });
      },
    },
    email: {
      get(): string { return this.formData.email; },
      set(newEmail: string) {
        this.v$.email.$touch();
        this.formData.email = newEmail;
        this.setCompanyForm({ email: newEmail });
      },
    },
    phone: {
      get(): string { return this.formData.phone; },
      set(phone: string) {
        this.v$.phone.$touch();
        this.formData.phone = phone;
        this.setCompanyForm({ phone });
      },
    },
    bName: {
      get(): string { return this.formData.company.bName; },
      set(bName: string) {
        this.v$.company.bName.$touch();
        this.formData.company.bName = bName;
        this.setCompanyForm({
          company: {
            ...this.formData.company,
            bName,
          },
        });
      },
    },
    fc: {
      get(): string { return this.formData.company.fc; },
      set(fc: string) {
        this.v$.company.fc.$touch();
        this.formData.company.fc = fc;
        this.setCompanyForm({
          company: {
            ...this.formData.company,
            fc,
          },
        });
      },
    },
    pIva: {
      get(): string { return this.formData.company.pIva; },
      set(pIva: string) {
        this.v$.company.pIva.$touch();
        this.formData.company.pIva = pIva;
        this.setCompanyForm({
          company: {
            ...this.formData.company,
            pIva,
          },
        });
      },
    },
    pec: {
      get(): string { return this.formData.company.pec; },
      set(pec: string) {
        this.v$.company.pec.$touch();
        this.formData.company.pec = pec;
        this.setCompanyForm({
          company: {
            ...this.formData.company,
            pec,
          },
        });
      },
    },
    country: {
      get(): string { return this.formData.company.country; },
      set(country: string) {
        this.v$.company.country.$touch();
        this.formData.company.country = country;
        this.setCompanyForm({
          company: {
            ...this.formData.company,
            country,
          },
        });
      },
    },
    sdi: {
      get(): string { return this.formData.company.sdi; },
      set(sdi: string) {
        this.v$.company.sdi.$touch();
        this.formData.company.sdi = sdi;
        this.setCompanyForm({
          company: {
            ...this.formData.company,
            sdi,
          },
        });
      },
    },
    lAddress: {
      get(): string { return this.formData.company.lAddress; },
      set(lAddress: string) {
        this.v$.company.lAddress.$touch();
        this.formData.company.lAddress = lAddress;
        this.setCompanyForm({
          company: {
            ...this.formData.company,
            lAddress,
          },
        });
      },
    },
  },
  methods: {
    ...mapActions({
      login: authEnums.enums.ACTIONS.CreateProfile,
      setCompaniesList: companyEnums.enums.ACTIONS.SetAllCompanies,
      setPostCompany: companyEnums.enums.ACTIONS.SetPostCompany,
      setCompanyForm: companyEnums.enums.ACTIONS.SetCompanyForm,
    }),
    submitForm(): void {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.setPostCompany();
      }
    },
  },
});
</script>
<template>
  <form @submit.prevent="submitForm">
    <p class="wi-text-left wi-m-b-4">Compila i campi per aggiungere una company</p>
    <div class="row wi-justify-between">
      <label for="name" class="wi-text-xs wi-text-left wi-text-bold wi-w-44 mc-w-l-100">
        Nome
        <input
          v-model="name"
          type="text"
          placeholder="Inserisci il tuo nome"
          id="name"
          :class="{ 'wi-input-error wi-m-b-1': v$.name.$error, 'wi-m-b-2': !v$.name.$error}"
          class="wi-input wi-w-100 wi-m-t-1"
        >
          <div class="input-errors wi-m-b-1" v-for="error of v$.name.$errors" :key="error.$uid">
            <div class="wi-text-xs">{{ error.$message }}</div>
          </div>
      </label>
      <label for="surname" class="wi-text-xs wi-text-left wi-text-bold wi-w-44 mc-w-l-100">
        Congnome
        <input
          v-model="surname"
          type="text"
          placeholder="Inserisci il tuo congnome"
          id="surname"
          :class="{ 'wi-input-error wi-m-b-1': v$.surname.$error, 'wi-m-b-2': !v$.surname.$error}"
          class="wi-input wi-w-100 wi-m-t-1"
        >
          <div class="input-errors wi-m-b-1" v-for="error of v$.surname.$errors" :key="error.$uid">
            <div class="wi-text-xs">{{ error.$message }}</div>
          </div>
      </label>
      <label for="phone" class="wi-text-xs wi-text-left wi-text-bold wi-w-44 mc-w-l-100">
        Telefono
        <input
          v-model="phone"
          type="text"
          placeholder="Inserisci il tuo numero di telefono"
          id="phone"
          :class="{ 'wi-input-error wi-m-b-1': v$.phone.$error, 'wi-m-b-2': !v$.phone.$error}"
          class="wi-input wi-w-100 wi-m-t-1"
        >
          <div class="input-errors wi-m-b-1" v-for="error of v$.phone.$errors" :key="error.$uid">
            <div class="wi-text-xs">{{ error.$message }}</div>
          </div>
      </label>
      <label for="email" class="wi-text-xs wi-text-left wi-text-bold wi-w-44 mc-w-l-100">
        Email
        <input
          v-model="email"
          type="text"
          placeholder="Inserisci la tua email"
          id="email"
          :class="{ 'wi-input-error wi-m-b-1': v$.email.$error, 'wi-m-b-2': !v$.email.$error}"
          class="wi-input wi-w-100 wi-m-t-1"
        >
          <div class="input-errors wi-m-b-1" v-for="error of v$.email.$errors" :key="error.$uid">
            <div class="wi-text-xs">{{ error.$message }}</div>
          </div>
      </label>
      <label for="bName" class="wi-text-xs wi-text-left wi-text-bold wi-w-44 mc-w-l-100">
        Ragione Sociale
        <input
          v-model="bName"
          type="text"
          placeholder="Inserisci la tua ragione sociale"
          id="bName"
          :class="{
            'wi-input-error wi-m-b-1': v$.company.bName.$error,
            'wi-m-b-2': !v$.company.bName.$error
          }"
          class="wi-input wi-w-100 wi-m-t-1"
        >
          <div
            class="input-errors wi-m-b-1"
            v-for="error of v$.company.bName.$errors"
            :key="error.$uid"
          >
            <div class="wi-text-xs">{{ error.$message }}</div>
          </div>
      </label>
      <label for="fc" class="wi-text-xs wi-text-left wi-text-bold wi-w-44 mc-w-l-100">
        Codice Fiscale
        <input
          v-model="fc"
          type="text"
          placeholder="Inserisci il tuo codice fiscale"
          id="fc"
          :class="{
            'wi-input-error wi-m-b-1': v$.company.fc.$error,
            'wi-m-b-2': !v$.company.fc.$error
          }"
          class="wi-input wi-w-100 wi-m-t-1"
        >
          <div
            class="input-errors wi-m-b-1"
            v-for="error of v$.company.fc.$errors"
            :key="error.$uid"
          >
            <div class="wi-text-xs">{{ error.$message }}</div>
          </div>
      </label>
      <label for="sdi" class="wi-text-xs wi-text-left wi-text-bold wi-w-44 mc-w-l-100">
        SDI
        <input
          v-model="sdi"
          type="text"
          placeholder="Inserisci il tuo sdi"
          id="sdi"
          :class="{
            'wi-input-error wi-m-b-1': v$.company.sdi.$error,
            'wi-m-b-2': !v$.company.sdi.$error
          }"
          class="wi-input wi-w-100 wi-m-t-1"
        >
          <div
            class="input-errors wi-m-b-1"
            v-for="error of v$.company.sdi.$errors"
            :key="error.$uid"
          >
            <div class="wi-text-xs">{{ error.$message }}</div>
          </div>
      </label>
      <label for="pec" class="wi-text-xs wi-text-left wi-text-bold wi-w-44 mc-w-l-100">
        PEC
        <input
          v-model="pec"
          type="text"
          placeholder="Inserisci indirizzo pec"
          id="pec"
          :class="{
            'wi-input-error wi-m-b-1': v$.company.pec.$error,
            'wi-m-b-2': !v$.company.pec.$error
          }"
          class="wi-input wi-w-100 wi-m-t-1"
        >
          <div
            class="input-errors wi-m-b-1"
            v-for="error of v$.company.pec.$errors"
            :key="error.$uid"
          >
            <div class="wi-text-xs">{{ error.$message }}</div>
          </div>
      </label>
      <label for="country" class="wi-text-xs wi-text-left wi-text-bold wi-w-44 mc-w-l-100">
        Paese
        <input
          v-model="country"
          type="text"
          placeholder="Inserisci il tuo paese"
          id="country"
          :class="{
            'wi-input-error wi-m-b-1': v$.company.country.$error,
            'wi-m-b-2': !v$.company.country.$error
          }"
          class="wi-input wi-w-100 wi-m-t-1"
        >
          <div
            class="input-errors wi-m-b-1"
            v-for="error of v$.company.country.$errors"
            :key="error.$uid"
          >
            <div class="wi-text-xs">{{ error.$message }}</div>
          </div>
      </label>
      <label for="pIva" class="wi-text-xs wi-text-left wi-text-bold wi-w-44 mc-w-l-100">
        Partita Iva
        <input
          v-model="pIva"
          type="text"
          placeholder="Inserisci la tua partita iva"
          id="pIva"
          :class="{
            'wi-input-error wi-m-b-1': v$.company.pIva.$error,
            'wi-m-b-2': !v$.company.pIva.$error
          }"
          class="wi-input wi-w-100 wi-m-t-1"
        >
          <div
            class="input-errors wi-m-b-1"
            v-for="error of v$.company.fc.$errors"
            :key="error.$uid"
          >
            <div class="wi-text-xs">{{ error.$message }}</div>
          </div>
      </label>
      <label for="lAddress" class="wi-text-xs wi-text-left wi-text-bold wi-w-44 mc-w-l-100">
        Sede Legale
        <input
          v-model="lAddress"
          type="text"
          placeholder="Inserisci indirizzo sede legale"
          id="lAddress"
          :class="{
            'wi-input-error wi-m-b-1': v$.company.lAddress.$error,
            'wi-m-b-2': !v$.company.lAddress.$error
          }"
          class="wi-input wi-w-100 wi-m-t-1"
        >
          <div
            class="input-errors wi-m-b-1"
            v-for="error of v$.company.lAddress.$errors"
            :key="error.$uid"
          >
            <div class="wi-text-xs">{{ error.$message }}</div>
          </div>
      </label>
    </div>
  </form>
</template>

<style lang="scss" scoped>

</style>
