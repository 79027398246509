import vuexDefinitions, { Filters, CustomerState} from '@/modules/customers/store/enums';

export default {
  [vuexDefinitions.enums.GETTERS.GetCustomerList]:
    (state: CustomerState) => state.customerList,
  [vuexDefinitions.enums.GETTERS.GetFilters]:
    (state: CustomerState) => state.filters,
  [vuexDefinitions.enums.GETTERS.GetPage]:
    (state: CustomerState) => state.paginate,
  [vuexDefinitions.enums.GETTERS.GetSelectedCustomer]:
    (state: CustomerState) => state.selectedCustomer,
  [vuexDefinitions.enums.GETTERS.GetRidesList]:
    (state: CustomerState) => state.ridesList,


};
