import { IResponse } from '@/core/interfaces';
import axios from 'axios';
import { Vehicle, VehicleForm } from '../store/enums';

// CALL API FOR ALL VEHICLE
const getAllVehicles = async (params?: any) : Promise<IResponse> => axios({
  url: `${process.env.VUE_APP_API}/vehicles`,
  params,
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}` ?? '',
  },
}).catch((e) => e.toJSON());


// CALL API FOR ALL BRANDS
const getAllBrands = async (brand: string) : Promise<IResponse> => axios({
  url: `${process.env.VUE_APP_API}/brands`,
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}` ?? '',
  },
  params: {
    brand
  }
}).catch((e) => e.toJSON());

// CALL API ALL MODELS
const getAllModels = async (brand: string) : Promise<IResponse> => axios({
  url: `${process.env.VUE_APP_API}/vehicles/brands`,
  method: 'get',
  params: {
    brand,
  },
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}` ?? '',
  },
}).catch((e) => e.toJSON());

// CALL API FOR CREATE VEHICLE
const createVehicle = async (vehicle: VehicleForm) : Promise<IResponse> => axios({
  url: `${process.env.VUE_APP_API}/vehicles`,
  method: 'post',
  data: {
    ...vehicle,
  },
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}` ?? '',
  },
}).catch((e) => e.toJSON());

// CALL API FOR DELETE VEHICLE
const deleteVehicle = async (id: string) : Promise<IResponse> => axios({
  url: `${process.env.VUE_APP_API}/vehicles/${id}`,
  method: 'delete',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}` ?? '',
  },
}).catch((e) => e.toJSON());

// CALL API FOR UPDATE VEHICLE FORM
const updateVehicle = async (id: string,  data: VehicleForm | null) : Promise<IResponse> => axios({
  url: `${process.env.VUE_APP_API}/vehicles/${id}`,
  method: 'patch',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}` ?? '',
  },
  data
}).catch((e) => e.toJSON());

const toggleEnabled = async (id: string) : Promise<IResponse> => axios({
  url: `${process.env.VUE_APP_API}/vehicles/toggle-enabled/${id}`,
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}` ?? '',
  },
}).catch((e) => e.toJSON());

const VehicleClient = {
  getAllVehicles,
  getAllBrands,
  getAllModels,
  createVehicle,
  deleteVehicle,
  updateVehicle,
  toggleEnabled
};
  // Disallow new properties on our object
Object.freeze(VehicleClient);
export default VehicleClient;
