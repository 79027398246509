import vuexDefinitions, {
  Alert, AlertState,
} from '@/core/store/alert/enums';
import { nextTick } from 'vue';

export default {
  [vuexDefinitions.enums.MUTATIONS.RemoveAlert](
    state: AlertState,
    alert: Alert,
  ): void {
    const removedElement = state.alerts?.filter((el: Alert) => el.id !== alert.id);
    state.alerts = removedElement;
  },
  async [vuexDefinitions.enums.MUTATIONS.AddAlert](
    state: AlertState,
    alert: Alert,
  ): Promise<void> {
    state.alerts?.push({
      ...alert,
      id: state.alerts.length + 1,
    });
    await nextTick();
    const scrollSection = document.getElementById('AlertContent');
    scrollSection?.scrollTo(0, scrollSection?.scrollHeight);
  },
  [vuexDefinitions.enums.MUTATIONS.PopAlert](
    state: AlertState,
  ): void {
    state.alerts.pop();
  },
};
