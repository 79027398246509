<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    name: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
  },
});
</script>

<template>
  <div class="dropdown">
    <div class="dropbtn wi-flex">
      <wi-icon v-if="icon" :icon="icon" width="16px" height="16px" class="wi-m-e-1"></wi-icon>
      <div class="wi-flex wi-text-bold wi-align-center">{{ name }}</div>
    </div>
    <div class="dropdown-content">
      <slot />
    </div>
  </div>
</template>

<style>
/* Style The Dropdown Button */
.dropbtn {
  font-size: 16px;
  border: none;
  cursor: pointer;
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  right: 0;
  background-color: #f8f7f7;
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {
  background-color: #f1f1f1;
}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown-footer {
  border-top: 1px solid #ddd;
}
</style>
