export interface OrderOptios {
  name: string;
  value: string;
}

export interface IResponse {
    data: {
      success: string
      message: string | Array<string>
      errorMessage?: string
      data: object
      paginate: Paginate
    },
}

export enum OrderBy {
  DESC = 'desc',
  ASC = 'asc'
}

export interface Paginate {
  hasNextPage: boolean,
  hasPreviousPage: boolean
  itemCount: number
  pageCount: number
  page: string
}
export interface PaginateData {
  paginate: Paginate | null,
}

export interface RangeDateFilter {
  start: string,
  end: string
}
