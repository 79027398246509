<script lang="ts">
import enums from "@/core/store/alert/enums";
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "AppAlert",
  computed: {
    ...mapGetters({
      getAlerts: enums.enums.GETTERS.GetAlerts,
    }),
  },
  methods: {
    ...mapActions({
      removeAlerts: enums.enums.ACTIONS.RemoveAlert,
      autoHideAlert: enums.enums.ACTIONS.AutoHideAlert,
    }),
  },
});
</script>

<template>
  <div data-test="alert-content" class="wi-alerts-content" :class="{ 'wi-alerts-content-show': getAlerts.length > 0 }" :style="'height:' + ((getAlerts.length + 1) * 80).toString() + 'px;' " id="AlertContent">
    <div class="wi-alerts">
      <div data-test="alert-item" class="wi-alert wi-m-b-1 animate bounce_css" v-for="alert in getAlerts" :key="alert.title" :class="`wi-alert-${alert.type}`">
        <button data-test="alert-content-delete" class="btn-none" style="margin-top: -12px" @click="removeAlerts(alert)">
          <i class="bi bi-x wi-text-xl wi-color-black"></i>
        </button>

        <div class="wi-flex wi-flex-row wi-justify-start">
          <div class="wi-flex wi-flex-column wi-justify-center" v-if="alert.type === 'error'">
            <svg class="wi-icon wi-icon-pointer wi-m-e-1" width="32" height="32" fill="#FA0034" viewBox="0 0 24 24">
              <path d="M16 19c-0.802 0-1.555-0.312-2.122-0.879l-1.878-1.879-1.879 1.879c-1.133 1.133-3.109 1.133-4.243 0-0.566-0.566-0.878-1.32-0.878-2.121s0.312-1.555 0.879-2.122l1.878-1.878-1.878-1.879c-0.567-0.566-0.879-1.32-0.879-2.121s0.312-1.555 0.879-2.122c1.133-1.132 3.109-1.133 4.243 0.001l1.878 1.879 1.879-1.879c1.133-1.133 3.109-1.133 4.243 0 0.566 0.566 0.878 1.32 0.878 2.121s-0.312 1.555-0.879 2.122l-1.878 1.878 1.878 1.879c0.567 0.566 0.879 1.32 0.879 2.121s-0.312 1.555-0.879 2.122c-0.566 0.566-1.319 0.878-2.121 0.878zM12 13.414l3.293 3.293c0.378 0.378 1.037 0.377 1.414 0 0.189-0.189 0.293-0.439 0.293-0.707s-0.104-0.518-0.293-0.707l-3.292-3.293 3.292-3.293c0.189-0.189 0.293-0.44 0.293-0.707s-0.104-0.518-0.293-0.707c-0.378-0.379-1.037-0.378-1.414-0.001l-3.293 3.294-3.293-3.293c-0.378-0.378-1.037-0.377-1.414 0-0.189 0.189-0.293 0.44-0.293 0.707s0.104 0.518 0.293 0.707l3.292 3.293-3.292 3.293c-0.189 0.189-0.293 0.439-0.293 0.707s0.104 0.518 0.293 0.707c0.378 0.379 1.037 0.378 1.414 0.001l3.293-3.294z"></path>
            </svg>
          </div>
          <div class="wi-flex wi-flex-column wi-justify-center" v-if="alert.type === 'success'">
            <svg class="wi-icon wi-icon-pointer wi-m-e-1" width="32" height="32" fill="#3D9970" viewBox="0 0 24 24">
              <path d="M11 20c-0.801 0-1.555-0.312-2.121-0.879l-4-4c-0.567-0.566-0.879-1.32-0.879-2.121s0.312-1.555 0.879-2.122c1.133-1.133 3.109-1.133 4.242 0l1.188 1.188 3.069-5.523c0.526-0.952 1.533-1.544 2.624-1.544 0.507 0 1.012 0.131 1.456 0.378 0.7 0.39 1.206 1.028 1.427 1.798s0.127 1.581-0.263 2.282l-5 9c-0.454 0.818-1.279 1.384-2.206 1.514-0.139 0.019-0.277 0.029-0.416 0.029zM7 12c-0.268 0-0.518 0.104-0.707 0.293s-0.293 0.439-0.293 0.707 0.104 0.518 0.293 0.707l4 4c0.223 0.221 0.523 0.33 0.844 0.283 0.312-0.043 0.586-0.232 0.737-0.504l5-9c0.13-0.233 0.161-0.503 0.088-0.76s-0.243-0.47-0.478-0.6c-0.473-0.264-1.101-0.078-1.357 0.388l-4.357 7.841-3.062-3.062c-0.19-0.189-0.44-0.293-0.708-0.293z"></path>
            </svg>
          </div>
          <div class="wi-flex wi-flex-column wi-justify-between wi-m-s-2">
            <h1 class="wi-m-b-1 wi-text-s wi-text-bold">{{ alert.title }}</h1>
            <p data-test="alert-description">{{ alert.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "@/assets/scss/variables.scss";
.wi-alerts {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &-content {
    position: fixed;
    right: 16px;
    bottom: 90px;
    overflow-y: scroll;
    width: 400px;
    z-index: -10000;

    &-show {
      z-index: 10000;
    }
    
    display: flex;
    justify-content: flex-end;
  }
}

.wi-alert {
  background-color: $white-color;
  box-shadow: 0 8px 16px rgba(0,0,0,.15);
  padding: 16px 32px 16px 8px;
  border-radius: 4px;
  border-left: 4px solid;
  color: $primary-color;
  transition: width 4s, height 0s;
  text-align: left;
  position: relative;

  &-error {
    border-color: $error-color;
  }
  &-success {
    border-color: $success-color;
  }

  button {
    position: absolute;
    right: 4px;
    top: 12px;
    padding: 0;
  }
}
</style>
