<script lang="ts" setup>
import { onMounted, defineEmits, defineProps } from "vue";

const emit = defineEmits(["close", "confirm-entities"]);
const props = defineProps({
  title: {
    type: String,
    default: "Modal Title",
  },

  textButton: {
    type: String,
    default: "modal text",
  },

  textClose: {
    type: String,
    default: "modald text",
  },
  disableAddButton: {
    type: Boolean,
    default: false,
  },
  width: {
    type: String,
    default: '50%'
  },
  hideFooter: {
    type: Boolean,
    default: false
  }
});

onMounted(() => {
  document.addEventListener(
    "click",
    (event: any) => {
      if (event.target?.closest(".modal-mask") && !event.target?.closest(".modal-container")) {
        emit("close");
      }
    },
    false
  );
});
</script>

<template>
  <div class="modal">
    <div class="modal-mask">
      <div class="wi-flex wi-flex-column modal-container mc-w-l-100" :style="`width: ${width}`">
        <div class="modal-header">
          <h3 class="wi-text-left wi-text-bold wi-text-s wi-text-title">{{ title }}</h3>
          <button class="btn-none" @click="$emit('close')">
            <i class="bi bi-x wi-text-xl"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="modal-content">
            <slot></slot>
          </div>
        </div>
        <div class="modal-footer" v-if="!hideFooter">
          <button class="btn-none wi-text-xs wi-text-primary" @click="$emit('close')">{{ textClose }}</button>

          <button
            :disabled="disableAddButton"
            @click="$emit('confirm-entities')"
            class="wi-button wi-text-bold wi-m-s-3 wi-m-b-1 wi-button-add wi-p-x-2"
          >
            {{ textButton }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000020 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.close-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: -1;
}

.modal-container {
  width: 50%;
  overflow-y: auto;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 3px;
}

.modal-header {
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.modal-header button {
  position: absolute;
  right: 0;
  top: 0;
}

.modal-content {
  margin-bottom: 10px;
  padding: 0 16px;
  overflow-y: scroll;
  max-height: 28rem;
}

.modal-footer {
  padding: 16px 40px;
  margin-top: 10px;
  text-align: right;
  background: #f8f9f9 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
</style>
