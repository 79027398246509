function getBaseURL() {
  let baseUrl: string = process.env.BASE_URL || '';
  if (baseUrl?.endsWith('/')) {
    baseUrl = baseUrl.replace(/\/$/g, '');
  }
  return baseUrl;
}

// TODO: completare questa funzione per eseguire il download di un file senza aprirlo
/**
 * @deprecated Questa funzione non va usata, essondo che scarica un file prima del render del template rallentando il processo
 * @description Questa funzione permette di scaricare un file dal web senza aprire un altra pagina
 * @param url Url da scaricare
 * @returns ritorna 
 */
async function downloadFile(url: string): Promise<string | undefined> {
  try {
    const response = await fetch(url);
    const file = await response.blob();
    const tempUrl = URL.createObjectURL(file);
    return tempUrl;
  } catch(error) {
    console.error(error);
  }
}

export function formatErrorMessages(messages: string[]): string {
  if (messages.length === 0) {
    return 'Nessun messaggio di errore.';
  } else if(messages.length > 0) {
    const formattedMessages = messages.map((message) => `- ${message}`);
    const errorMessage = formattedMessages.join('\n');
    return `Errori:\n${errorMessage}`;
  }
  return '';
}

export default {
  getBaseURL,
  formatErrorMessages,
  downloadFile
}





