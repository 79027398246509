import { Paginate } from '@/core/interfaces';
import vuexDefinitions, { ReferentState, ReferentFilter, Referent } from '@/modules/referents/store/enums';
  
export default {
    [vuexDefinitions.enums.MUTATIONS.UpdateReferentList](
        state: ReferentState,
        referents: Referent[],
    ): void {
        state.referentList = referents;
    },
    [vuexDefinitions.enums.MUTATIONS.UpdateReferentFilter](
        state: ReferentState,
        filterReferent: ReferentFilter,
    ): void {
        state.filters = filterReferent;
    },
    [vuexDefinitions.enums.MUTATIONS.UpdatePaginate](
        state: ReferentState,
        paginate: Paginate,
    ): void {
        state.paginate = paginate;
    },

};
  