import { IResponse } from '@/core/interfaces';
import axios from 'axios';

const getAllProformas = async (start_date: string, end_date: string, order?: string, page?: number) : Promise<IResponse> => {
  const params: any = { order, page };

  if (start_date.trim() !== '') {
    params.start_date = start_date;
  }

  if (end_date.trim() !== '') {
    params.end_date = end_date;
  }

  return axios({
    url: `${process.env.VUE_APP_API}/proformas`,
    params,
    method: 'get',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionStorage.getItem('token')}` ?? '',
    },
  });
};

const BillingClient = {
  getAllProformas,
};
Object.freeze(BillingClient);
export default BillingClient;