<script lang="ts" setup>
import { computed, onBeforeMount, onMounted, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import enums from '@/modules/auth/store/enums';
import configEnums from '@/core/store/config/enums';
import CardAnalisys from '../components/CardAnalisys.vue';
import CardTable from '../components/CardTable.vue';
import DashboardClient from '../services/dashboard-client';
import alertVuexDefinitions, { Alert, AlertType } from '@/core/store/alert/enums';
import RidesClient from '@/modules/rides/rides-services/ride-client';
import UserClient from '@/core/services/user-client';
import moment from 'moment';

interface ReportData {
  totalRidesAmount: string;
  totalProfits: string;
  totalCustomer: string;
  totalDriver: string;
}
const store = useStore();
const getAuthUser = computed(() => store.getters[enums.enums.GETTERS.GetAuthUser]);
const config = computed(() => store.getters[configEnums.enums.GETTERS.GetConfig]);
const columnsRide = ref(config.value?.modules?.ride?.columns || []);
const columnsDriver = ref(config.value?.modules?.driver?.columns || []);
const columnsCustomer = ref(config.value?.modules?.customer?.columns || []);
const columnsCompany = ref(config.value?.modules?.company?.columns || []);
const reportData = ref<ReportData>();
const markers = ref();
const center = ref({ lat: 13.007813, lng: 42.623855 });
const rides = ref();
const drivers = ref();
const customers = ref();
const companies = ref();
onBeforeMount(async () => {
  try {
    const response = await DashboardClient.getReportData();
    const responseDriver = await DashboardClient.getDriverLocations();
    const responseRides = await RidesClient.getAllRides({ page: 1, order: 'asc'});
    const responseUserDriver = await UserClient.getAllUsers('DRIVER');
    const responseUserCustomer = await UserClient.getAllUsers('CUSTOMER');
    const responseUserCompany = await UserClient.getAllUsers('COMPANY');
    reportData.value = {
      totalRidesAmount: response.data.data.totalRidesAmount?.toFixed(2),
      totalProfits: response.data.data.totalProfits?.toFixed(2),
      totalCustomer: responseUserCustomer.data.data?.length,
      totalDriver: responseUserDriver.data.data?.length,
    } as ReportData;
    markers.value = responseDriver.data.data;
    rides.value = responseRides.data.data;
    drivers.value = responseUserDriver.data.data;
    customers.value = responseUserCustomer.data.data;
    companies.value = responseUserCompany.data.data;
    center.value = markers.value[0];
  } catch (error) {
    store.dispatch(alertVuexDefinitions.enums.ACTIONS.SetAlert, {
      title: 'Errore',
      description: 'Errore nel recupero dei dati del report',
      type: AlertType.Error,
    } as Alert);
  }
});
</script>

<template>
  <app-layout>
    
    <div class="wi-flex wi-flex-column wi-w-100">
      <template v-if="getAuthUser.user.role === 'SUPERADMIN'">
        <div class="row wi-flex wi-justify-between wi-flex-column wi-flex-wrap" style="gap: 20px;">
          <card-analisys
            type="first"
            icon="dollars_white"
            :title="'€ ' + reportData?.totalRidesAmount"
            description="TRANSIZIONI GENERATE"
          ></card-analisys>
          <card-analisys
            type="second"
            icon="wallet_white"
            :title="'€ ' + reportData?.totalProfits"
            description="PROFITTI"
          ></card-analisys>
          <card-analisys
            type="third"
            icon="work_white"
            :title="reportData?.totalCustomer"
            description="NUMERO COSTUMER"
          ></card-analisys>
          <card-analisys
            type="fourth"
            icon="drive_white"
            :title="reportData?.totalDriver"
            description="NUMERO DRIVER"
          ></card-analisys>
        </div>
        <GMapMap
          v-if="markers?.length > 0"
          :center="center"
          :zoom="7"
          map-type-id="terrain"
          style="width: 100%; height: 300px"
        >
          <GMapCluster :zoomOnClick="true" :minimumClusterSize="10">
            <GMapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m"
              :clickable="true"
              :icon="'http://localhost/uploads/images/markercar.png'"
              @click="center = m"
            />
          </GMapCluster>
        </GMapMap>
        <div class="wi-flex wi-flex-column wi-w-100">
          <div class="row wi-flex-wrap wi-justify-between">
            <card-table
              :has-paginate="false"
              :columns="columnsDriver"
              title="Ultimi driver"
              :has-show-more="true"
              class="wi-p-e-2"
              :rows-count="drivers?.length"
              route-extra="drivers"
            >
              <tr
                class="mc-cursor-pointer"
                v-for="item in drivers"
                :key="item._id">
                  <td class="content">{{ item.name ? item.name : ' - ' }}</td>
                  <td class="content">{{ item.surname ? item.surname : ' - ' }}</td>
                  <td class="content">{{ item.email ? item.email : ' - ' }}</td>
                  <td class="content">{{ item.phone ? item.phone : ' - ' }}</td>
                  <td class="content">{{ item?.payout_active ? item?.payout_active : ' - ' }}</td>
                  <td class="content">{{ item.payout_created ? item.payout_created : ' - ' }}</td>
              </tr>
            </card-table>
            <card-table
              :has-paginate="false"
              :columns="columnsCustomer"
              title="Ultimi customer"
              :has-show-more="true"
              :rows-count="customers?.length"
              route-extra="customers"
            >
              <tr
                class="mc-cursor-pointer"
                v-for="item in customers"
                :key="item._id">
                <td class="content">{{item.name}}</td>
                <td class="content">{{item.surname}}</td>
                <td class="content">{{item.email}}</td>
                <td class="content">{{item.phone}}</td>
              </tr>
            </card-table>
          </div>
          <div class="row wi-flex wi-flex-row wi-flex-wrap wi-justify-between">
            <card-table
              :has-paginate="false"
              :columns="columnsRide"
              title="Ultime corse"
              :has-show-more="true"
              class="wi-p-e-2"
              :rows-count="rides?.length"
              route-extra="rides"
            >
              <tr
                class="mc-cursor-pointer"
                v-for="item in rides"
                :key="item._id">
                <td class="content">{{ moment(item.start_time).format('DD-MM-YYYY HH:mm') }}</td>
                <td class="content">{{item.pickup_address?.address}}</td>
                <td class="content">{{item.dropoff_address?.address}}</td>
                <td class="content">€{{item.amount}}</td>
                <td class="content">{{ item.customer?.name }} {{item.customer?.surname }}</td>
                <td class="content">{{ item.driver?.name }} {{item.driver?.surname }}</td>
                <td class="content wi-flex wi-flex-row wi-justify-center wi-m-w-none wi-w-auto">
                  <wi-icon-svg icon="view" width="24" height="24" class="wi-icon wi-icon-pointer wi-m-e-1" @click="$router.push('/rides/' + item._id)"></wi-icon-svg>
                </td>
              </tr>
            </card-table>
            <card-table
              :has-paginate="false"
              :columns="columnsCompany"
              title="Ultime Company"
              :has-show-more="true"
              :rows-count="companies?.length"
              route-extra="companies"
            >
              <tr
                class="mc-cursor-pointer"
                v-for="item in companies"
                :key="item._id">
                <td class="content">{{item.name}}</td>
                <td class="content">{{item.surname}}</td>
                <td class="content">{{item.email}}</td>
                <td class="content">{{item.phone}}</td>
              </tr>
            </card-table>
          </div>
        </div>
      </template>
      <template v-if="getAuthUser.user.role === 'COMPANY'">
        <div class="row wi-flex wi-justify-between wi-flex-column wi-flex-wrap" style="gap: 20px;">
          <card-analisys
            type="first"
            icon="dollars_white"
            title="€ 250.000"
            description="TOTALE SPESE"
          ></card-analisys>
          <card-analisys
            type="second"
            icon="wallet_white"
            title="€ 250.000"
            description="NUMERO REFERENTI"
          ></card-analisys>
          <card-analisys
            type="third"
            icon="work_white"
            title="240"
            description="NUMERO MEMBRI"
          ></card-analisys>
          <card-analisys
            type="fourth"
            icon="drive_white"
            title="130"
            description="CORSE MENSILI"
          ></card-analisys>
        </div>
      </template>
    </div>
  </app-layout>
</template>

<style lang="scss" scoped>
</style>
