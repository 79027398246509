import { createApp } from 'vue';
import { initializeApp } from 'firebase/app';
import firebaseConfig from '@/core/firebase';
import VueGoogleMaps from 'vue-google-maps-community-fork';
import App from './App.vue';
import router from './router';
import store from './store';
import { Directive } from 'vue';
import './assets/scss/main.scss';
import AppLayout from './components/AppLayout.vue';
import AppModal from './components/AppModal.vue';
import AppAlert from './components/AppAlert.vue';
import AppIcon from './components/AppIcon.vue';
import AppDropDown from './components/AppDropDown.vue';
import AppIconSvg from './components/AppIconSvg.vue';
import TablePage from './components/table/TablePage.vue';
import TableContent from './components/table/TableContent.vue';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import { vue3Debounce } from 'vue-debounce';
import MapViewer from './components/MapViewer.vue';
import AppToggle from './components/AppToggle.vue';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const firebaseApp = initializeApp(firebaseConfig);
const app = createApp(App);
initializeApp(firebaseConfig);
app.use(store);
app.directive('debounce', vue3Debounce({lock: true}));
app.directive('click-outside', {
  mounted(el, binding, vnode) {
    // Controlla se binding.value è una funzione prima di chiamarla
    if (typeof binding.value !== 'function') {
      console.error('Il valore passato a click-outside non è una funzione');
      return;
    }

    el.clickOutsideEvent = (event: any) => {
      console.log('click page')
      if (!(el === event.target || el.contains(event.target))) {
        console.log('click outside')
        // Chiamare binding.value solo se è una funzione
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener('click', el.clickOutsideEvent);
  }
});
app.use(router);
app.component('app-layout', AppLayout);
app.component('wi-icon', AppIcon);
app.component('wi-icon-svg', AppIconSvg);
app.component('table-page', TablePage);
app.component('table-content', TableContent);
app.component('wi-modal', AppModal);
app.component('wi-toggle', AppToggle);
app.component('wi-alert', AppAlert);
app.component('wi-drop-down', AppDropDown);
app.component('wi-map-viewr', MapViewer);
app.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places',
  },
});
app.mount('#app');
