import vuexDefinitions, { ConfigState } from '@/core/store/config/enums';
import { nextTick } from 'vue';

export default {
  [vuexDefinitions.enums.MUTATIONS.UpdateConfig](
    state: ConfigState,
    configs: ConfigState
  ): void {
    state.modules = configs.modules;
  },
};
