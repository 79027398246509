<script lang="ts" setup>
import { ref, computed, onMounted, reactive, watch } from 'vue';
import { mapActions, useStore } from 'vuex';
import enums, { VehicleModels } from '../store/enums';
import AppAutoComplete from '@/components/AppAutoComplete.vue';
import UserClient from '@/core/services/user-client';
import { AuthRole } from '@/modules/auth/store/enums';
import { Driver } from '@/modules/drivers/store/enums';
import useVuelidate from '@vuelidate/core';
import { required, numeric } from '@vuelidate/validators';

import moment from 'moment';

const store = useStore();
const value = ref('');
const book = ref();
const insurance = ref();
const items = ref<Driver[]>([]);
const getModelVehiclelist = ref<any>([]);
const getBrandVehiclelist = ref<any>([]);
const getVehicleForm = reactive({
  
});
const years = computed(() => {
  const years = []
  for(var i = 1900; i <= moment().year(); i++) {
    years.push(i.toString());
  }
  return years;
})

const vehicleFormBrand = computed(() => getVehicleForm.value.make);

const vehicleFormDriver = computed({
  get(): string {
    return getVehicleForm.value.driver;
  },
  set(newValue: string): void {
    store.dispatch(enums.enums.ACTIONS.SetVehicleForm, {
      ...getVehicleForm.value,
      driver: newValue,
    });
  }
});

const vehicleFormYear = computed({
  get(): string {
    return moment(getVehicleForm.value.year, 'YYYY-MM-DDTHH:mm:ss±hh:mm').format('YYYY');
  },
  set(newValue: string): void {
    store.dispatch(enums.enums.ACTIONS.SetVehicleForm, {
      ...getVehicleForm.value,
      year: newValue.toString(),
    });
  }
});

const state = reactive({
  make: "",
  plate: "",
  model: "",
  seats: "",
  type: "",
  year: "",
  vehicle_document: "",
  book_document: "",
});

const rules = {
  make: { required },
  plate: {required },
  model: { required },
  seats: { required, numeric },
  type: { required },
  year: { required },
  vehicle_document: { required },
  book_document: { required }
};

const v$ = useVuelidate(rules, state);

const isValid = computed(() => 
  v$.value.brand.$invalid
  || v$.value.model.$invalid
  || v$.value.plate.$invalid
  || v$.value.seats.$invalid
  || v$.value.type.$invalid
  || v$.value.year.$invalid
  || v$.value.vehicle_document.$invalid
  || v$.value.book_document.$invalid
);

watch(v$.value.seats, (newValue, oldValue) => {
  store.dispatch(enums.enums.ACTIONS.SetVehicleForm, {
    ...getVehicleForm.value,
    seats: newValue.$model,
  });
});

watch(v$.value.plate, (newValue, oldValue) => {
  store.dispatch(enums.enums.ACTIONS.SetVehicleForm, {
    ...getVehicleForm.value,
    plate: newValue.$model,
  });
});

watch(v$.value.type, (newValue, oldValue) => {
  store.dispatch(enums.enums.ACTIONS.SetVehicleForm, {
    ...getVehicleForm.value,
    type: newValue.$model,
  });
});

const chengeBook = (event: Event) => {
  const fileInput = event?.target as HTMLInputElement;
  const file = fileInput?.files[0];

  // Verifica se è stato selezionato un file
  if (file) {
    const allowedExtensions = ['.pdf', '.png', '.jpeg', '.jpg'];

    // Verifica l'estensione del file
    const fileExtension = file.name.toLowerCase().substring(file.name.lastIndexOf('.'));
    if (allowedExtensions.includes(fileExtension)) {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result?.toString().split(',')[1];
        if (base64String) {
          state.book_document = base64String ?? '';
        }
        console.log("File in base64:", state.book_document);

        // Ora puoi fare qualsiasi altra operazione necessaria
      };

      reader.readAsDataURL(file);
    } else {
      // File con estensione non consentita, invalida il campo e mostra un messaggio di errore
      v$.value.book_document.$error = true;
      v$.value.book_document.$touch();
      v$.value.book_document.$errors.push({
        $uid: 'file-type-error', $message: 'Formato del file non consentito. Sono ammessi solo file PDF, PNG, JPEG e JPG.',
        $propertyPath: '',
        $property: '',
        $validator: '',
        $params: {},
        $pending: false,
        $response: undefined
      });
    }
  }
};

const changeInsurance = (event: Event) => {
  const fileInput = event?.target as HTMLInputElement;
  const file = fileInput?.files[0];

  // Verifica se è stato selezionato un file
  if (file) {
    const allowedExtensions = ['.pdf', '.png', '.jpeg', '.jpg'];

    // Verifica l'estensione del file
    const fileExtension = file.name.toLowerCase().substring(file.name.lastIndexOf('.'));
    if (allowedExtensions.includes(fileExtension)) {
      const reader = new FileReader();

      reader.onload = () => {
        const base64String = reader.result?.toString().split(',')[1];
        if (base64String) {
          state.vehicle_document = base64String ?? '';
        }
        console.log("File in base64:", state.vehicle_document);

        // Ora puoi fare qualsiasi altra operazione necessaria
      };

      reader.readAsDataURL(file);
    } else {
      // File con estensione non consentita, invalida il campo e mostra un messaggio di errore
      v$.value.vehicle_document.$error = true;
      v$.value.vehicle_document.$touch();
      v$.value.vehicle_document.$errors.push({
        $uid: 'file-type-error', $message: 'Formato del file non consentito. Sono ammessi solo file PDF, PNG, JPEG e JPG.',
        $propertyPath: '',
        $property: '',
        $validator: '',
        $params: {},
        $pending: false,
        $response: undefined
      });
    }
  }
};

const vehicleFormModel = computed(() => getVehicleForm?.value.model);

onMounted(async () => {
  await store.dispatch(enums.enums.ACTIONS.FetchBrandVehicleList, '');
  const selectedBrand = getBrandVehiclelist.value.find((el: any) => el.brand === vehicleFormBrand.value) ?? '';
  console.log()
  if(selectedBrand.length === 0 && vehicleFormBrand.value !== '' && getVehicleForm.value.model) {
    store.dispatch(enums.enums.ACTIONS.AddBrandVehicleList, {
      _id: 'current',
      brand: getVehicleForm.value.make,
      models: [getVehicleForm.value.model],
    });
  }
  if (getVehicleForm.value) {
    state.make = getVehicleForm.value.make;
    state.model = getVehicleForm.value.model;
    state.plate = getVehicleForm.value.plate;
    state.seats = getVehicleForm.value.seats;
    state.year = getVehicleForm.value.year;
    state.type = getVehicleForm.value.type;
    state.book_document = getVehicleForm.value.book_document;
    state.vehicle_document = getVehicleForm.value.vehicle_document;
  }

  const selectedModals = getBrandVehiclelist.value.find((el: any) => el.brand === vehicleFormBrand.value)?.models;
  store.dispatch(enums.enums.ACTIONS.FetchModelVehicleList, selectedModals == '' ? [ getVehicleForm.value.model ] : selectedModals);
  value.value = (vehicleFormDriver.value?.name ?? '') + ' ' + (vehicleFormDriver.value?.surname ?? '');
  store.dispatch(enums.enums.ACTIONS.FetchVehicleList);
});

const selectBrand = (event: any) => {
  const model = getBrandVehiclelist.value
    .find((x: VehicleModels) => x.brand === event.target?.value).models;
  store.dispatch(enums.enums.ACTIONS.FetchModelVehicleList, model);
  state.make = event.target?.value;
  store.dispatch(enums.enums.ACTIONS.SetVehicleForm, {
    ...getVehicleForm.value,
    make: event.target?.value,
  });
};

const updateDriverSearch = async (search: string) => {
  value.value = search;
  const response = await UserClient.getAllUsers(AuthRole.DRIVER, search);
  items.value = response.data.data as Driver[];
};

const uploadDocument = (document: string) => {
  if(document === 'book') {
    book.value.click();
  } else if(document === 'insurance') {
    insurance.value.click();
  }
}

const selectDriver = (newValue: Driver) => {
  value.value = newValue.name + ' ' + newValue.surname;
  items.value = [];
  store.dispatch(enums.enums.ACTIONS.SetVehicleForm, {
    ...getVehicleForm.value,
    driver: newValue._id
  });
};

</script>

<template>
  <div class="row wi-justify-between">
    <label
      for="n_brand"
      class="wi-text-bold col wi-text-left wi-m-b-2">
      Marca
      <select
        id="name"
        placeholder="Inserisci la marca"
        class="wi-input wi-modal-input"
        :class="{ 'error-border': v$.make.$error }" 
        v-mode="v$.make.$model"
        @change="selectBrand($event)"
      >
        <option value="" :selected="v$.make.$model === ''">Inserisci il marchio del veicolo</option>
        <option
          v-for="brand in getBrandVehiclelist"
          :key="brand.name"
          :value="brand.name"
          :selected="v$.make.$model === brand.brand"
        >
          {{brand.brand}}
        </option>
      </select>
      <div class="input-errors wi-m-b-1" v-for="error of v$.make.$errors" :key="error.$uid">
        <div class="wi-text-xs wi-font-lato">{{ error.$message }}</div>
      </div>
    </label>
    <label for="name" class="wi-text-bold col wi-text-left wi-m-b-2">
      Anno Immatricolazione
      <select
        id="name"
        class="wi-input wi-modal-input"
        placeholder="Inserisci la tipologia"
        v-model="v$.year.$model"
      >
        <option class="wi-p-y-3" :selected="v$.year.$model === ''" value="">Inserisci l'anno del veicolo</option>
        <option v-for="i in years" :key="i" class="wi-p-y-3" :selected="v$.year.$model == i" :value="i">{{ i }}</option>
      </select>
    </label>
    <label for="models" class="wi-text-bold col wi-text-left wi-m-b-2">
      Modello
      <select
        id="models"
        placeholder="Inserisci la tipologia"
        class="wi-input wi-modal-input"
        @change="store.dispatch(enums.enums.ACTIONS.SetVehicleForm, {
          ...getVehicleForm,
          model: $event.target?.value,
        })"
      >
        <option :selected="vehicleFormModel.model === ''">Inserisci il modello</option>
        <option
          v-for="model in getModelVehiclelist"
          :key="model"
          :value="model"
          :selected="vehicleFormModel === model"
        >
          {{model}}
        </option>
      </select>
    </label>
    <label for="name" class="wi-text-bold col wi-text-left wi-m-b-2">
      Tipologia
      <select
        id="name"
        placeholder="Inserisci la tipologia"
        class="wi-input wi-modal-input"
        :class="{ 'error-border': v$.type.$error }"
        v-model="v$.type.$model"
      >
        <option class="wi-p-y-3" :selected="v$.type.$model === ''" value="">Inserisci il Tipo veicolo</option>
        <option class="wi-p-y-3" :selected="v$.type.$model === 'ECONOMY'" value="ECONOMY">ECONOMY</option>
        <option class="wi-p-y-3" :selected="v$.type.$model === 'VAN'" value="VAN">VAN</option>
        <option class="wi-p-y-3" :selected="v$.type.$model === 'CONFORT'" value="CONFORT">CONFORT</option>
        <option class="wi-p-y-3" :selected="v$.type.$model === 'LUXURY'" value="LUXURY">LUXURY</option>
      </select>
      <div class="input-errors wi-m-b-1" v-for="error of v$.type.$errors" :key="error.$uid">
        <div class="wi-text-xs wi-font-lato">{{ error.$message }}</div>
      </div>
    </label>
    <label for="plate" class="wi-text-bold col wi-text-left wi-m-b-2">
      Targa
      <input
        id="plate"
        type="text"
        placeholder="Inserisci la targa del veicolo"
        class="wi-input wi-modal-input"
        :class="{ 'error-border': v$.plate.$error }"
        v-model="v$.plate.$model"
      >
      <div class="input-errors wi-m-b-1" v-for="error of v$.plate.$errors" :key="error.$uid">
        <div class="wi-text-xs wi-font-lato">{{ error.$message }}</div>
      </div>
    </label>
    <label for="driver" class="wi-text-bold col wi-text-left wi-m-b-2">
      Driver
      <AppAutoComplete
        :search="value"
        :has-open="items.length > 0"
        @update:search="updateDriverSearch($event)"
      >
      <div class="items" @click="selectDriver(item)" v-for="item in items" :key="item._id">
        <div class="item" @click="selectDriver(item)">
          <a>{{ item.name }} {{ item.surname }} </a>
        </div>
      </div>
      </AppAutoComplete>
    </label>
    <label
      for="n_member"
      class="wi-text-bold col wi-text-left wi-m-b-2">
      Posti
      <input
        id="n_member"
        type="number"
        min="2"
        max="10"
        placeholder="Inserisci i posti disponibili"
        class="wi-input wi-modal-input"
        :class="{ 'error-border': v$.seats.$error }"
        v-model="v$.seats.$model"
      >
      <div class="input-errors wi-m-b-1" v-for="error of v$.seats.$errors" :key="error.$uid">
        <div class="wi-text-xs wi-font-lato">{{ error.$message }}</div>
      </div>
    </label>
    <label
      v-if="getVehicleForm.vehicle_document"
      for="vehicle_document"
      class="wi-text-bold wi-text-right wi-m-b-2">
      Documenti veicoli
      <a :href="getVehicleForm.vehicle_document" target="_blank">Documento download</a>
    </label>
    <label
      v-if="getVehicleForm.book_document"
      for="vehicle_document"
      class="wi-text-bold wi-text-right wi-m-b-2">
      Libretto veicoli
      <a :href="getVehicleForm.book_document" target="_blank">Libretto download</a>
    </label>
  </div>
</template>

<style lang="scss" scoped>
.items{
  padding: .3rem;
}
.items:hover{
  background-color: rgb(199, 198, 198);
  color: white;
}

.wi-text-options {
  border-bottom: 1px solid #969393;
  padding: 13px;
  margin: 0;
  &.success {
    border-bottom: none;
    background-color: #3d99702d;

  }
}

.error-border {
  border: 2px solid red;
}

.wi-color-info-vehicle{
  font-family: Lato, Regular;
}

</style>
