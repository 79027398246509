import { IResponse } from '@/core/interfaces';
import axios from 'axios';
import { AuthRole } from '@/modules/auth/store/enums';


const getAllUsers = async (role: string, search?: string, order?: string, page?: number) : Promise<IResponse> => axios({
  url: `${process.env.VUE_APP_API}/users`,
  params: {
    role,
    search,
    order,
    page,
  },
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}` ?? '',
  },
}).catch((e) => e.toJSON());


const getSingleUser = async (id: string) : Promise<IResponse> => axios({
  url: `${process.env.VUE_APP_API}/users/${id}`,
  method: 'get',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}` ?? '',
  },
}).catch((e) => e.toJSON());

const setEnabledUser = async (id: string) : Promise<IResponse> => axios({
  url: `${process.env.VUE_APP_API}/users/enabled/${id}`,
  method: 'put',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${sessionStorage.getItem('token')}` ?? '',
  },
}).catch((e) => e.toJSON());

const UserClient = {
  getAllUsers,
  getSingleUser,
  setEnabledUser
};
  // Disallow new properties on our object
Object.freeze(UserClient);
export default UserClient;
