import axios from 'axios';

export interface PostPrice {
  price: number | string,
  has_vip_price: boolean | number,
  vehicle_type: string,
  label: string,
  success?: boolean
}

const authHeaders = {
  'Content-Type': 'application/json',
  Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
};

const getAllPrice = async () => {
  return axios({
    url: `${process.env.VUE_APP_API}/settings`,
    method: 'get',
    headers:  {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
  });
};

const postPrice = async (data: PostPrice) => {
  return axios({
    url: `${process.env.VUE_APP_API}/settings`,
    method: 'post',
    headers:  {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
    data
  });
};

const updatePrice = async (id: string, data: PostPrice) => {
  return axios({
    url: `${process.env.VUE_APP_API}/settings/${id}`,
    method: 'patch',
    headers:  {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${ sessionStorage.getItem('token') ?? '' }`,
    },
    data
  });
};

const SettingsClient = {
  getAllPrice,
  postPrice,
  updatePrice
};
  // Disallow new properties on our object
Object.freeze(SettingsClient);
export default SettingsClient;
